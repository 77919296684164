//frontend/src/components/UserAccountInfo.js
import { useState, useEffect } from 'react';

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

export const useAccountInfo = () => {
  const [accountInfo, setAccountInfo] = useState(null);

  useEffect(() => {
    const fetchAccountInfo = async () => {
      try {
        const response = await fetch(`${API_BASE_URL}/user_account`, {
          method: "GET",
          credentials: "include",
        });
        if (response.ok) {
          const data = await response.json();
          setAccountInfo(data);
          if (process.env.NODE_ENV === 'development') {
            console.log("receive account profile", data);
          }
        } else {
          if (process.env.NODE_ENV === 'development') {
            console.error("Failed to fetch account info");
          }
        }
      } catch (error) {
        if (process.env.NODE_ENV === 'development') {
          console.error("Error fetching account info:", error);
        }
      }
    };

    fetchAccountInfo();
  }, []);

  return accountInfo;
};
