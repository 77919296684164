// EmailSuggestionConstant.js

import React from 'react';

const EmailSuggestions = (
  <div style={{ fontFamily: 'Arial, sans-serif', lineHeight: '1.6' }}>
    <h3 style={{marginBottom: "-10px"}}>Email Writing Suggestions</h3>
    <ol style={{ paddingLeft: '40px', paddingRight: "20px" }}>
      <li>
        <strong>Clear and Concise Subject Line:</strong> Use a clear and concise subject line that accurately reflects the content of the email. This helps recipients understand the purpose and urgency at a glance.
      </li>
      <li>
        <strong>Professional Greeting:</strong> Start your email with a professional greeting appropriate for the context (e.g., "Dear [Name]," or "Hello [Name],").
      </li>
      <li>
        <strong>Introduction and Context:</strong> Provide a brief introduction and context for your email. Clearly state the purpose to give recipients a clear understanding of what to expect.
      </li>
      <li>
        <strong>Be Mindful of Tone:</strong> Pay attention to the tone of your email. Use a courteous and professional tone, and remember that the lack of non-verbal cues can affect how your message is interpreted.
      </li>
      <li>
        <strong>Use Proper Grammar and Punctuation:</strong> Write in complete sentences with proper grammar and punctuation. Well-constructed sentences enhance clarity and professionalism.
      </li>
      <li>
        <strong>Concise and Focused Content:</strong> Keep your message concise and focused. Avoid unnecessary details and get straight to the point to respect the recipient's time.
      </li>
      <li>
        <strong>Use Bullet Points or Lists:</strong> If presenting multiple points or items, use bullet points or lists to enhance readability.
      </li>
      <li>
        <strong>Include a Call-to-Action:</strong> Clearly state what action you expect the recipient to take. Whether it's responding to a question, providing feedback, or taking a specific action, make it explicit.
      </li>
      <li>
        <strong>Professional Closing:</strong> End your email with a professional closing (e.g., "Best regards," or "Sincerely,") followed by your name.
      </li>
      <li>
        <strong>Signature Block:</strong> Include a signature block with your name, title, and relevant contact information. This adds a professional touch and makes it easy for recipients to reach you.
      </li>
      <li>
        <strong>Proofread Before Sending:</strong> Before sending, proofread your email to catch any spelling or grammatical errors. A well-polished email reflects positively on your professionalism.
      </li>
      <li>
        <strong>Avoid Using All Caps:</strong> Using all caps can be interpreted as shouting. Avoid using all capital letters unless it's for emphasis on a specific word or phrase.
      </li>
      <li>
        <strong>Reply Promptly:</strong> Respond to emails promptly, especially if a quick response is expected. If you need more time, acknowledge the email and provide an estimated timeframe for a detailed response.
      </li>
    </ol>
    <p>Need Help? <strong>SophoScriptor</strong> is always ready to assist you with crafting effective emails. </p>
  </div>
);

export default EmailSuggestions;
