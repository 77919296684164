const WritingUsageConstant = (
    <div className="textarea-container">
        <div className="textarea">
            <p style={{marginLeft: "0pt", marginTop: "20px"}}>SophoScriptor tracks word count usage based on three sources: essay/email writing, brainstorming, and evaluation reports.</p>

            <h3 style={{marginLeft: "0pt", marginTop: "0pt", marginBottom: "10px"}}>1. Essay and Email Writing</h3>
            <p style={{ marginLeft: '20px' }}>
            This app tracks the word count of each writing as part of your app usage. While SophoScriptor tries to adhere to the prescribed word limit for essays (except emails), there are instances where the generated text greatly exceeds the word limit. To optimize your experience, your app usage for each writing only counts the lesser value between the set word limit and the actual word count.
            </p>

            <h3 style={{marginLeft: "0pt", marginTop: "0pt", marginBottom: "10px"}}>2. Brainstorming</h3>
            <p style={{ marginLeft: '20px' }}>
            Brainstorming is a powerful tool designed to spark your creativity. It takes your initial thoughts and expands them into rich, well-developed writing ideas, evolving them into a detailed and impactful writing framework. To maximize the value of this tool, only half of the word count from the generated writing ideas and framework is counted toward your usage.
            </p>

            <h3 style={{marginLeft: "0pt", marginTop: "0pt", marginBottom: "10px"}}>3. Evaluation Reports</h3>
            <p style={{ marginLeft: '20px' }}>
            Each evaluation report is tailored to the specific essay draft and can inspire new ideas. The report includes a detailed evaluation and improvement suggestions. Regardless of length, each report is counted as 200 words towards your usage to encourage using this valuable feature.
            </p>
        </div>
    </div>
);

export default WritingUsageConstant;
