import React, { useState, useEffect } from "react";
import { getSessionStorageItem, updateSessionData } from "./Mask";

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

const GreetingMessage = () => {
    const [username, setUsername] = useState(getSessionStorageItem("username"));
    const [expirationDate, setExpirationDate] = useState(getSessionStorageItem("expiration_date"));
    const [writingLimit, setWritingLimit] = useState(getSessionStorageItem("writing_limit", 0));
    const [totalWords, setTotalWords] = useState(getSessionStorageItem("total_words", 0));
    const [greetingMessage, setGreetingMessage] = useState("Loading...");

    const calculateGreetingMessage = (username, writingLimit, totalWords, expirationDate) => {
        const currentTime = new Date();
        const shortDate = expirationDate.substring(0, 10);
        const expirationTime = new Date(shortDate);
        const availableWords = (writingLimit || 0) - (totalWords || 0);

        if (availableWords > 0 && expirationTime > currentTime) {
            return `As a valued member, you have access to all features of the app and can write ${availableWords} words until ${shortDate}.`;
        } else if (availableWords <= 0) {
            return "You have exceeded your writing limit. To continue using the service, consider purchasing a writing plan or extending your limit. Visit the Products and Services page for details.";
        } else {
            return "Your account has expired. To continue using the service, consider purchasing a writing plan or extending your account. Visit the Products and Services page for details.";
        }
    };

    useEffect(() => {
        const sessionDataExists =
            username && expirationDate && writingLimit && totalWords;

        if (sessionDataExists) {
            // If session data exists, calculate the message without a backend call
            setGreetingMessage(
                calculateGreetingMessage(username, writingLimit, totalWords, expirationDate)
            );
        } else {
            // Otherwise, fetch data from the backend
            const fetchAccountData = async () => {
                try {
                    const response = await fetch(`${API_BASE_URL}/user_account`, {
                        method: "GET",
                        credentials: "include",
                    });

                    if (response.ok) {
                        const data = await response.json();
                        updateSessionData("username", data.username, setUsername);
                        updateSessionData("expiration_date", data.expiration_date, setExpirationDate);
                        updateSessionData("writing_limit", data.writing_limit, setWritingLimit);
                        updateSessionData("total_words", data.total_words, setTotalWords);

                        setGreetingMessage(
                            calculateGreetingMessage(
                                data.username,
                                data.writing_limit,
                                data.total_words,
                                data.expiration_date
                            )
                        );
                    } else {
                        setGreetingMessage(
                            "Sign up for our free Base Membership in the sidebar! Enjoy a 15-day trial with up to 3000 words to explore all our features."
                        );
                    }
                } catch (error) {
                    if (process.env.NODE_ENV === 'development') {
                        console.error("Error fetching account info:", error);
                    }
                    setGreetingMessage("Unable to fetch account details. Please try again later.");
                }
            };

            fetchAccountData();
        }
    }, [username, expirationDate, writingLimit, totalWords]);

    return <p style={{ padding: "10px", color: "#CC5500" }}>{greetingMessage}</p>;
};

export default GreetingMessage;
