const PrivacyPolicyConstant = (
    <div className="textarea-container">
        <div className="textarea">
            <p style={{marginLeft: "0pt", marginTop: '20pt'}}><strong>Effective Date:</strong> November 22, 2024</p>
            <p style={{marginLeft: "0pt", marginTop: '0pt'}}>Thank you for choosing SophoScriptor as your personal writing assistant. This Privacy Policy outlines how we collect, use, and safeguard your information when you use our writing app. By using the app, you agree to the terms outlined in this policy.</p>
            
            <h3 style={{marginLeft: "0pt", marginTop: "0pt", marginBottom: "10px"}}>1. Information Collected:</h3>
            <h4 style={{ marginLeft: "20px", marginTop: '0pt', marginBottom: "10px" }}>a. User-Generated Writings:</h4>
            <p style={{ marginLeft: '20px' }}>
            We generally do not store writings generated by users. Users of our premium memberships, however, have the option to save their essays in their personal writing accounts. These accounts are private and accessible only to the user; even administrators do not have access to the content stored within them.
            Users are encouraged to download and save their writings for their personal use.
            </p>
            <h4 style={{ marginLeft: "20px", marginTop: '0pt', marginBottom: "10px" }}>b. Cookies:</h4>
            <p style={{ marginLeft: '20px' }}>
            The app uses cookies solely for operational purposes, such as maintaining session information and user login status.
            These cookies enhance the functionality and user experience of the app.
            </p>
            
            <h3 style={{marginLeft: "0pt", marginTop: "0pt", marginBottom: "10px"}}>2. Purpose of Data Collection:</h3>
            <p style={{ marginLeft: '20px' }}>The primary purpose of data collection is to ensure the smooth operation of the app and enhance user experience.</p>
            
            <h3 style={{marginLeft: "0pt", marginTop: "0pt", marginBottom: "10px"}}>3. Copyright and Responsibility:</h3>
            <h4 style={{ marginLeft: "20px", marginTop: '0pt', marginBottom: "10px" }}>a. Copyright:</h4>
            <p style={{ marginLeft: '20px' }}>
            SophoScriptor does not claim copyright for any writings generated within the app.
            Users retain full copyright and ownership of their generated content.
            </p>
            <h4 style={{ marginLeft: "20px", marginTop: '0pt', marginBottom: "10px" }}>b. Responsibility:</h4>
            <p style={{ marginLeft: '20px' }}>
            SophoScriptor does not take responsibility for the content generated by users.
            It is the user's judgment and responsibility to decide how to use the app and the writings it generates.
            </p>
            
            <h3 style={{marginLeft: "0pt", marginTop: "0pt", marginBottom: "10px"}}>4. Security Measures:</h3>
            <p style={{ marginLeft: '20px' }}>We implement reasonable security measures to protect your information and ensure its confidentiality.</p>
            
            <h3 style={{marginLeft: "0pt", marginTop: "0pt", marginBottom: "10px"}}>5. Third-Party Services:</h3>
            <p style={{ marginLeft: '20px' }}>The app may contain links to third-party websites. SophoScriptor is not responsible for the privacy practices or content of these third-party sites.</p>
            
            <h3 style={{marginLeft: "0pt", marginTop: "0pt", marginBottom: "10px"}}>6. Changes to the Privacy Policy:</h3>
            <p style={{ marginLeft: '20px' }}>SophoScriptor reserves the right to modify this Privacy Policy at any time. Users will be notified of significant changes.</p>
            
            <h3 style={{marginLeft: "0pt", marginTop: "0pt", marginBottom: "10px"}}>7. Contact Information:</h3>
            <p style={{ marginLeft: '20px' }}>For any questions or concerns regarding this Privacy Policy, please contact us by visiting the Contact Us page from the left menu, or click the Contact Us button below.</p>
            
            <p style={{ marginLeft: '0px', marginTop: "20px" }}>By using SophoScriptor, you agree to the terms outlined in this Privacy Policy. It is recommended to review this policy periodically for any updates or changes.</p>
            
            <p style={{ marginLeft: '0px', marginTop: "20px", marginBottom: "20px", fontStyle: 'italic', fontWeight: 'bold' }}>SophoScriptor, Your Writing Assistant</p>
        </div>
    </div>
);

export default PrivacyPolicyConstant;
