import React from 'react';
import { Tooltip } from 'react-tooltip';
import PropTypes from 'prop-types';

const EvaluationOptionsBox = ({ options, setOptions, onOptionChange }) => {
  const handleEssaySourceChange = (source) => {
    const newOptions = {
      ...options,
      fromSampleWriting: source === 'SampleWriting' ? !options.fromSampleWriting : false,
      fromMyComputer: source === 'MyComputer' ? !options.fromMyComputer : false,
    };
    setOptions(newOptions);
    onOptionChange(newOptions); // Pass the updated options back to the parent
  };

  const handleCriteriaChange = (criteria) => {
    const newOptions = {
      ...options,
      useNewCriteria: criteria === 'New' ? !options.useNewCriteria : false,
      useOldCriteria: criteria === 'Old' ? !options.useOldCriteria : false,
    };
    setOptions(newOptions);
    onOptionChange(newOptions); // Pass the updated options back to the parent
  };

  return (
    <div className="options-box">
      <div className="options-container">
        <div className="option-group">
          <label>Choose Essay Source:</label>
          <div>
            <input
              type="checkbox"
              checked={options.fromSampleWriting}
              onChange={() => handleEssaySourceChange('SampleWriting')}
              data-tooltip-id="sampleWritingTooltip"
            />
            From Sample Writing
            <Tooltip id="sampleWritingTooltip">
              Transfer the essay from the Sample Writing screen.
            </Tooltip>
          </div>
          <div>
            <input
              type="checkbox"
              checked={options.fromMyComputer}
              onChange={() => handleEssaySourceChange('MyComputer')}
              data-tooltip-id="myComputerTooltip"
            />
            From My Computer
            <Tooltip id="myComputerTooltip">
              Upload an essay from your computer.
            </Tooltip>
          </div>

          {/* Horizontal line */}
          <hr />

          {/* Explanation for Essay Source */}
          <div className="explanation">
            {options.fromSampleWriting && (
              <p>Transfer the sample essay written on the Sample Writing screen for evaluation.</p>
            )}
            {options.fromMyComputer && (
              <p>Click the button below to upload an essay from your computer. Supported formats include .txt, .doc, and .pdf.</p>
            )}
          </div>
        </div>

        <div className="option-group">
          <label>Choose Evaluation Criteria:</label>
          <div>
            <input
              type="checkbox"
              checked={options.useNewCriteria}
              onChange={() => handleCriteriaChange('New')}
              data-tooltip-id="newCriteriaTooltip"
            />
            Use New Criteria
            <Tooltip id="newCriteriaTooltip">
              Create a new set of evaluation criteria tailored to the specific essay.
            </Tooltip>
          </div>
          <div>
            <input
              type="checkbox"
              checked={options.useOldCriteria}
              onChange={() => handleCriteriaChange('Old')}
              data-tooltip-id="oldCriteriaTooltip"
            />
            Apply Existing Criteria
            <Tooltip id="oldCriteriaTooltip">
              Apply a previously generated criteria, ideal for comparison.
            </Tooltip>
          </div>

          {/* Horizontal line */}
          <hr />

          {/* Explanation for Evaluation Criteria */}
          <div className="explanation">
            {options.useNewCriteria && (
              <p>
                A new set of criteria will be developed based on the writing prompt, word limit, and unique aspects of the essay.
              </p>
            )}
            {options.useOldCriteria && (
              <p>
                An existing criteria set will be used for evaluation, useful for comparing different essays consistently.
              </p>
            )}
            <p>Note: You can customize the evaluation criteria in the box to fit your needs.</p>
          </div>
        </div>
      </div>
    </div>
  );
};

EvaluationOptionsBox.propTypes = {
  options: PropTypes.object.isRequired,
  setOptions: PropTypes.func.isRequired,
  onOptionChange: PropTypes.func.isRequired,
};

export default EvaluationOptionsBox;

