import React, { useState } from "react";
import axios from "axios";
import DownloadOptions from "./DownloadOptions";

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

const EssayEditor = ({ essay, onClose, onSaveSuccess }) => {
    const [folder, setFolder] = useState(essay.folder);
    const [tag, setTag] = useState(essay.tag);
    const [comment, setComment] = useState(essay.comment);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [isEssayOpen, setIsEssayOpen] = useState(false); // Controls essay content accordion
    const [isEvaluationOpen, setIsEvaluationOpen] = useState(false); // Controls evaluation report accordion
    const [showEssayDownloadOptions, setShowEssayDownloadOptions] = useState(false);
    const [showEvaluationDownloadOptions, setShowEvaluationDownloadOptions] = useState(false);


    const handleSave = async () => {
        setLoading(true);
        try {
            await axios.put(
                `${API_BASE_URL}/essays/${essay._id}`,
                { folder, tag, comment },
                { withCredentials: true }
            );
            onSaveSuccess();
            onClose();
        } catch (err) {
            setError(err.response?.data?.detail || "Failed to update essay");
        } finally {
            setLoading(false);
        }
    };

    const handleDelete = async () => {
        if (window.confirm("Are you sure you want to delete this essay?")) {
            setLoading(true);
            try {
                await axios.delete(`${API_BASE_URL}/essays/${essay._id}`, {
                withCredentials: true,
                });
                onSaveSuccess(); // Notify parent about changes
                onClose();
            } catch (err) {
                setError(err.response?.data?.detail || "Failed to delete essay");
            } finally {
                setLoading(false);
            }
        }
    };

    return (
        <>
            <div className="modal">
                <div className="modal-content">
                    <h2 style={{ textAlign: "center" }}>Edit Essay</h2>
                    {error && <p style={{ color: "red" }}>{error}</p>}

                    {/* Flex container for Folder and Tag inputs */}
                    <div style={{ display: "flex", justifyContent: "space-between", marginBottom: "20px" }}>
                        <label
                            style={{ flex: 1, marginRight: "20px" }}
                            title="Change folder name (move to another folder, or create a new folder)"
                        >
                            Folder:
                            <input
                                type="text"
                                style={{ width: "100%", marginTop: "5px" }}
                                value={folder}
                                onChange={(e) => setFolder(e.target.value)}
                            />
                        </label>
                        <label
                            style={{ flex: 1 }}
                            title="Edit the essay title"
                        >
                            Tag (Essay Title):
                            <input
                                type="text"
                                style={{ width: "100%", marginTop: "5px" }}
                                value={tag}
                                onChange={(e) => setTag(e.target.value)}
                            />
                        </label>
                    </div>

                    {/* Full-width Comment input */}
                    <label
                        title="Add or edit comments to help recognize the essay easily"
                        style={{ display: "block", marginBottom: "20px" }}
                    >
                        Comment:
                        <textarea
                            style={{ width: "100%", marginTop: "5px", resize: "vertical" }}
                            value={comment}
                            onChange={(e) => setComment(e.target.value)}
                        />
                    </label>

                    {/* Essay Content Accordion */}
                    <div style={{ margin: "20px 0" }}>
                        <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                            <button
                                onClick={() => setIsEssayOpen(!isEssayOpen)}
                                className="btn-toggle-section"
                                title="Click to view the essay. You can also download it once displayed."
                            >
                                {isEssayOpen ? "Hide Essay Content" : "Show Essay Content"}
                            </button>
                            {isEssayOpen && (
                                <button
                                    onClick={() => setShowEssayDownloadOptions(!showEssayDownloadOptions)}
                                    className="btn-download"
                                    style={{ marginLeft: "10px" }}
                                >
                                    Download the Essay
                                </button>
                            )}
                        </div>
                        {isEssayOpen && (
                            <div
                                style={{
                                    marginTop: "10px",
                                    border: "1px solid #ddd",
                                    padding: "10px",
                                    borderRadius: "5px",
                                }}
                            >
                                {essay.essay ? (
                                    <p style={{ maxHeight: "200px", overflowY: "auto", whiteSpace: "pre-wrap", lineHeight: "1.5em" }}>
                                        {essay.essay}
                                    </p>
                                ) : (
                                    <p style={{ fontStyle: "italic", color: "#555" }}>
                                        No essay of this record was found in the database.
                                    </p>
                                )}
                            </div>
                        )}
                        {isEssayOpen && showEssayDownloadOptions && (
                            <DownloadOptions
                                writingPiece={essay.essay} // Pass the essay content here
                                defaultFileName={`Essay_${essay.tag}`}
                                description="Choose the format to download the essay"
                                onClose={() => setShowEssayDownloadOptions(false)} // Optional close callback
                            />
                        )}
                    </div>

                    {/* Evaluation Report Accordion */}
                    <div style={{ margin: "20px 0" }}>
                        <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                            <button
                                title="Click to view the evaluation report. You can also download it once displayed."
                                onClick={() => setIsEvaluationOpen(!isEvaluationOpen)}
                                className="btn-toggle-section"
                            >
                                {isEvaluationOpen ? "Hide Evaluation Report" : "Show Evaluation Report"}
                            </button>
                            {isEvaluationOpen && (
                                <button
                                    onClick={() =>
                                        setShowEvaluationDownloadOptions(!showEvaluationDownloadOptions)
                                    }
                                    className="btn-download"
                                    style={{ marginLeft: "10px" }}
                                >
                                    Download the Evaluation Report
                                </button>
                            )}
                        </div>
                        {isEvaluationOpen && (
                            <div
                                style={{
                                    marginTop: "10px",
                                    border: "1px solid #ddd",
                                    padding: "10px",
                                    borderRadius: "5px",
                                }}
                            >
                                {essay.evaluation_report ? (
                                    <p style={{ maxHeight: "200px", overflowY: "auto", whiteSpace: "pre-wrap", lineHeight: "1.5em" }}>
                                        {essay.evaluation_report}
                                    </p>
                                ) : (
                                    <p style={{ fontStyle: "italic", color: "#555" }}>
                                        No evaluation report associated with this essay was found in the database.
                                    </p>
                                )}
                            </div>
                        )}
                        {isEvaluationOpen && showEvaluationDownloadOptions && (
                            <DownloadOptions
                                writingPiece={essay.evaluation_report} // Pass the evaluation report content here
                                defaultFileName={`Evaluation_Report_${essay.tag}`}
                                description="Choose the format to download the evaluation report"
                                onClose={() => setShowEvaluationDownloadOptions(false)} // Optional close callback
                            />
                        )}
                    </div>

                    {/* Other Information */}
                    <div style={{ marginTop: "20px" }}>
                        <p>
                            <strong>Prompt:</strong> {essay.prompt}
                        </p>
                        <p>
                            <strong>Word Limit:</strong> {essay.word_limit}
                        </p>
                        <div style={{ marginTop: "20px" }}>
                            <p>
                                <strong>Overview of Essay:</strong>
                            </p>
                            <div style={{ marginLeft: "10px", marginTop: "10px" }}>
                                <p style={{ whiteSpace: "pre-wrap", lineHeight: "1.5em" }}>
                                    <strong>Essay:</strong>{" "}
                                    {essay.essay.length > 500
                                        ? `${essay.essay.substring(0, 500)}...`
                                        : essay.essay}
                                </p>
                                {essay.essay.length > 500 && (
                                    <p style={{ fontStyle: "italic" }}>
                                        (Essay content is truncated for preview. Click "Show Essay Content" to view full essay.)
                                    </p>
                                )}
                                <p style={{ whiteSpace: "pre-wrap", lineHeight: "1.5em", marginTop: "20px" }}>
                                    <strong>Evaluation Report:</strong>{" "}
                                    {essay.evaluation_report.length > 500
                                        ? `${essay.evaluation_report.substring(0, 500)}...`
                                        : essay.evaluation_report}
                                </p>
                                {essay.evaluation_report.length > 500 && (
                                    <p style={{ fontStyle: "italic" }}>
                                        (Evaluation report is truncated for preview. Click "Show Evaluation Report" to view full report.)
                                    </p>
                                )}
                            </div>
                        </div>
                    </div>

                    {/* Actions */}
                    <div className="actions" style={{ marginTop: "20px" }}>
                        <button
                            onClick={handleSave}
                            className="btn-record-managing"
                            style={{ marginBottom: "10px" }}
                            disabled={loading}
                        >
                            Save
                        </button>
                        <button
                            className="btn-record-managing"
                            style={{ marginLeft: "10px", marginBottom: "10px" }}
                            onClick={handleDelete}
                            disabled={loading}
                        >
                            Delete
                        </button>
                        <button
                            className="btn-record-managing"
                            style={{ marginLeft: "10px", marginBottom: "10px" }}
                            onClick={onClose}
                        >
                            Cancel
                        </button>
                    </div>
                </div>
            </div>
        </>
    );
};

export default EssayEditor;


