// ProductsServices.js
import React, { useState, useEffect } from 'react';
import { useAppState } from '../components/AppContext';
import '../App.css';
import Products from './subProductsServices/Products';
import Memberships from './subProductsServices/Memberships';
import Purchase from './subProductsServices/Purchase';

const ProductsServices = () => {
    const { state } = useAppState();
    const [activeTab, setActiveTab] = useState("Writing Plans and Extension Options");

    // Check the URL hash on load and set the active tab accordingly
    useEffect(() => {
        const hash = window.location.hash;
        if (hash === "#Products") {
            setActiveTab("Writing Plans and Extension Options");
        } else if (hash === "#Memberships") {
            setActiveTab("Memberships");
        } else if (hash === "#Purchase") {
            setActiveTab("Purchase");
        } else {
            setActiveTab("Writing Plans and Extension Options");
        }
    }, []);

    // Update the URL hash when the active tab changes
    const handleTabClick = (tabName) => {
        setActiveTab(tabName);
        if (tabName === "Writing Plans and Extension Options") {
            window.location.hash = "#Products";
        } else if (tabName === "Memberships") {
            window.location.hash = "#Memberships";
        } else if (tabName === "Purchase") {
            window.location.hash = "#Purchase";
        } else {
            window.location.hash = "#Products";
        }
    };

    // List of tabs
    const tabs = [
        { name: "Writing Plans and Extension Options", component: <Products /> },
        { name: "Memberships", component: <Memberships /> },
        { name: "Purchase", component: <Purchase /> },
    ];

    return (
        <>
            <div className="Page-header">
                <div className="Page-tabs" style={{gap: "10px"}}>
                    {tabs.map((tab) => (
                        <span
                            key={tab.name}
                            className={`Page-tab ${activeTab === tab.name ? "active" : ""}`}
                            onClick={() => handleTabClick(tab.name)}
                        >
                            {tab.name}
                        </span>
                    ))}
                </div>
            </div>
            <div className="Page-maincontent">
                {/* Render the active tab's component */}
                {tabs.find((tab) => tab.name === activeTab)?.component}
            </div>
        </>
    );
};

export default ProductsServices;
