//frontend/src/components/LoginForm.js
import React, { useState } from 'react';
import { setSessionStorageItem } from "./Mask";
import VerificationInterface from "./VerificationInterface";

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

function LoginForm() {
    const [formData, setFormData] = useState({
        username_or_email: '',
        password: '',
    });
    const [showResetForm, setShowResetForm] = useState(false); // State to toggle between forms
    const [error, setError] = useState('');
    const [mfaRequired, setMfaRequired] = useState(false);
    const [phoneNumber, setPhoneNumber] = useState('');
    const [mfaMethod, setMfaMethod] = useState('');
    const [countryCode, setCountryCode] = useState('');
    const [email, setEmail] = useState('');
    const [unverifiedUsername, setUnverifiedUsername] = useState('');
    const [verificationMessage, setVerificationMessage] = useState(''); // Message for verification method
    const [passwordRequest, setPasswordRequest] = useState(false); // State for password reset success
    const [resetEmail, setResetEmail] = useState(''); // Email from successful reset
    const [isSpinnerRunning, setIsSpinnerRunning] = useState(false);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setError('');
        setVerificationMessage('');
        setIsSpinnerRunning(true);

        // Basic validation
        if (!formData.username_or_email || !formData.password) {
            setError('Username and password are required');
            setIsSpinnerRunning(false);
            return;
        }

        try {
            const response = await fetch(`${API_BASE_URL}/login`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(formData),
                credentials: 'include',
            });

            if (!response.ok) {
                const data = await response.json();
                setError(data.detail || 'Login failed. Please try again.');
                setIsSpinnerRunning(false);
            } else {
                const data = await response.json();
                setMfaRequired(data.mfa_required);
                if (data.mfa_required) {
                    setMfaMethod(data.mfa_method);
                    setPhoneNumber(data.phone_number);
                    setCountryCode(data.country_code);
                    setEmail(data.email);
                    setVerificationMessage(data.verification_message);
                    setUnverifiedUsername(data.username);
                    setIsSpinnerRunning(false);
                } else {
                    const newToken = data.session_token;
                    setSessionStorageItem('session_token', newToken);
                    setIsSpinnerRunning(false);
                    window.location.reload();
                }
            }
        } catch (error) {
            if (process.env.NODE_ENV === 'development') {
                console.error('Error:', error);
            }
            setError('Failed to login. Please try again later.');
            setIsSpinnerRunning(false);
        }
    };

    const handlePasswordReset = async () => {
        setError('');
        setVerificationMessage('');
        setIsSpinnerRunning(true);

        try {
            const response = await fetch(`${API_BASE_URL}/reset_password`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ username_or_email: formData.username_or_email }),
            });

            if (response.ok) {
                const data = await response.json();
                setPasswordRequest(true);
                setResetEmail(data.email);
                setShowResetForm(false); // Return to the Login form
                setIsSpinnerRunning(false);
            } else {
                const errorData = await response.json();
                setError(errorData.detail);
                setIsSpinnerRunning(false);
            }
        } catch (error) {
            if (process.env.NODE_ENV === 'development') {
                console.error('Error:', error);
            }
            setError('Failed to reset password. Please try again later.');
            setIsSpinnerRunning(false);
        }
    };

    return (
        <div>
            {showResetForm ? (
                <div>
                    <h3>Reset Password</h3>
                    <input
                        type="text"
                        name="username_or_email"
                        placeholder="Username or Registered Email"
                        required
                        onChange={handleChange}
                        style={{
                            padding: '8px 3px',
                            width: '96%',
                            borderRadius: '4px',
                            border: '1px solid #ccc',
                            fontSize: '12px',
                            marginBottom: '10px',
                        }}
                    />
                    {
                        isSpinnerRunning ? (
                            <div className="simpleSpinner"></div> // Spinner component or div
                        ) : (
                            <button
                                onClick={handlePasswordReset}
                                style={{
                                    fontSize: '14px',
                                    padding: '3px',
                                    backgroundColor: '#f39c12',
                                    color: 'white',
                                    border: '1px solid #ccc',
                                    borderRadius: '4px',
                                    cursor: 'pointer',
                                }}
                            >
                                Request Password Reset
                            </button>
                        )
                    }
                </div>
            ) : (
                <div>
                    <h3>Login</h3>
                    <form onSubmit={handleSubmit}>
                        <input
                            type="text"
                            name="username_or_email"
                            placeholder="Username or Email"
                            required
                            onChange={handleChange}
                            style={{
                                padding: '8px 3px',
                                width: '96%',
                                borderRadius: '4px',
                                border: '1px solid #ccc',
                                fontSize: '12px',
                                marginBottom: '10px',
                            }}
                        />
                        <input
                            type="password"
                            name="password"
                            placeholder="Password"
                            required
                            onChange={handleChange}
                            style={{
                                padding: '8px 3px',
                                width: '96%',
                                borderRadius: '4px',
                                border: '1px solid #ccc',
                                fontSize: '12px',
                                marginBottom: '10px',
                            }}
                        />
                        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                            {
                                isSpinnerRunning ? (
                                    <div className="simpleSpinner"></div> // Spinner component or div
                                ) : (
                                    <button
                                        type="submit"
                                        style={{
                                            fontSize: '14px',
                                            padding: '3px',
                                            backgroundColor: '#2c3e50',
                                            color: 'white',
                                            border: '1px solid #ccc',
                                            borderRadius: '4px',
                                            cursor: 'pointer',
                                        }}
                                    >
                                        &nbsp;&nbsp;Login&nbsp;&nbsp;
                                    </button>
                                )
                            }
                            <button
                                type="button"
                                onClick={() => setShowResetForm(true)}
                                style={{
                                    fontSize: '14px',
                                    padding: '3px',
                                    backgroundColor: '#f39c12',
                                    color: 'white',
                                    border: '1px solid #ccc',
                                    borderRadius: '4px',
                                    cursor: 'pointer',
                                }}
                            >
                                Forget password
                            </button>
                        </div>
                    </form>
                    {passwordRequest && (
                        <p style={{ color: 'green' }}>
                            A new password has been sent to your email of {resetEmail}. Please use the new password to
                            login to your account. After login, you can change your password at the Account/Edit Account
                            page.
                        </p>
                    )}
                    {mfaRequired && (
                        <VerificationInterface
                            mfaRequired={mfaRequired}
                            phoneNumber={phoneNumber}
                            countryCode={countryCode}
                            email={email}
                            mfaMethod={mfaMethod}
                            verificationMessage={verificationMessage}
                            unverifiedUsername={unverifiedUsername}
                        />
                    )}
                </div>
            )}
            {error && <p style={{ color: 'red' }}>{error}</p>}
        </div>
    );
}

export default LoginForm;

