import React, { useEffect, useState } from "react";
import axios from "axios";

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

const CouponAnnouncement = () => {
    const [couponMessage, setCouponMessage] = useState("");
    const [announcementMessage, setAnnouncementMessage] = useState("");

    useEffect(() => {
        const fetchMessages = async () => {
            try {
                const response = await axios.get(`${API_BASE_URL}/check_coupon_announcement`, {
                    withCredentials: true,
                });
                const { coupon_message, announcement_message } = response.data;
                setCouponMessage(coupon_message);
                setAnnouncementMessage(announcement_message);
            } catch (error) {
                if (process.env.NODE_ENV === 'development') {
                    console.error("Error fetching coupon and announcement messages:", error);
                }
            }
        };

        fetchMessages();
    }, []);

  return (
    <div style={{ margin: "20px" }}>
      {couponMessage && (
        <div
          style={{
            border: "1px solid #4CAF50",
            padding: "0px",
            marginBottom: "30px",
            borderRadius: "5px",
            backgroundColor: "#f9fff9",
          }}
          dangerouslySetInnerHTML={{ __html: couponMessage }}
        />
      )}
      {announcementMessage && (
        <div
          style={{
            border: "1px solid #2196F3",
            padding: "15px",
            borderRadius: "5px",
            backgroundColor: "#f0faff",
            color: '#2c3e50'
          }}
          dangerouslySetInnerHTML={{ __html: announcementMessage }}
        />
      )}
    </div>
  );
};

export default CouponAnnouncement;
