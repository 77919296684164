export const evaluationCriteria = `
    <div style={{ fontFamily: 'Arial, sans-serif', lineHeight: '1.6' }}>
        <ol style={{ paddingLeft: '40px', paddingRight: "20px">
            <li><strong>Adherence to Prompt</strong>: The first criterion is how well the essay addresses the specific
            question or topic posed by the prompt. A strong essay directly engages with the prompt, using it as
            a framework to highlight relevant aspects of the applicant's life and ambitions.</li>
            <li><strong>Authenticity and Voice</strong>: A compelling essay has a genuine, authentic tone that reflects the
            unique personality of the writer. The authenticity helps in making a personal connection with the
            reader. The voice should be consistent and personal, allowing the admissions committee to get a sense
            of who the writer is beyond their grades and test scores.</li>
            <li><strong>Clarity and Organization</strong>: The structure of the essay should facilitate an easy and logical
            flow of ideas. Good essays have a clear introduction, body, and conclusion, with each paragraph
            smoothly transitioning to the next. Clarity in writing ensures that the reader understands the
            applicant's message without confusion.</li>
            <li><strong>Depth of Insight and Reflection</strong>: Effective essays go beyond superficial narratives. They
            offer deep personal insights and reflections that reveal the thinker behind the application. Colleges
            look for students who can think critically about their experiences and what they’ve learned from them.</li>
            <li><strong>Relevance and Detail</strong>: The details and examples used in the essay should be relevant and
            support the central narrative or argument. Specific details help illustrate larger points and add
            depth and credibility to the personal stories shared.</li>
            <li><strong>Creativity</strong>: While not all essays need to be creatively written, an element of creativity in
            either the essay's structure or its narrative style can help it stand out. This might mean presenting
            ideas in an unusual structure, using compelling imagery, or applying a unique thematic focus.</li>
            <li><strong>Grammar and Style</strong>: Proper grammar, punctuation, and spelling are fundamental. Errors can
            distract from the content and may lead the admissions committee to question the applicant's writing
            skills or attention to detail.</li>
            <li><strong>Impact and Persuasiveness</strong>: Ultimately, a great essay not only informs but also engages and
            persuades. It should leave a lasting impression, making the reader feel that the applicant would be a
            valuable addition to their school community.</li>
            <li><strong>Alignment with College Values</strong>: Understanding and aligning with the values or characteristics
            prized by the target institution can enhance an essay's effectiveness. If a college values community
            service, for example, an essay that authentically showcases this commitment can resonate more strongly.</li>
        </ol>
        <p>This is just a short list of common factors that college admission committees may consider when
        evaluating application essays. There is no fixed formula for this process. Other elements, such as
        the essay's alignment with the institution’s mission, the applicant's demonstrated resilience or
        leadership qualities, and the potential for academic and extracurricular contribution, may also
        influence the committee's decisions.</p>
        <p>Evaluating application essays is more an art than a science, blending objective criteria with
        subjective interpretation. For you, the most crucial aspect is to authentically represent yourself in
        your essay, allowing your unique voice and experiences to shine through.</p>
    </div>
`;
