import React, { useState } from "react";
import PropTypes from "prop-types";

const FileUpload = ({ onFileLoad }) => {
    const [fileError, setFileError] = useState(null);

    const handleFileUpload = (event) => {
        const file = event.target.files[0];
        if (file) {
            const fileType = file.name.split('.').pop().toLowerCase();
            const reader = new FileReader();

            reader.onload = (e) => {
                try {
                    let fileContent = e.target.result;
                    if (fileType === "txt") {
                        onFileLoad(fileContent);
                    } else if (fileType === "docx") {
                        import("mammoth").then(mammoth => {
                            mammoth.extractRawText({ arrayBuffer: fileContent })
                                .then(result => onFileLoad(result.value))
                                .catch(() => setFileError("Error processing DOCX file."));
                        });
                    } else if (fileType === "pdf") {
                        import("pdfjs-dist/webpack").then(pdfjsLib => {
                            pdfjsLib.getDocument({ data: fileContent }).promise
                                .then(pdfDoc => {
                                    let content = "";
                                    const numPages = pdfDoc.numPages;
                                    let readPages = Array.from({ length: numPages }, (_, i) => {
                                        return pdfDoc.getPage(i + 1).then(page => {
                                            return page.getTextContent().then(textContent => {
                                                textContent.items.forEach(item => content += item.str + " ");
                                            });
                                        });
                                    });
                                    Promise.all(readPages).then(() => onFileLoad(content));
                                })
                                .catch(() => setFileError("Error processing PDF file."));
                        });
                    } else {
                        setFileError("Unsupported file type. Please upload a .txt, .docx, or .pdf file.");
                    }
                } catch (error) {
                    setFileError("An error occurred while processing the file.");
                }
            };

            if (fileType === "pdf" || fileType === "docx") {
                reader.readAsArrayBuffer(file);
            } else if (fileType === "txt") {
                reader.readAsText(file);
            } else {
                setFileError("Unsupported file type. Please upload a .txt, .docx, or .pdf file.");
            }
        }
    };

    return (
        <div className="file-upload-container">
            <input type="file" onChange={handleFileUpload} accept=".txt, .docx, .pdf" />
            {fileError && <p className="error-text">{fileError}</p>}
        </div>
    );
};

FileUpload.propTypes = {
    onFileLoad: PropTypes.func.isRequired
};

export default FileUpload;

