// frontend/src/homepage.js
import React, {useState, useEffect} from 'react';
import '../App.css';
import Welcome from './subHomepage/Welcome';
import AboutUs from './subHomepage/AboutUs';
import { useAppState } from '../components/AppContext';

const Home = () => {
    const { state } = useAppState(); // Access state from AppContext
    const [activeTab, setActiveTab] = useState("Welcome");

    // Check the URL hash on load and set the active tab accordingly
    useEffect(() => {
        const hash = window.location.hash;
        if (hash === "#Welcome") {
            setActiveTab("Welcome");
        } else if (hash === "#AboutUs") {
            setActiveTab("About Us");
        } else {
            setActiveTab("Welcome");
        }
    }, []);

    // Update the URL hash when the active tab changes
    const handleTabClick = (tabName) => {
        setActiveTab(tabName);
        if (tabName === "Welcome") {
            window.location.hash = "#Welcome";
        } else if (tabName === "About Us") {
            window.location.hash = "#AboutUs";
        } else {
            window.location.hash = "#Welcome";
        }
    };

    const tabs = [
        { name: "Welcome", component: <Welcome /> },
        { name: "About Us", component: <AboutUs /> },
    ];

    return (
        <>
            <div className="Page-header">
                <div className="Page-tabs">
                    {tabs.map((tab) => (
                        <span
                            key={tab.name}
                            className={`Page-tab ${activeTab === tab.name ? "active" : ""}`}
                            onClick={() => handleTabClick(tab.name)}
                        >
                            {tab.name}
                        </span>
                    ))}
                </div>
            </div>
            <div className="Page-main">
                {/* Render the active tab's component */}
                {tabs.find((tab) => tab.name === activeTab).component}
            </div>
        </>
    );
};

export default Home;
