import React, { useState, useEffect, useContext } from 'react';
import { SettingsContext } from '../../components/SettingsContext';
import { getSessionStorageItem, setSessionStorageItem } from "../../components/Mask";

const PageSetting = () => {
    const { settings, updateSettings } = useContext(SettingsContext);

    const [pageWidth, setPageWidth] = useState(settings.pageWidth || "80%");
    const [fontSize, setFontSize] = useState(settings.fontSize || "medium");
    const [colorTheme, setColorTheme] = useState(settings.colorTheme || "light");

    useEffect(() => {
        // Synchronize local state with context when settings change
        setPageWidth(settings.pageWidth);
        setFontSize(settings.fontSize);
        setColorTheme(settings.colorTheme);
    }, [settings]);

    const handlePageWidthChange = (e) => {
        const newWidth = e.target.value;
        updateSettings({ pageWidth: newWidth });
        setSessionStorageItem('page_width', newWidth);
    };

    const handleFontSizeChange = (e) => {
        const newFontSize = e.target.value;
        updateSettings({ fontSize: newFontSize });
        setSessionStorageItem('font_size', newFontSize);
    };

    const handleColorThemeChange = (e) => {
        const newTheme = e.target.value;
        updateSettings({ colorTheme: newTheme });
        setSessionStorageItem('color_theme', newTheme);
    };

    return (
        <>
            <h1>Page Settings</h1>
            <div className="textarea-container">
                <div className="textarea">
                    <p style={{ marginTop: '2em', marginLeft: '0px', marginBottom: '0px' }}>
                        On this page, you are able to:
                    </p>
                    <ul>
                        <li>Adjust the window size for a more comfortable interface.</li>
                        <li>Change the font size to enhance readability.</li>
                        <li>Select your preferred color theme for a personalized experience.</li>
                    </ul>
                </div>
            </div>
            <div
                style={{
                    border: "1px solid #ccc",
                    borderRadius: "8px",
                    padding: "1.5em",
                    marginTop: "2.5em",
                    width: '80%',
                    minWidth: "450px"
                }}
            >
                <div
                    style={{
                        display: "grid",
                        gridTemplateColumns: "1fr 1fr 1fr",
                        gap: "10px",
                        textAlign: "center",
                        fontWeight: "bold",
                    }}
                >
                    <div></div>
                    <div>Current Setting</div>
                    <div>Edit</div>
                </div>
                <div
                    style={{
                        display: "grid",
                        gridTemplateColumns: "1fr 1fr 1fr",
                        gap: "10px",
                        marginTop: "20px",
                        textAlign: "center",
                        alignItems: "center",
                    }}
                >
                    {/* Page Width */}
                    <div>Page Width</div>
                    <div>{pageWidth}</div>
                    <div>
                        <select
                            value={pageWidth}
                            onChange={handlePageWidthChange}
                            style={{
                                padding: "5px 10px",
                                border: "1px solid #ccc",
                                borderRadius: "4px",
                                fontSize: "0.8em",
                                width: "8em",
                            }}
                        >
                            <option value="50%">50%</option>
                            <option value="60%">60%</option>
                            <option value="70%">70%</option>
                            <option value="80%">80%</option>
                            <option value="90%">90%</option>
                            <option value="100%">100%</option>
                        </select>
                    </div>

                    {/* Font Size */}
                    <div>Font Size</div>
                    <div>{fontSize}</div>
                    <div>
                        <select
                            value={fontSize}
                            onChange={handleFontSizeChange}
                            style={{
                                padding: "5px 10px",
                                border: "1px solid #ccc",
                                borderRadius: "4px",
                                fontSize: "0.8em",
                                width: "8em",
                            }}
                        >
                            <option value="very small">Very Small</option>
                            <option value="small">Small</option>
                            <option value="medium">Medium</option>
                            <option value="large">Large</option>
                            <option value="very large">Very Large</option>
                        </select>
                    </div>

                    {/* Color Theme */}
                    <div>Color Theme</div>
                    <div>{colorTheme}</div>
                    <div>
                        <select
                            value={colorTheme}
                            onChange={handleColorThemeChange}
                            style={{
                                padding: "5px 10px",
                                border: "1px solid #ccc",
                                borderRadius: "4px",
                                fontSize: "0.8em",
                                width: "8em",
                            }}
                        >
                            <option value="light">Light</option>
                            <option value="softGray">Soft Gray</option>
                            <option value="dark">Dark</option>
                            <option value="blue">Blue</option>
                            <option value="green">Green</option>
                            <option value="emerald">Emerald</option>
                            <option value="sunset">Sunset</option>
                            <option value="ocean">Ocean</option>
                            <option value="forest">Forest</option>
                        </select>
                    </div>
                </div>
            </div>
        </>
    );
};

export default PageSetting;
