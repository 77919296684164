const UsageSuggestionsConstant = (
    <div className="textarea-container">
        <div className="textarea">
            <h2 style={{ fontSize: '1.8em', fontWeight: 'bold' }}>Important Notices</h2>
            
            <h3 style={{marginLeft: "0pt", marginTop: "0pt", marginBottom: "10px"}}>1. Secure Your Credentials</h3>
            <p style={{ marginLeft: '20px' }}>
                Protect your login credentials and avoid sharing them with others, as the app is designed for personal use across multiple devices.
            </p>
            
            <h3 style={{marginLeft: "0pt", marginTop: "0pt", marginBottom: "10px"}}>2. Downloading Writings</h3>
            <p style={{ marginLeft: '20px' }}>
                Download your writings for reference, as the system doesn't store them for privacy. Unsaved writings cannot be recovered later.
            </p>
            
            <h3 style={{marginLeft: "0pt", marginTop: "0pt", marginBottom: "10px"}}>3. App Usage Recommendations</h3>
            <p style={{ marginLeft: '20px' }}>
                Use the app wisely; consider generated writings as references. It's your responsibility to judge and decide how to use the content.
            </p>
            
            <h3 style={{marginLeft: "0pt", marginTop: "0pt", marginBottom: "10px"}}>4. Patience with SophoScriptor</h3>
            <p style={{ marginLeft: '20px' }}>
                SophoScriptor may produce creative essays with unexpected outputs. Understand that it’s powered by advanced AI models with unique logics. Please treat SophoScriptor with respect for her special capabilities.
            </p>
            <p style={{ marginLeft: '0px', marginTop: "20px" }}>
                If you encounter any issues or have questions, please contact us by text message or email.
            </p>
        </div>
    </div>
);

export default UsageSuggestionsConstant;