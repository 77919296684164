import React, { useState } from "react";

const VideoPlaceholder = () => {
    const [isPlaying, setIsPlaying] = useState(false);

    // URL of the video from S3
    const videoUrl = "https://sophoscriptor-archive.s3.amazonaws.com/v1_SophoScriptor_intro.mp4";

    // Function to handle video playback
    const handlePlayVideo = () => {
        setIsPlaying(true); // Show the video player
    };

    return (
        <div className="video-placeholder-container">
            {/* Placeholder content */}
            {!isPlaying && (
                <>
                    <div className="video-text-overlay">
                        <h1>SophoScriptor</h1>
                        <h2>Spark Your Creativity</h2>
                        <h2>Discover Your Authentic Self</h2>
                    </div>
                    <div className="video-play-button" onClick={handlePlayVideo}>
                        <div className="circle">
                            <div className="arrow"></div>
                        </div>
                    </div>
                </>
            )}

            {/* Video player */}
            {isPlaying && (
                <video className="video-element" width="100%" controls autoPlay>
                    <source src={videoUrl} type="video/mp4" />
                    Your browser does not support the video tag.
                </video>
            )}
        </div>
    );
};

export default VideoPlaceholder;
