import React, { useState, useEffect, useRef } from "react";
import axios from 'axios';
import getAIAccount from '../../components/GetAIAccount';
import MembershipsExplanation from '../../constants/MembershipsExplanation';
import GreetingMessage from '../../components/greeting';


const aiAccount = getAIAccount();

function Memberships() {

    return (
        <>
            <h1  style={{marginBottom: "0px"}}>Memberships</h1>
            <GreetingMessage />
            <MembershipsExplanation />
        </>
    );
}

export default Memberships;