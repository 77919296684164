import React, { useState, useEffect } from 'react';
import { Tooltip } from 'react-tooltip';

const EssayOptionsBox = ({
    writingIdea,
    collegeIdea,
    collegePrompt,
    writingFramework,
    evaluationSuggestion,
    limitLower,
    wordLimit,
    draftEssay,
    options,
    setOptions,
    onOptionChange,
    loadEssayVersion
}) => {
    const [sourceExplanation, setSourceExplanation] = useState('');
    const [approachExplanations, setApproachExplanations] = useState([]);
    const [newEssayLabel, setNewEssayLabel] = useState('Write a New Essay'); // Default label

    const handleSourceChange = (selectedSource) => {
        const newOptions = {
            ...options,
            source: options.source === selectedSource ? '' : selectedSource,
        };
        setOptions(newOptions);
        onOptionChange(newOptions);

        if (selectedSource === 'lastVersion' && options.source !== 'lastVersion') {
            loadEssayVersion();
        }
    };

    const handleApproachChange = (name) => {
        const newOptions = { ...options };

        if (name === 'applyBrainstorming') {
            newOptions.applyBrainstorming = !options.applyBrainstorming;
            if (newOptions.applyBrainstorming) {
                newOptions.applyEvaluation = false; // Ensure exclusivity
            }
        } else if (name === 'applyEvaluation') {
            newOptions.applyEvaluation = !options.applyEvaluation;
            if (newOptions.applyEvaluation) {
                newOptions.applyBrainstorming = false; // Ensure exclusivity
            }
        } else {
            newOptions[name] = !options[name]; // For "Finalize Essay"
        }

        setOptions(newOptions);
        onOptionChange(newOptions);
    };

    // Update label and explanation dynamically based on draftEssay
    useEffect(() => {
        if (draftEssay) {
            setNewEssayLabel('Current Essay on Screen');
            setSourceExplanation('Revise the essay on screen to strengthen your application.');
        } else {
            setNewEssayLabel('Write a New Essay');
            setSourceExplanation(
                collegePrompt && wordLimit && collegeIdea
                    ? 'Write a new sample essay for the writing prompt and word limit.'
                    : 'Provide a writing prompt, word limit, and writing idea to start.'
            );
        }
    }, [draftEssay, collegePrompt, wordLimit, collegeIdea]);

    // Update approach explanations
    useEffect(() => {
        const explanations = [];

        if (options.applyBrainstorming) {
            explanations.push(
                collegePrompt && wordLimit && writingIdea && writingFramework
                    ? 'Writing a new essay based on the Writing Ideas and Writing Framework generated from Brainstorming.'
                    : 'Please provide a writing prompt, word limit, and start brainstorming first.'
            );
        }

        if (options.applyEvaluation) {
            explanations.push(
                collegePrompt && wordLimit && draftEssay && evaluationSuggestion
                    ? 'Revise the draft essay with the suggestions from the evaluation report.'
                    : 'Please provide an essay and start an evaluation process first.'
            );
        }

        if (options.finalizeEssay) {
            if (collegePrompt && wordLimit && limitLower && draftEssay) {
                explanations.push(
                    `Revise your essay to fit the word limit range of ${limitLower}-${wordLimit}. This is resource-intensive, and the words will be counted tenfold if the essay falls within the range.`
                );
            } else if (collegePrompt && wordLimit && limitLower) {
                explanations.push(
                    `Write a new essay to fit the word limit range of ${limitLower}-${wordLimit}. This is resource-intensive, and the words will be counted tenfold if the essay falls within the range.`
                );
            } else {
                explanations.push(
                    'Provide a writing prompt, word limit, and draft essay to start.'
                );
            }
        }

        setApproachExplanations(explanations);
    }, [
        options.applyBrainstorming,
        options.applyEvaluation,
        options.finalizeEssay,
        collegePrompt,
        wordLimit,
        writingIdea,
        writingFramework,
        draftEssay,
        evaluationSuggestion,
        limitLower,
    ]);

    return (
        <div className="options-box">
            <div className="options-container">
                <div className="option-group">
                    <label>Choose Essay Source:</label>
                    <div>
                        <input
                            type="checkbox"
                            checked={options.source === 'newEssay'}
                            onChange={() => handleSourceChange('newEssay')}
                            data-tooltip-id="newEssayTooltip"
                        />
                        {newEssayLabel}
                        <Tooltip id="newEssayTooltip">
                            {draftEssay
                                ? 'Continue working on the essay currently displayed on screen.'
                                : 'Start writing a new sample essay using the provided prompt and word limit.'}
                        </Tooltip>
                    </div>
                    <div>
                        <input
                            type="checkbox"
                            checked={options.source === 'lastVersion'}
                            onChange={() => handleSourceChange('lastVersion')}
                            data-tooltip-id="lastVersionTooltip"
                        />
                        From Last Essay Writing
                        <Tooltip id="lastVersionTooltip">
                            Load the last essay version to revise or continue writing.
                        </Tooltip>
                    </div>
                    <div>
                        <input
                            type="checkbox"
                            checked={options.source === 'fromMyComputer'}
                            onChange={() => handleSourceChange('fromMyComputer')}
                            data-tooltip-id="fromMyComputerTooltip"
                        />
                        From My Computer
                        <Tooltip id="fromMyComputerTooltip">
                            Upload an essay from your computer. Supported formats: .txt, .docx, .pdf.
                        </Tooltip>
                    </div>
                    <hr />
                    <div className="explanation">{sourceExplanation}</div>
                </div>

                <div className="option-group">
                    <label>Choose Writing Approach:</label>
                    <div>
                        <input
                            type="checkbox"
                            checked={options.applyBrainstorming}
                            onChange={() => handleApproachChange('applyBrainstorming')}
                            data-tooltip-id="applyBrainstormingTooltip"
                        />
                        Apply Brainstorming
                        <Tooltip id="applyBrainstormingTooltip">
                            Use ideas and framework from the Brainstorming process to write a new sample essay.
                        </Tooltip>
                    </div>
                    <div>
                        <input
                            type="checkbox"
                            checked={options.applyEvaluation}
                            onChange={() => handleApproachChange('applyEvaluation')}
                            data-tooltip-id="applyEvaluationTooltip"
                        />
                        Apply Evaluation
                        <Tooltip id="applyEvaluationTooltip">
                            Revise the essay based on evaluation suggestions.
                        </Tooltip>
                    </div>
                    <div>
                        <input
                            type="checkbox"
                            checked={options.finalizeEssay}
                            onChange={() => handleApproachChange('finalizeEssay')}
                            data-tooltip-id="finalizeEssayTooltip"
                        />
                        Finalize Essay
                        <Tooltip id="finalizeEssayTooltip">
                            Adjust the essay to match the specified word limit range.
                        </Tooltip>
                    </div>
                    <hr />
                    <div className="explanation">
                        {approachExplanations.map((explanation, index) => (
                            <p style={{ marginTop: "0px", marginBottom: "10px" }} key={index}>
                                {explanation}
                            </p>
                        ))}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default EssayOptionsBox;




