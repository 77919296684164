import React from 'react';

function HamburgerMenu({ toggleSidebar }) {
  return (
    <button className="hamburger" onClick={toggleSidebar}>
      ☰
    </button>
  );
}

export default HamburgerMenu;
