import React, { useState, useEffect } from 'react';
import { useAccountInfo } from '../../components/UserAccountInfo';
import countriesAndCodes from '../../constants/countriesAndCodes';
import { getSessionStorageItem, updateSessionData } from "../../components/Mask";

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

const EditAccount = () => {
    const accountInfo = useAccountInfo();

    // State variables for editing
    const [nickname, setNickname] = useState(getSessionStorageItem("nickname") || "");
    const [email, setEmail] = useState(getSessionStorageItem("email") || "");
    const [firstname, setFirstname] = useState(getSessionStorageItem("firstname") || "");
    const [lastname, setLastname] = useState(getSessionStorageItem("lastname") || "");
    const [accountMFA, setAccountMFA] = useState(getSessionStorageItem("account_mfa") || false);
    const [mfaMethod, setMfaMethod] = useState(getSessionStorageItem('mfa_method') || null);
    const [country, setCountry] = useState(getSessionStorageItem("country") || "United States (+1)");
    const [countryCode, setCountryCode] = useState(getSessionStorageItem("country_code") || "+1");
    const [phoneNumber, setPhoneNumber] = useState(getSessionStorageItem("phone_number") || "");


    const [showPasswordInput, setShowPasswordInput] = useState(false);
    const [newPassword, setNewPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [showNewPassword, setShowNewPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);
    const [passwordError, setPasswordError] = useState('');

    const [nicknameSuccessMessage, setNicknameSuccessMessage] = useState("");
    const [nicknameErrorMessage, setNicknameErrorMessage] = useState("");
    const [nameSuccessMessage, setNameSuccessMessage] = useState("");
    const [nameErrorMessage, setNameErrorMessage] = useState("");
    const [phoneNumberSuccessMessage, setPhoneNumberSuccessMessage] = useState("");
    const [phoneNumberErrorMessage, setPhoneNumberErrorMessage] = useState("");
    const [emailSuccessMessage, setEmailSuccessMessage] = useState("");
    const [emailErrorMessage, setEmailErrorMessage] = useState("");
    const [accountMFASuccessMessage, setAccountMFASuccessMessage] = useState("");
    const [accountMFAErrorMessage, setAccountMFAErrorMessage] = useState("");
    const [mfaMethodError, setMfaMethodError] = useState('')

    // Update state with accountInfo when available
    useEffect(() => {
        if (accountInfo) {
            updateSessionData("nickname", accountInfo.nickname, setNickname);
            updateSessionData("email",accountInfo.email, setEmail);
            updateSessionData("firstname", accountInfo.firstname, setFirstname);
            updateSessionData("lastname", accountInfo.lastname, setLastname);
            updateSessionData("account_mfa", accountInfo.account_mfa, setAccountMFA);
            updateSessionData("mfa_method", accountInfo.mfa_method, setMfaMethod);
            updateSessionData("country", accountInfo.country, setCountry);
            updateSessionData("country_code", accountInfo.country_code, setCountryCode);
            updateSessionData("phone_number", accountInfo.phone_number, setPhoneNumber);
        }
    }, [accountInfo]);

    // Handlers for changes
    const handleNicknameChange = (e) => setNickname(e.target.value);
    const handleEmailChange = (e) => setEmail(e.target.value);
    const handleFirstnameChange = (e) => setFirstname(e.target.value);
    const handleLastnameChange = (e) => setLastname(e.target.value);
    const handleMFAChange = () => setAccountMFA(!accountMFA);
    const handleMfaMethodChange = (e) => {
        const method = e.target.value;
        setMfaMethod(method);
        if (method === 'phone' && !phoneNumber) {
            setMfaMethodError('Please provide a phone number first using the Edit Phone Number section below.');
        } else {
            setMfaMethodError('');
        }
    };
    const handleCountryChange = (e) => {
        const selectedCountry = countriesAndCodes.find(
            (country) => country.name === e.target.value
        );
        setCountry(selectedCountry.name);
        setCountryCode(selectedCountry.code);
    };
    const handlePhoneNumberChange = (e) => setPhoneNumber(e.target.value);

    // Handlers for backend calls with success messages
    const updateNickname = async () => {
        try {
            const response = await fetch(`${API_BASE_URL}/account_change_nickname`, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ new_nickname: nickname }),
                credentials: 'include'
            });

            if (!response.ok) { // Check if the response status is not OK
                const errorData = await response.json(); // Parse the error message
                throw new Error(errorData.detail); // Throw an error with the detail message
            }

            setNicknameSuccessMessage("Nickname has been changed successfully");
            setNicknameErrorMessage("");

            // after a successful update, set the hash, and reload the page in 3 seconds
            setTimeout(() => {
                window.location.hash = "#EditAccount"; // Set the hash
                window.location.reload(); // Reload the page
            }, 3000);
        } catch (error) {
            setNicknameSuccessMessage(""); // Clear success message if there's an error
            setNicknameErrorMessage(error.message); // Display the error message to the user
        }
    };

    const updateEmail = async () => {
        try {
            const response = await fetch(`${API_BASE_URL}/account_change_email`, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ new_email: email }),
                credentials: 'include'
            });

            if (!response.ok) { // Check if the response status is not OK
                const errorData = await response.json(); // Parse the error message
                throw new Error(errorData.detail); // Throw an error with the detail message
            }

            setEmailSuccessMessage("Email has been changed successfully");
            setEmailErrorMessage("");

            // Schedule a page reload after 3 seconds
            setTimeout(() => {
                window.location.reload();
            }, 3000);
        } catch (error) {
            setEmailSuccessMessage("");
            setEmailErrorMessage(error.message);
        }
    };

    const updateName = async () => {
        try {
            const response = await fetch(`${API_BASE_URL}/account_change_name`, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ new_first_name: firstname, new_last_name: lastname }),
                credentials: 'include'
            });

            if (!response.ok) { // Check if the response status is not OK
                const errorData = await response.json(); // Parse the error message
                throw new Error(errorData.detail); // Throw an error with the detail message
            }

            setNameSuccessMessage("Name has been changed successfully");
            setNameErrorMessage("");

            // Schedule a page reload after 3 seconds
            setTimeout(() => {
                window.location.reload();
            }, 3000);
        } catch (error) {
            setNameSuccessMessage("");
            setNameErrorMessage(error.message);
        }
    };

    const updateMFA = async () => {
        if (accountMFA && mfaMethod === 'phone' && !phoneNumber) {
            setAccountMFAErrorMessage('Cannot enable MFA via phone without a valid phone number.');
            return;
        }

        try {
            const payload = { account_mfa: accountMFA };
            if (accountMFA) {
                payload.mfa_method = mfaMethod; // Include MFA method only when MFA is enabled
            }

            const response = await fetch(`${API_BASE_URL}/account_change_mfa`, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(payload),
                credentials: 'include'
            });

            if (!response.ok) { // Check if the response status is not OK
                const errorData = await response.json(); // Parse the error message
                throw new Error(errorData.detail); // Throw an error with the detail message
            }

            setAccountMFASuccessMessage("MFA setting has been updated successfully");
            setAccountMFAErrorMessage("");

            // Schedule a page reload after 3 seconds
            setTimeout(() => {
                window.location.reload();
            }, 3000);
        } catch (error) {
            setAccountMFASuccessMessage("");
            setAccountMFAErrorMessage(error.message);
        }
    };

    const isMFASubmitDisabled = (accountMFA && !mfaMethod) || (accountMFA && mfaMethod === 'phone' && !phoneNumber) || mfaMethodError;

    const updatePhoneNumber = async () => {
        const defaultCountryCode = "+1";
        const defaultCountry = "United States (+1)";

        if (!countryCode) {
            countryCode = defaultCountryCode;
        }
        if (!country) {
            country = defaultCountry;
        }

        try {
            const response = await fetch(`${API_BASE_URL}/account_change_phone`, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ country_code: countryCode, country: country, phone_number: phoneNumber }),
                credentials: 'include'
            });

            if (!response.ok) { // Check if the response status is not OK
                const errorData = await response.json(); // Parse the error message
                throw new Error(errorData.detail); // Throw an error with the detail message
            }

            setPhoneNumberSuccessMessage("Phone number has been changed successfully");
            setPhoneNumberErrorMessage("");

            // Schedule a page reload after 3 seconds
            setTimeout(() => {
                window.location.reload();
            }, 3000);
        } catch (error) {
            setPhoneNumberSuccessMessage("");
            setPhoneNumberErrorMessage(error.message);
        }
    };

    const validatePassword = (password) => {
        // Check if the password is at least 10 characters long and contains both letters, digits, and special characters
        const isValid = /^(?=.*[a-zA-Z])(?=.*\d)(?=.*[!@#$%^&*()\-_+=\[\]{}|;:'",.<>?/]).{10,}$/.test(password);
        return isValid;
    };

    const handleChangePassword = async () => {
        setPasswordError('');
        if (!validatePassword(newPassword)) {
            setPasswordError(
                'Password must be at least 10 characters long. Letters, digits, and special characters are allowed.'
            );
            return;
        }
        if (newPassword !== confirmPassword) {
            setPasswordError('Passwords do not match.');
            return;
        }

        try {
            // Call the backend 'change_password' function
            const response = await fetch(`${API_BASE_URL}/account_change_password`, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ new_password: newPassword }),
                credentials: "include"
            });

            if (response.ok) {
                alert('Password changed successfully!');
                setShowPasswordInput(false);
                setNewPassword('');
                setConfirmPassword('');
            } else {
                setPasswordError('Failed to change password. Please try again.');
            }
        } catch (error) {
            setPasswordError('An error occurred. Please try again.');
        }
    };

    return (
        <>
            <div>
                <h1>Edit Your Account</h1>
            </div>
            <div className="edit-account-container">
                <div className="edit-section">
                    <div className="edit-section-header">
                        <h3>Edit Nickname</h3>
                        <button className='btn-record-action' onClick={updateNickname}>Submit Change</button>
                    </div>
                    <p>Current Nickname: {accountInfo?.nickname || "Not set"}</p>
                    <input
                        type="text"
                        value={nickname}
                        onChange={handleNicknameChange}
                        placeholder="Enter new nickname"
                    />
                    {nicknameSuccessMessage && <p style={{ color: 'green', marginLeft: "40px" }}>{nicknameSuccessMessage}</p>}
                    {nicknameErrorMessage && <p style={{ color: 'red', marginLeft: "40px" }}>{nicknameErrorMessage}</p>}
                </div>

                <div className="edit-section">
                    <div className="edit-section-header">
                        <h3>Edit Email</h3>
                        <button className='btn-record-action' onClick={updateEmail}>Submit Change</button>
                    </div>
                    <p>Current Email: {accountInfo?.email}</p>
                    <input
                        type="email"
                        value={email}
                        onChange={handleEmailChange}
                        placeholder="Enter new email"
                    />
                    {emailSuccessMessage && <p style={{ color: 'green', marginLeft: "40px" }}>{emailSuccessMessage}</p>}
                    {emailErrorMessage && <p style={{ color: 'red', marginLeft: "40px" }}>{emailErrorMessage}</p>}
                </div>

                <div className="edit-section">
                    <div className="edit-section-header">
                        <h3>Edit Name</h3>
                        <button className='btn-record-action' onClick={updateName}>Submit Change</button>
                    </div>
                    <p>Current First Name: {accountInfo?.firstname}</p>
                    <p>Current Last Name: {accountInfo?.lastname}</p>
                    <div className="edit-section-header">
                        <input
                            type="text"
                            style={{marginRight: "10px"}}
                            value={firstname}
                            onChange={handleFirstnameChange}
                            placeholder="First Name"
                        />
                        <input
                            type="text"
                            value={lastname}
                            onChange={handleLastnameChange}
                            placeholder="Last Name"
                        />
                    </div>
                    {nameSuccessMessage && <p style={{ color: 'green', marginLeft: "40px" }}>{nameSuccessMessage}</p>}
                    {nameErrorMessage && <p style={{ color: 'red', marginLeft: "40px" }}>{nameErrorMessage}</p>}
                </div>

                <div className="edit-section">
                    <div className="edit-section-header">
                        <h3>Edit Account MFA</h3>
                        <button className='btn-record-action' onClick={updateMFA} disabled={isMFASubmitDisabled}>Submit Change</button>
                    </div>
                    <p>
                        MFA is currently {accountInfo?.account_mfa ? 'Enabled' : 'Disabled'}
                        {accountMFA && mfaMethod && `, and your current MFA method is set to ${accountInfo?.mfa_method === 'phone' ? 'Phone' : 'Email'}.`}
                    </p>
                    <label className="left-aligned">
                        <input
                            type="checkbox"
                            checked={accountMFA}
                            onChange={handleMFAChange}
                        />
                        Enable MFA
                    </label>
                    {accountMFA && (
                        <div style={{ marginLeft: '25px', marginTop: '0px' }}>
                            <p style={{marginTop: "0px", marginBottom: "10px"}}>Select MFA Method</p>
                            <label className="left-aligned">
                                <input
                                    type="radio"
                                    name="mfaMethod"
                                    value="phone"
                                    checked={mfaMethod === 'phone'}
                                    onChange={handleMfaMethodChange}
                                />
                                Phone
                            </label>
                            <label>
                                <input
                                    type="radio"
                                    name="mfaMethod"
                                    value="email"
                                    checked={mfaMethod === 'email'}
                                    onChange={handleMfaMethodChange}
                                />
                                Email
                            </label>
                            {mfaMethodError && (
                                <p style={{ color: 'red', marginTop: '10px' }}>{mfaMethodError}</p>
                            )}
                        </div>
                    )}

                    {accountMFASuccessMessage && (
                        <p style={{ color: 'green', marginLeft: '40px' }}>
                            {accountMFASuccessMessage}
                        </p>
                    )}
                    {accountMFAErrorMessage && (
                        <p style={{ color: 'red', marginLeft: '40px' }}>
                            {accountMFAErrorMessage}
                        </p>
                    )}
                </div>

                <div className="edit-section">
                    <div className="edit-section-header">
                        <h3>Edit Phone Number</h3>
                        <button className='btn-record-action' onClick={updatePhoneNumber}>Submit Change</button>
                    </div>
                    <p>Current Phone: {accountInfo?.country_code} {accountInfo?.phone_number}</p>
                    <div className="edit-section-header">
                        <div className="responsive-row">
                            <select
                                className="responsive-select"
                                value={country || "United States (+1)"} onChange={handleCountryChange}>
                                {countriesAndCodes.map((country) => (
                                    <option key={country.index} value={country.name}>
                                        {country.name}
                                    </option>
                                ))}
                            </select>
                            <input
                                className="responsive-input"
                                type="text"
                                value={phoneNumber}
                                onChange={handlePhoneNumberChange}
                                placeholder="Enter phone number"
                            />
                        </div>
                    </div>
                    {phoneNumberSuccessMessage && <p style={{ color: 'green', marginLeft: "40px" }}>{phoneNumberSuccessMessage}</p>}
                    {phoneNumberErrorMessage && <p style={{ color: 'red', marginLeft: "40px" }}>{phoneNumberErrorMessage}</p>}
                </div>

                <div className="edit-section">
                    <div className="edit-section-header">
                        <h3>Reset Password</h3>
                        {showPasswordInput ? (
                            <button
                                className="btn-record-action"
                                onClick={handleChangePassword}
                            >
                                Submit Change
                            </button>
                        ) : (
                            <button
                                className="btn-record-action"
                                onClick={() => setShowPasswordInput(true)}
                            >
                                Change Password
                            </button>
                        )}
                    </div>
                    {showPasswordInput && (
                        <div>
                            <label>
                                <p style={{ fontWeight: "normal" }}>New Password:</p>
                                <input
                                    style={{ marginLeft: "1.5em", width: "12em" }}
                                    type={showNewPassword ? "text" : "password"} // Toggle between "text" and "password"
                                    value={newPassword}
                                    onChange={(e) => setNewPassword(e.target.value)}
                                />
                                <button
                                    className="btn-record-action"
                                    style={{ fontSize: "0.7em", marginLeft: "0.5em" }}
                                    onClick={() => setShowNewPassword(!showNewPassword)} // Toggle state
                                >
                                    {showNewPassword ? "Hide" : "Show"}
                                </button>
                            </label>
                            <label>
                                <p style={{ fontWeight: "normal" }}>Confirm Password:</p>
                                <input
                                    style={{ width: "12em" }}
                                    type={showConfirmPassword ? "text" : "password"} // Toggle between "text" and "password"
                                    value={confirmPassword}
                                    onChange={(e) => setConfirmPassword(e.target.value)}
                                />
                                <button
                                    className="btn-record-action"
                                    style={{ fontSize: "0.7em", marginLeft: "0.5em" }}
                                    onClick={() => setShowConfirmPassword(!showConfirmPassword)} // Toggle state
                                >
                                    {showConfirmPassword ? "Hide" : "Show"}
                                </button>
                            </label>
                            {passwordError && <p style={{ color: "red" }}>{passwordError}</p>}
                        </div>
                    )}
                </div>
            </div>
        </>
    );
};

export default EditAccount;

