// Mask.js

// Load the mask key from .env
const maskKey = process.env.REACT_APP_MASK_KEY || "DefaultKeyForMasking"; // Use REACT_APP_ prefix for React environment variables

// Variable name mapping object (human-readable to obfuscated keys)
const VARIABLE_NAME_MAP = {
    session_token: "v01",
    username: "v02",
    email: "v03",
    nickname: "v04",
    firstname: "v05",
    lastname: "v06",
    my_timezone: "v07",
    country: "v08",
    country_code: "v09",
    phone_number: "v10",
    account_mfa: "v11",
    mfa_method: "v12",
    role: "v13",
    quota: "v14",
    membership: "v15",
    expiration_date: "v16",
    last_transaction_date: "v17",
    writing_limit: "v18",
    total_words: "v19",
    authentication_status: "v20",
    page_width: "v21",
    font_size: "v22",
    color_theme: "v23",
    ai_account: "v24",
    college_prompt: "v25",
    word_limit: "v26",
    key_idea: "v27",
    writing_idea: "v28",
    writing_framework: "v29",
    brainstorming_writing_words: "v30",
    brainstorming_words: "v31",
    brainstormingVersionIndex: "v32",
    brainstormingVersionMax: "v33",
    college_idea: "v34",
    limit_lower: "v35",
    draft_essay: "v36",
    writing_words: "v37",
    words: "v38",
    essayVersionIndex: "v39",
    essayVersionMax: "v40",
    essay_to_evaluate: "v41",
    evaluation_suggestion: "v42",
    evaluation_dimension: "v43",
    essay_suggestion: "v44",
    evaluation_report: "v45",
    evaluation_writing_words: "v46",
    evaluation_words: "v47",
    evaluationDimensionVersionIndex: "v48",
    evaluationDimensionVersionMax: "v49",
    evaluationReportVersionIndex: "v50",
    evaluationReportVersionMax: "v51",
    email_requirement: "v52",
    email_tone: "v53",
    draft_email: "v54",
    email_writing_words: "v55",
    email_words: "v56",
    emailVersionIndex: "v57",
    emailVersionMax: "v58",
};

// Helper to get obfuscated key for a variable name
const getObfuscatedKey = (name) => VARIABLE_NAME_MAP[name] || name;

// XOR function for masking/unmasking
const xorEncryptDecrypt = (input, key) => {
    const keyLength = key.length;
    return input
        .split("")
        .map((char, i) => String.fromCharCode(char.charCodeAt(0) ^ key.charCodeAt(i % keyLength)))
        .join("");
};

// UTF-8 Safe Base64 Encoding
const utf8ToBase64 = (str) => btoa(unescape(encodeURIComponent(str)));
const base64ToUtf8 = (base64) => decodeURIComponent(escape(atob(base64)));

// Helper to safely get values from sessionStorage, using mapped variable names and returning a string or the provided default value
export const getSessionStorageItem = (variableName, defaultValue = "") => {
    const obfuscatedKey = getObfuscatedKey(variableName); // Get obfuscated key
    const item = sessionStorage.getItem(obfuscatedKey); // Retrieve by obfuscated key
    if (item === null || item === undefined) return defaultValue;

    try {
        // Decode from Base64, unmask using XOR, and parse as JSON
        const xorResult = base64ToUtf8(item); // UTF-8 decoding from Base64
        const unmaskedValue = xorEncryptDecrypt(xorResult, maskKey); // XOR decryption
        return JSON.parse(unmaskedValue); // Parse the JSON string
    } catch (e) {
        console.error(`Error retrieving and unmasking ${variableName} from sessionStorage:`, e);
        return defaultValue; // Return default value on error
    }
};

// Function to update sessionStorage and state simultaneously with variable name mapping
export const updateSessionData = (variableName, value, setter) => {
    const obfuscatedKey = getObfuscatedKey(variableName); // Get obfuscated key

    try {
        if (value === undefined || value === null) value = ""; // Ensure controlled values
        const jsonValue = JSON.stringify(value); // Convert value to JSON string
        const xorResult = xorEncryptDecrypt(jsonValue, maskKey); // XOR encryption
        const maskedValue = utf8ToBase64(xorResult); // UTF-8 safe Base64 encoding
        sessionStorage.setItem(obfuscatedKey, maskedValue); // Save to sessionStorage
    } catch (e) {
        console.error(`Error masking and saving ${variableName} to sessionStorage:`, e);
    }

    setter(value); // Update state
};

// Function to handle sessionStorage.setItem with variable name mapping and masking
export const setSessionStorageItem = (variableName, value) => {
    const obfuscatedKey = getObfuscatedKey(variableName); // Get obfuscated key

    try {
        if (value === undefined || value === null) value = ""; // Ensure controlled values
        const jsonValue = JSON.stringify(value); // Convert value to JSON string
        const xorResult = xorEncryptDecrypt(jsonValue, maskKey); // XOR encryption
        const maskedValue = utf8ToBase64(xorResult); // UTF-8 safe Base64 encoding
        sessionStorage.setItem(obfuscatedKey, maskedValue); // Save to sessionStorage
    } catch (e) {
        console.error(`Error masking and saving ${variableName} to sessionStorage:`, e);
    }
};

// Function to handle sessionStorage.removeItem with variable name mapping
export const removeSessionStorageItem = (variableName) => {
    const obfuscatedKey = getObfuscatedKey(variableName); // Get obfuscated key

    try {
        sessionStorage.removeItem(obfuscatedKey); // Remove from sessionStorage
    } catch (e) {
        console.error(`Error removing ${variableName} from sessionStorage:`, e);
    }
};
