import React, { useState } from 'react';

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

const VerificationInterface = ({ unverifiedUsername, mfaRequired, mfaMethod, verificationMessage, phoneNumber, countryCode, email }) => {
    const [verificationCode, setVerificationCode] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const [isSpinnerRunning, setIsSpinnerRunning] = useState(false);

    const handleChange = (e) => {
        setVerificationCode(e.target.value);
    };

    const handleVerification = async (e) => {
        e.preventDefault();
        setIsSpinnerRunning(true);

        try {
            const response = await fetch(`${API_BASE_URL}/account_verification`, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({
                    username: unverifiedUsername,
                    email: email,
                    verification_code: verificationCode,
                }),
                credentials: 'include', // Ensure cookies are sent with the request
            });

            if (response.ok) {
                const data = await response.json();
                if (data.verification_success) {
                    setIsSpinnerRunning(false);
                    window.location.reload(); // Cookie found, reload the page
                } else{
                    setErrorMessage(data.error_message || 'Verification failed. Please try again.');
                    setIsSpinnerRunning(false);
                }
            } else {
                // Show error message
                setErrorMessage('Verification interrupted. Please try again.');
                setIsSpinnerRunning(false);
            }
        } catch (err) {
            if (process.env.NODE_ENV === 'development') {
                console.error(err);
            }
            setErrorMessage('Something went wrong. Please try again later.');
            setIsSpinnerRunning(false);
        }
    };

    const handleResendVerification = async () => {
        setIsSpinnerRunning(true);
        setErrorMessage('');

        try {
            const response = await fetch(`${API_BASE_URL}/account_verification_resend`, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({
                    username: unverifiedUsername,
                    phone_number: phoneNumber,
                    country_code: countryCode,
                    email: email,
                    mfa_method: mfaMethod
                }),
            });

            const data = await response.json();

            if (response.ok) {
                // Successfully resent
                setErrorMessage(data.message || 'Verification code resent. Check your email or phone.');
            } else {
                // Handle resend error
                setErrorMessage(data.message || 'Failed to resend the code.');
            }
        } catch (err) {
            if (process.env.NODE_ENV === 'development') {
                console.error(err);
            }
            setErrorMessage('Something went wrong. Please try again later.');
        } finally {
            setIsSpinnerRunning(false);
        }
    };

    return (
        <>
            {mfaRequired && (
                <div>
                    <p style={{ marginTop: "20px", marginBottom: "10px", color: 'orange' }}>Verification code required to verify the account.</p>
                    <form onSubmit={handleVerification}>
                        <input
                            type="text"
                            name="verification_code"
                            placeholder="Enter the verification code here"
                            required
                            value={verificationCode}
                            onChange={handleChange}
                            title={verificationMessage}
                            style={{
                                padding: '8px 3px',
                                width: '96%',
                                borderRadius: '4px',
                                border: '1px solid #ccc',
                                fontSize: '12px',
                                marginBottom: '10px',
                            }}
                        />
                        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                            {isSpinnerRunning ? (
                                <div className="simpleSpinner"></div> // Replace with your spinner component
                            ) : (
                                <button type="submit" className="btn-record-action">
                                    Verify
                                </button>
                            )}
                            <button
                                type="button"
                                className="btn-record-action"
                                onClick={handleResendVerification}
                            >
                                Resend
                            </button>
                        </div>
                    </form>
                    {errorMessage && <p style={{ color: 'red' }}>{errorMessage}</p>}
                </div>
            )}
        </>
    );
};

export default VerificationInterface;
