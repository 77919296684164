//frontend/src/Sidebar.js
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import AuthContainer from './components/Authentication';
import { jwtDecode } from "jwt-decode";
import logo from './assets/images/logo_256.png';
import { useAppState } from './components/AppContext';

const Sidebar = ({ isOpen, onTokenChange, onLinkClick }) => {
    const { updateToken } = useAppState();
    const [token, setToken] = useState(null);
    const [userInfo, setUserInfo] = useState(null);

    const handleTokenChange = (newToken) => {
        setToken(newToken);
        updateToken(newToken);
        if (newToken) {
            const decodedToken = jwtDecode(newToken);
            setUserInfo(decodedToken);
        } else {
            setUserInfo(null);
        }
    };

    return (
        <>
            <div className={`Sidebar ${isOpen ? 'open' : ''}`}>
                <div className="Sidebar-header">
                    <img src={logo} alt="SophoScriptor Logo" className="Sidebar-logo" />
                    <h2 className="Sidebar-title">SophoScriptor</h2>
                </div>
                <nav>
                    <Link className="Sidebar-link" to="/Homepage" onClick={onLinkClick}>
                        Welcome
                    </Link>
                    <Link className="Sidebar-link" to="/WritingAssistance" onClick={onLinkClick}>
                        Writing Assistance
                    </Link>
                    <Link className="Sidebar-link" to="/ProductsServices" onClick={onLinkClick}>
                        Products & Services
                    </Link>
                    <Link className="Sidebar-link" to="/HelpSupport" onClick={onLinkClick}>
                        Help & Support
                    </Link>
                    <Link className="Sidebar-link" to="/Account" onClick={onLinkClick}>
                        Account
                    </Link>
                </nav>
                <hr className="HorizontalRule" />
                <AuthContainer onTokenChange={handleTokenChange} />
            </div>
        </>
    );
};

export default Sidebar;



