const countriesAndCodes = [
    {index: 0, code: '+93', name: 'Afghanistan (+93)'},
    {index: 1, code: '+355', name: 'Albania (+355)'},
    {index: 2, code: '+213', name: 'Algeria (+213)'},
    {index: 3, code: '+685', name: 'American Samoa (+685)'},
    {index: 4, code: '+376', name: 'Andorra (+376)'},
    {index: 5, code: '+244', name: 'Angola (+244)'},
    {index: 6, code: '+1 264', name: 'Anguilla (+1 264)'},
    {index: 7, code: '+672', name: 'Australian Antarctic Territory (+672)'},
    {index: 8, code: '+1 268', name: 'Antigua and Barbuda (+1 268)'},
    {index: 9, code: '+599', name: 'Antilles (+599)'},
    {index: 10, code: '+54', name: 'Argentina (+54)'},
    {index: 11, code: '+374', name: 'Armenia (+374)'},
    {index: 12, code: '+297', name: 'Aruba (+297)'},
    {index: 13, code: '+247', name: 'Ascension Island (+247)'},
    {index: 14, code: '+61', name: 'Australia (+61)'},
    {index: 15, code: '+43', name: 'Austria (+43)'},
    {index: 16, code: '+994', name: 'Azerbaijan (+994)'},
    {index: 17, code: '+351', name: 'Azores (+351)'},
    {index: 18, code: '+1 242', name: 'Bahamas (+1 242)'},
    {index: 19, code: '+973', name: 'Bahrain (+973)'},
    {index: 20, code: '+880', name: 'Bangladesh (+880)'},
    {index: 21, code: '+1 246', name: 'Barbados (+1 246)'},
    {index: 22, code: '+1 268', name: 'Barbuda and Antigua (+1 268)'},
    {index: 23, code: '+375', name: 'Belarus (+375)'},
    {index: 24, code: '+32', name: 'Belgium (+32)'},
    {index: 25, code: '+501', name: 'Belize (+501)'},
    {index: 26, code: '+229', name: 'Benin (+229)'},
    {index: 27, code: '+1 441', name: 'Bermuda (+1 441)'},
    {index: 28, code: '+975', name: 'Bhutan (+975)'},
    {index: 29, code: '+591', name: 'Bolivia (+591)'},
    {index: 30, code: '+387', name: 'Bosnia Herzegovina (+387)'},
    {index: 31, code: '+267', name: 'Botswana (+267)'},
    {index: 32, code: '+55', name: 'Brazil (+55)'},
    {index: 33, code: '+1 284', name: 'British Virgin Islands (+1 284)'},
    {index: 34, code: '+673', name: 'Brunei Darussalam (+673)'},
    {index: 35, code: '+359', name: 'Bulgaria (+359)'},
    {index: 36, code: '+226', name: 'Burkina Faso (+226)'},
    {index: 37, code: '+95', name: 'Burma (Myanmar) (+95)'},
    {index: 38, code: '+257', name: 'Burundi (+257)'},
    {index: 39, code: '+855', name: 'Cambodia (+855)'},
    {index: 40, code: '+237', name: 'Cameroon (+237)'},
    {index: 41, code: '+1', name: 'Canada (+1)'},
    {index: 42, code: '+238', name: 'Cape Verde Islands (+238)'},
    {index: 43, code: '+1 345', name: 'Cayman Islands (+1 345)'},
    {index: 44, code: '+236', name: 'Central African Republic (+236)'},
    {index: 45, code: '+235', name: 'Chad (+235)'},
    {index: 46, code: '+56', name: 'Chile (+56)'},
    {index: 47, code: '+86', name: 'China (+86)'},
    {index: 48, code: '+672', name: 'Christmas Island (+672)'},
    {index: 49, code: '+672', name: 'Cocos Island (+672)'},
    {index: 50, code: '+57', name: 'Colombia (+57)'},
    {index: 51, code: '+269', name: 'Comoros (+269)'},
    {index: 52, code: '+242', name: 'Congo (+242)'},
    {index: 53, code: '+682', name: 'Cook Islands (+682)'},
    {index: 54, code: '+506', name: 'Costa Rica (+506)'},
    {index: 55, code: '+225', name: "Cote D'Ivoire (Ivory Coast) (+225)" },
    {index: 56, code: '+385', name: 'Croatia (+385)'},
    {index: 57, code: '+53', name: 'Cuba (+53)'},
    {index: 58, code: '+357', name: 'Cyprus (+357)'},
    {index: 59, code: '+42', name: 'Czech Republic (+42)'},
    {index: 60, code: '+45', name: 'Denmark (+45)'},
    {index: 61, code: '+253', name: 'Djibouti (+253)'},
    {index: 62, code: '+1 767', name: 'Dominica (+1 767)'},
    {index: 63, code: '+1 809', name: 'Dominican Republic (+1 809)'},
    {index: 64, code: '+593', name: 'Ecuador (+593)'},
    {index: 65, code: '+20', name: 'Egypt (+20)'},
    {index: 66, code: '+503', name: 'El Salvador (+503)'},
    {index: 67, code: '+240', name: 'Equatorial Guinea (+240)'},
    {index: 68, code: '+291', name: 'Eritrea (+291)'},
    {index: 69, code: '+372', name: 'Estonia (+372)'},
    {index: 70, code: '+251', name: 'Ethiopia (+251)'},
    {index: 71, code: '+500', name: 'Falkland Islands (+500)'},
    {index: 72, code: '+298', name: 'Faroe Islands (+298)'},
    {index: 73, code: '+679', name: 'Fiji (+679)'},
    {index: 74, code: '+358', name: 'Finland (+358)'},
    {index: 75, code: '+33', name: 'France (+33)'},
    {index: 76, code: '+594', name: 'French Guiana (+594)'},
    {index: 77, code: '+689', name: 'French Polynesia (+689)'},
    {index: 78, code: '+241', name: 'Gabon (+241)'},
    {index: 79, code: '+220', name: 'Gambia (+220)'},
    {index: 80, code: '+995', name: 'Georgia (+995)'},
    {index: 81, code: '+49', name: 'Germany (+49)'},
    {index: 82, code: '+233', name: 'Ghana (+233)'},
    {index: 83, code: '+350', name: 'Gibraltar (+350)'},
    {index: 84, code: '+30', name: 'Greece (+30)'},
    {index: 85, code: '+299', name: 'Greenland (+299)'},
    {index: 86, code: '+1 473', name: 'Grenada (+1 473)'},
    {index: 87, code: '+1 784', name: 'Grenadines (+1 784)'},
    {index: 88, code: '+590', name: 'Guadeloupe (+590)'},
    {index: 89, code: '+671', name: 'Guam (+671)'},
    {index: 90, code: '+502', name: 'Guatemala (+502)'},
    {index: 91, code: '+224', name: 'Guinea (+224)'},
    {index: 92, code: '+245', name: 'Guinea-Bissau (+245)'},
    {index: 93, code: '+592', name: 'Guyana (+592)'},
    {index: 94, code: '+509', name: 'Haiti (+509)'},
    {index: 95, code: '+504', name: 'Honduras (+504)'},
    {index: 96, code: '+852', name: 'Hong Kong (+852)'},
    {index: 97, code: '+36', name: 'Hungary (+36)'},
    {index: 98, code: '+354', name: 'Iceland (+354)'},
    {index: 99, code: '+91', name: 'India (+91)'},
    {index: 100, code: '+62', name: 'Indonesia (+62)'},
    {index: 101, code: '+98', name: 'Iran (+98)'},
    {index: 102, code: '+964', name: 'Iraq (+964)'},
    {index: 103, code: '+353', name: 'Ireland (+353)'},
    {index: 104, code: '+972', name: 'Israel (+972)'},
    {index: 105, code: '+39', name: 'Italy (+39)'},
    {index: 106, code: '+225', name: "Ivory Coast (Cote d'Ivorie) (+225)" },
    {index: 107, code: '+1 876', name: 'Jamaica (+1 876)'},
    {index: 108, code: '+81', name: 'Japan (+81)'},
    {index: 109, code: '+962', name: 'Jordan (+962)'},
    {index: 110, code: '+7', name: 'Kazakhstan (+7)'},
    {index: 111, code: '+254', name: 'Kenya (+254)'},
    {index: 112, code: '+7', name: 'Kirghizstan (+7)'},
    {index: 113, code: '+686', name: 'Kiribati (+686)'},
    {index: 114, code: '+381', name: 'Kosovo (+381)'},
    {index: 115, code: '+965', name: 'Kuwait (+965)'},
    {index: 116, code: '+856', name: 'Laos (+856)'},
    {index: 117, code: '+371', name: 'Latvia (+371)'},
    {index: 118, code: '+961', name: 'Lebanon (+961)'},
    {index: 119, code: '+266', name: 'Lesotho (+266)'},
    {index: 120, code: '+231', name: 'Liberia (+231)'},
    {index: 121, code: '+218', name: 'Libya (+218)'},
    {index: 122, code: '+423', name: 'Liechtenstein (+423)'},
    {index: 123, code: '+370', name: 'Lithuania (+370)'},
    {index: 124, code: '+352', name: 'Luxembourg (+352)'},
    {index: 125, code: '+853', name: 'Macao (+853)'},
    {index: 126, code: '+389', name: 'Macedonia (+389)'},
    {index: 127, code: '+261', name: 'Madagascar (+261)'},
    {index: 128, code: '+265', name: 'Malawi (+265)'},
    {index: 129, code: '+60', name: 'Malaysia (+60)'},
    {index: 130, code: '+960', name: 'Maldives (+960)'},
    {index: 131, code: '+223', name: 'Mali (+223)'},
    {index: 132, code: '+356', name: 'Malta (+356)'},
    {index: 133, code: '+692', name: 'Marshall Islands (+692)'},
    {index: 134, code: '+596', name: 'Martinique (+596)'},
    {index: 135, code: '+222', name: 'Mauritania (+222)'},
    {index: 136, code: '+230', name: 'Mauritius (+230)'},
    {index: 137, code: '+269', name: 'Mayotte (+269)'},
    {index: 138, code: '+52', name: 'Mexico (+52)'},
    {index: 139, code: '+691', name: 'Micronesia (+691)'},
    {index: 140, code: '+373', name: 'Moldova (+373)'},
    {index: 141, code: '+377', name: 'Monaco (+377)'},
    {index: 142, code: '+976', name: 'Mongolia (+976)'},
    {index: 143, code: '+382', name: 'Montenegro (+382)'},
    {index: 144, code: '+1 664', name: 'Montserrat (+1 664)'},
    {index: 145, code: '+212', name: 'Morocco (+212)'},
    {index: 146, code: '+258', name: 'Mozambique (+258)'},
    {index: 147, code: '+264', name: 'Namibia (+264)'},
    {index: 148, code: '+674', name: 'Nauru (+674)'},
    {index: 149, code: '+977', name: 'Napal (+977)'},
    {index: 150, code: '+31', name: 'Netherlands (Holland) (+31)'},
    {index: 151, code: '+599', name: 'Netherlands Antilles (+599)'},
    {index: 152, code: '+687', name: 'New Caledonia (+687)'},
    {index: 153, code: '+64', name: 'New Zealand (+64)'},
    {index: 154, code: '+505', name: 'Nicaragua (+505)'},
    {index: 155, code: '+227', name: 'Niger Republic (+227)'},
    {index: 156, code: '+234', name: 'Nigeria (+234)'},
    {index: 157, code: '+47', name: 'Norway (+47)'},
    {index: 158, code: '+850', name: 'North Korea (+850)'},
    {index: 159, code: '+968', name: 'Oman (+968)'},
    {index: 160, code: '+92', name: 'Pakistan (+92)'},
    {index: 161, code: '+507', name: 'Panama (+507)'},
    {index: 162, code: '+675', name: 'Papua New Guinea (+675)'},
    {index: 163, code: '+595', name: 'Paraguay (+595)'},
    {index: 164, code: '+51', name: 'Peru (+51)'},
    {index: 165, code: '+63', name: 'Philippines (+63)'},
    {index: 166, code: '+649', name: 'Pitcairn Island (+649)'},
    {index: 167, code: '+48', name: 'Poland (+48)'},
    {index: 168, code: '+351', name: 'Portugal (+351)'},
    {index: 169, code: '+1 787', name: 'Puerto Rico (+1 787)'},
    {index: 170, code: '+974', name: 'Qatar (+974)'},
    {index: 171, code: '+40', name: 'Romania (+40)'},
    {index: 172, code: '+7', name: 'Russia (+7)'},
    {index: 173, code: '+250', name: 'Rwanda (+250)'},
    {index: 174, code: '+290', name: 'St Helena (+290)'},
    {index: 175, code: '+1 869', name: 'St Kitts and Nevis (+1 869)'},
    {index: 176, code: '+1 758', name: 'St Lucia (+1 758)'},
    {index: 177, code: '+1 784', name: 'St Vincent (+1 784)'},
    {index: 178, code: '+378', name: 'San Marino (+378)'},
    {index: 179, code: '+966', name: 'Saudi Arabia (+966)'},
    {index: 180, code: '+221', name: 'Senegal (+221)'},
    {index: 181, code: '+381', name: 'Serbia (+381)'},
    {index: 182, code: '+248', name: 'Seychelles (+248)'},
    {index: 183, code: '+232', name: 'Sierra Leone (+232)'},
    {index: 184, code: '+65', name: 'Singapore (+65)'},
    {index: 185, code: '+421', name: 'Slovakia (+421)'},
    {index: 186, code: '+386', name: 'Slovenia (+386)'},
    {index: 187, code: '+677', name: 'Solomon Islands (+677)'},
    {index: 188, code: '+252', name: 'Somalia (+252)'},
    {index: 189, code: '+27', name: 'South Africa (+27)'},
    {index: 190, code: '+82', name: 'South Korea (+82)'},
    {index: 191, code: '+349', name: 'Spain (+349)'},
    {index: 192, code: '+94', name: 'Sri Lanka (+94)'},
    {index: 193, code: '+249', name: 'Sudan (+249)'},
    {index: 194, code: '+597', name: 'Surinam (+597)'},
    {index: 195, code: '+268', name: 'Swaziland (+268)'},
    {index: 196, code: '+46', name: 'Sweden (+46)'},
    {index: 197, code: '+41', name: 'Switzerland (+41)'},
    {index: 198, code: '+963', name: 'Syria (+963)'},
    {index: 199, code: '+886', name: 'Taiwan (+886)'},
    {index: 200, code: '+7', name: 'Tajikistan (+7)'},
    {index: 201, code: '+255', name: 'Tanzania (+255)'},
    {index: 202, code: '+66', name: 'Thailand (+66)'},
    {index: 203, code: '+228', name: 'Togo (+228)'},
    {index: 204, code: '+676', name: 'Tonga (+676)'},
    {index: 205, code: '+1 868', name: 'Trinidad & Tobago (+1 868)'},
    {index: 206, code: '+216', name: 'Tunisia (+216)'},
    {index: 207, code: '+90', name: 'Turkey (+90)'},
    {index: 208, code: '+7', name: 'Turkmenistan (+7)'},
    {index: 209, code: '+1 649', name: 'Turks & Caicos Islands (+1 649)'},
    {index: 210, code: '+688', name: 'Tuvalu (+688)'},
    {index: 211, code: '+256', name: 'Uganda (+256)'},
    {index: 212, code: '+380', name: 'Ukraine (+380)'},
    {index: 213, code: '+971', name: 'United Arab Emirates (+971)'},
    {index: 214, code: '+44', name: 'United Kingdom (+44)'},
    {index: 215, code: '+1', name: 'United States (+1)'},
    {index: 216, code: '+598', name: 'Uraguay (+598)'},
    {index: 217, code: '+998', name: 'Uzbekistan (+998)'},
    {index: 218, code: '+678', name: 'Vanuatu (+678)'},
    {index: 219, code: '+39', name: 'Vatican City (+39)'},
    {index: 220, code: '+58', name: 'Venezuela (+58)'},
    {index: 221, code: '+84', name: 'Vietnam (+84)'},
    {index: 222, code: '+1 340', name: 'Virgin Islands (US) (+1 340)'},
    {index: 223, code: '+685', name: 'Western Samoa (+685)'},
    {index: 224, code: '+967', name: 'Yemen (+967)'},
    {index: 225, code: '+243', name: 'Zaire (Congo) (+243)'},
    {index: 226, code: '+260', name: 'Zambia (+260)'},
    {index: 227, code: '+263', name: 'Zimbabwe (+263)'},
];

export default countriesAndCodes;