import React, { useState, useEffect, useRef } from "react";
import axios from 'axios';
import UsageSuggestionsConstant from '../../constants/UsageSuggestionsConstant';


function UsageSuggestions() {

    return (
        <>
            <h1>Usage Suggestions</h1>

            {UsageSuggestionsConstant}
        </>
    );
}

export default UsageSuggestions;