// src/App.js
import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import { SettingsProvider } from './components/SettingsContext';
import Sidebar from './Sidebar';  // Sidebar component for navigation
import HamburgerMenu from './HamburgerMenu';
import './App.css';              // Keep existing styles
import logo from './assets/images/logo_128.png';
import Home from './pages/Homepage';
import WritingAssistance from './pages/WritingAssistance';
import ProductsServices from './pages/ProductsServices';
import HelpSupport from './pages/HelpSupport';
import Account from './pages/Account';
import { setSessionStorageItem, removeSessionStorageItem } from "./components/Mask";

function App() {
    const [isSidebarOpen, setSidebarOpen] = useState(false);
    const [isHamburgerVisible, setHamburgerVisible] = useState(false);
    const [token, setToken] = useState(null);

    // Check for screen size to determine Hamburger visibility
    useEffect(() => {
        const handleResize = () => {
            setHamburgerVisible(window.innerWidth <= 768); // Adjust for small screens (<= 768px)
        };

        handleResize(); // Check initial screen size
        window.addEventListener('resize', handleResize); // Update on window resize

        return () => {
            window.removeEventListener('resize', handleResize); // Cleanup event listener
        };
    }, []);

    // Toggle the sidebar visibility
    const toggleSidebar = () => {
        setSidebarOpen(!isSidebarOpen);
    };

    const handleTokenChange = (newToken) => {
        setToken(newToken);
        if (newToken) {
            setSessionStorageItem('token', newToken);
        } else {
            removeSessionStorageItem('token');
        }
    };

    const handleLinkClick = () => {
        setSidebarOpen(false); // Close the sidebar in the parent component
    };

    return (
        <SettingsProvider>
            <Router>
                <div className="App">
                    {/* Hamburger Menu for small screens */}
                    {isHamburgerVisible && <HamburgerMenu toggleSidebar={toggleSidebar} />}

                    {/* Sidebar with toggle control */}
                    <Sidebar isOpen={isSidebarOpen} onTokenChange={handleTokenChange} onLinkClick={handleLinkClick} />

                    {/* Main Content Area */}
                    <div className="MainContent">
                        {/* Page Tabs */}
                        <div className="Page-header">
                            <div className="Page-tabs">
                                {isHamburgerVisible && (
                                    <div className="Page-tabs-header">
                                        <img src={logo} alt="SophoScriptor Logo" className="Page-tabs-logo" />
                                        <span className="Page-tabs-title">SophoScriptor</span>
                                    </div>
                                )}
                                {/* Tabs content goes here */}
                            </div>
                        </div>


                        <Routes>
                            <Route path="/" element={<Navigate to="/Homepage" />} />
                            <Route path="/Homepage" element={<Home />} />
                            <Route path="/WritingAssistance/*" element={<WritingAssistance />} />
                            <Route path="/ProductsServices/*" element={<ProductsServices />} />
                            <Route path="/HelpSupport/*" element={<HelpSupport />} />
                            <Route path="/Account/*" element={<Account onTokenChange={handleTokenChange} />} />
                            {/* Catch-all route for invalid URLs */}
                            <Route path="*" element={<Navigate to="/Homepage" />} />
                        </Routes>
                    </div>
                </div>
            </Router>
        </SettingsProvider>
    );
}

export default App;
