// Utility function to perform weighted random selection
import { getSessionStorageItem, setSessionStorageItem } from "./Mask";


function getWeightedRandomChoice(choices, weights) {
    let sumOfWeights = weights.reduce((a, b) => a + b, 0);
    let randomValue = Math.random() * sumOfWeights;
    let cumulativeWeight = 0;

    for (let i = 0; i < choices.length; i++) {
        cumulativeWeight += weights[i];
        if (randomValue < cumulativeWeight) {
            return choices[i];
        }
    }
}

// Function to retrieve or calculate ai_account
function getAIAccount() {
    // Check if ai_account exists in sessionStorage
    let aiAccount = getSessionStorageItem('ai_account');

    if (!aiAccount) {
        // Define your choices and weights
        const aiChoices = ['SophoScriptor', 'Old'];
        const weights = [2, 1];

        // Calculate ai_account using the utility function
        aiAccount = getWeightedRandomChoice(aiChoices, weights);

        // Store the calculated value in sessionStorage
        setSessionStorageItem('ai_account', aiAccount);
    }

    return aiAccount;
}

export default getAIAccount;
