import React, { useState, useEffect, useRef } from "react";
import axios from 'axios';
import PrivacyPolicyConstant from '../../constants/PrivacyPolicyConstant';


function UserInstructions() {

    return (
        <>
            <h1>Privacy Policy</h1>

            {PrivacyPolicyConstant}
        </>
    );
}

export default UserInstructions;