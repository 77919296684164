import React, { useState, useEffect, useRef } from "react";
import axios from 'axios';
import getAIAccount from '../../components/GetAIAccount';
import GreetingMessage from '../../components/greeting';
import {
    getSessionStorageItem,
    updateSessionData,
    setSessionStorageItem,
    removeSessionStorageItem,
} from "../../components/Mask";

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

const aiAccount = getAIAccount();

function Brainstorming() {
    const [username, setUsername] = useState(getSessionStorageItem("username"));
    const [expirationDate, setExpirationDate] = useState(getSessionStorageItem("expiration_date"));
    const [writingLimit, setWritingLimit] = useState(getSessionStorageItem("writing_limit", 0));
    const [totalWords, setTotalWords] = useState(getSessionStorageItem("total_words", 0));
    const currentDate = new Date();
    const shortDate = expirationDate.substring(0, 10);
    const expiration = new Date(shortDate);
    const numericWritingLimit = Number(writingLimit);
    const numericTotalWords = Number(totalWords);

    const [collegePrompt, setCollegePrompt] = useState(getSessionStorageItem("college_prompt"));
    const [wordLimit, setWordLimit] = useState(getSessionStorageItem("word_limit", "300"));
    const [keyIdea, setKeyIdea] = useState(getSessionStorageItem("key_idea"));
    const [writingIdea, setWritingIdea] = useState(getSessionStorageItem("writing_idea"));
    const [writingFramework, setWritingFramework] = useState(getSessionStorageItem("writing_framework"));
    const [brainstormingWritingWords, setBrainstormingWritingWords] = useState(getSessionStorageItem("brainstorming_writing_words"));
    const [brainstormingWords, setBrainstormingWords] = useState(getSessionStorageItem("brainstorming_words"));
    const [originalWritingIdea, setOriginalWritingIdea] = useState("");
    const [originalWritingFramework, setOriginalWritingFramework] = useState("");

    const [brainstormingVersionIndex, setBrainstormingVersionIndex] = useState(null);
    const [brainstormingVersionMax, setBrainstormingVersionMax] = useState(null);
    const [brainstormingLoading, setBrainstormingLoading] = useState(false);
    const [brainstormingErrorMessage, setBrainstormingErrorMessage] = useState("");

    const [noVersionMessage, setNoVersionMessage] = useState("");

    // Reference to store the current AbortController
    const brainstormingAbortController = useRef(null);

    // Function to calculate new height based on content of both Writing Idea and Writing Framework
    function newHeight(text1, text2) {
        const lineHeight = 20; // Line height in pixels
        const minHeight = 200; // Minimum height in pixels
        const maxHeight = 800; // Maximum height in pixels

        // Calculate line breaks for both text areas
        const linebreaks1 = (text1.match(/\n/g) || []).length;
        const linebreaks2 = (text2.match(/\n/g) || []).length;

        // Calculate heights based on the line count for both
        const height1 = minHeight + (linebreaks1 * lineHeight);
        const height2 = minHeight + (linebreaks2 * lineHeight);

        // Use the larger height, but cap it at maxHeight
        const height = Math.min(Math.max(height1, height2), maxHeight);

        return height + 'px';
    }

    // Sync brainstormingVersionIndex and brainstormingVersionMax with sessionStorage whenever they change
    useEffect(() => {
        if (brainstormingVersionIndex !== null) {
            setSessionStorageItem("brainstormingVersionIndex", brainstormingVersionIndex);
        }
        if (brainstormingVersionMax !== null) {
            setSessionStorageItem("brainstormingVersionMax", brainstormingVersionMax);
        }
    }, [brainstormingVersionIndex, brainstormingVersionMax]);

    // Load a specific brainstorming version from the backend, or latest if brainstormingVersionIndex is null
    const loadVersion = async (version = null) => {
        try {
            setNoVersionMessage(""); // clear any previous message
            setBrainstormingErrorMessage("");
            const params = version !== null && version !== undefined ? { version_number: version } : {};

            const response = await axios.get(`${API_BASE_URL}/get_brainstorming_session_data`,
            {withCredentials: true, // Include cookies with the request
            params });

            const versionData = response.data;
            if (!versionData || versionData.brainstorming_version === null || versionData.brainstorming_version === "") {
                // Set message if no version data is available
                setNoVersionMessage("No brainstorming data found. Start a new brainstorming process.");
            } else {
                updateSessionData("college_prompt", versionData.college_prompt, setCollegePrompt);
                updateSessionData("word_limit", versionData.word_limit, setWordLimit);
                updateSessionData("key_idea", versionData.key_idea, setKeyIdea);
                updateSessionData("writing_idea", versionData.writing_idea, setWritingIdea);
                updateSessionData("writing_framework", versionData.writing_framework, setWritingFramework);
                updateSessionData("brainstorming_writing_words", versionData.writing_words, setBrainstormingWritingWords);
                updateSessionData("brainstorming_words", versionData.words, setBrainstormingWords);

                // Set version indices based on condition
                if (brainstormingVersionIndex === null) {
                    updateSessionData("brainstormingVersionIndex", versionData.brainstorming_version, setBrainstormingVersionIndex);
                    updateSessionData("brainstormingVersionMax", versionData.brainstorming_version, setBrainstormingVersionMax);
                } else {
                    updateSessionData("brainstormingVersionIndex", versionData.brainstorming_version, setBrainstormingVersionIndex);
                }
            }
        } catch (error) {
            if (error.name === 'AbortError') {
                if (process.env.NODE_ENV === 'development') {
                    console.log("Brainstorm request was aborted.");
                }
                setNoVersionMessage("Loading interrupted. Please try again.")
            } else if (error.response && error.response.status === 401) {
                // Handle authentication error
                setNoVersionMessage("You need a valid account for this service.");
            } else {
                if (process.env.NODE_ENV === 'development') {
                    console.error("Essay failed:", error);
                }
                setNoVersionMessage("Error loading brainstorming data. Please try again.")
            }
        }
    };

    // Version navigation handlers
    const handlePreviousVersion = () => {
    if (brainstormingVersionIndex > 1 && (brainstormingVersionIndex > brainstormingVersionMax - 5)) {
        const newBrainstormingIndex = brainstormingVersionIndex - 1;
        updateSessionData("brainstormingVersionIndex", newBrainstormingIndex, setBrainstormingVersionIndex);
        loadVersion(newBrainstormingIndex);
    }
    };

    const handleNextVersion = () => {
    if (brainstormingVersionIndex < brainstormingVersionMax) {
        const newBrainstormingIndex = brainstormingVersionIndex + 1;
        setBrainstormingVersionIndex(newBrainstormingIndex);
        loadVersion(newBrainstormingIndex);
    }
    };

    // Remove this useEffect that runs on component mount
    // useEffect(() => {
    //    loadVersion(undefined); // Avoid sending null or empty string
    // }, []);

    const [initialHeight, setInitialHeight] = useState('200px');
    const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth < 768);
    const ideaRef = useRef(null);
    const frameworkRef = useRef(null);

    // Adjust initial height based on content of both text areas
    useEffect(() => {
        if (ideaRef.current && frameworkRef.current && (writingIdea || writingFramework)) {
            const height = newHeight(writingIdea || "", writingFramework || "");
            ideaRef.current.style.height = height;
            frameworkRef.current.style.height = height;
            setInitialHeight(height); // Set initial height for dynamic resizing
        }
    }, [writingIdea, writingFramework]);

    // Adjust initial height based on content and screen size
    useEffect(() => {
        const handleResize = () => {
            setIsSmallScreen(window.innerWidth < 768);

            if (ideaRef.current && frameworkRef.current) {
                if (window.innerWidth < 768) {
                    // Independent height for small screens
                    ideaRef.current.style.height = newHeight(writingIdea || "", writingIdea || "");
                    frameworkRef.current.style.height = newHeight(writingFramework || "", writingFramework || "");
                } else {
                    // Shared height for larger screens
                    const height = newHeight(writingIdea || "", writingFramework || "");
                    ideaRef.current.style.height = height;
                    frameworkRef.current.style.height = height;
                    setInitialHeight(height); // Set initial height for dynamic resizing
                }
            }
        };

        // Set initial height on load
        handleResize();

        // Listen for resize events
        window.addEventListener("resize", handleResize);

        // Cleanup the event listener on component unmount
        return () => window.removeEventListener("resize", handleResize);
    }, [writingIdea, writingFramework]);

    // Brainstorming button handler
    const handleBrainstorm = async () => {
        setBrainstormingErrorMessage("");
        setNoVersionMessage("");

        // Validate input fields
        if (!collegePrompt) {
            setBrainstormingErrorMessage(
                "Please provide the writing prompt."
            );
            return;
        }
        if (!wordLimit) {
            setBrainstormingErrorMessage(
                "Please provide the word limit."
            );
            return;
        }
        if (!keyIdea) {
            setBrainstormingErrorMessage(
                "Please provide your initial idea for brainstorming."
            );
            return;
        }

        if (process.env.NODE_ENV === 'development') {
            console.log("Payload:", {
                college_prompt: collegePrompt,
                word_limit: wordLimit,
                key_idea: keyIdea,
                writing_idea: writingIdea,
                writing_framework: writingFramework,
            });
        }
        setBrainstormingLoading(true); // Start brainstormingLoading spinner

        // Initialize a new AbortController for the current request
        brainstormingAbortController.current = new AbortController();
        const { signal } = brainstormingAbortController.current;

        try {
            const response = await axios.post(`${API_BASE_URL}/brainstorming`, {
                ai_account: aiAccount || "",
                college_prompt: collegePrompt || "",
                word_limit: wordLimit || "300",
                key_idea: keyIdea || "",
                writing_idea: writingIdea || "",
                writing_framework: writingFramework || "",
            }, {withCredentials: true});

            updateSessionData("writing_idea", response.data.writing_idea, setWritingIdea);
            updateSessionData("writing_framework", response.data.writing_framework, setWritingFramework);
            updateSessionData('brainstorming_writing_words', response.data.writing_words, setBrainstormingWritingWords)
            updateSessionData('brainstorming_words', response.data.words, setBrainstormingWords)
            updateSessionData("brainstormingVersionIndex", Number(brainstormingVersionMax)+1, setBrainstormingVersionIndex)
            updateSessionData("brainstormingVersionMax", Number(brainstormingVersionMax)+1, setBrainstormingVersionMax)

            setOriginalWritingIdea(response.data.writing_idea);
            setOriginalWritingFramework(response.data.writing_framework);
        } catch (error) {
            if (error.name === 'AbortError') {
                if (process.env.NODE_ENV === 'development') {
                    console.log("Brainstorm request was aborted.");
                }
                setBrainstormingErrorMessage("An error occurred while brainstorming. Please try again.")
            } else if (error.response && error.response.status === 401) {
                // Handle authentication error
                setBrainstormingErrorMessage("You need a valid account for this service.");
            } else {
                if (process.env.NODE_ENV === 'development') {
                    console.error("Essay failed:", error);
                }
                setBrainstormingErrorMessage("Error brainstorming. Please try again.")
            }
        } finally {
            setBrainstormingLoading(false);
        }
    };

    // Helper function to detect if the user has edited the input
    const isEdited = (original, current) => original !== current;

    const handleWordLimitChange = (e) => {
        let value = e.target.value;
        setWordLimit(value);
    };

    // Add validation on blur (when the input loses focus)
    const validateWordLimit = () => {
        let value = parseInt(wordLimit);

        // Validate and adjust the value only if it's a number
        if (!isNaN(value)) {
            if (value < 20) {
                value = 20; // Set to minimum if below 20
            } else if (value > 1000) {
                value = 1000; // Set to maximum if above 1000
            }
            updateSessionData("word_limit", value, setWordLimit);
        }
    }

    // Restart/Stop button handler
    const handleRestartOrStop = () => {
        if (brainstormingLoading) {
            // Abort the current brainstorming request if it exists
            if (brainstormingAbortController.current) {
                brainstormingAbortController.current.abort();
                brainstormingAbortController.current = null; // Reset after aborting
            }

            // Clear any loading states
            setBrainstormingLoading(false);
        } else {
            // Reset writing fields if not brainstorming
            setWritingIdea("");
            setWritingFramework("");
            removeSessionStorageItem("writing_idea");
            removeSessionStorageItem("writing_framework");
            setBrainstormingErrorMessage("");
            setNoVersionMessage("");
        }
    };

    // for page presentation
    const [isExpanded, setIsExpanded] = useState(false);

    const togglePanel = () => {
    setIsExpanded(!isExpanded);
    };

    return (
        <>
            <h1>Brainstorming</h1>
            {(!username || numericWritingLimit<numericTotalWords || expiration<currentDate) && <GreetingMessage />}
            <div className="accordion" style={{marginTop: "20px"}}>
                <div onClick={togglePanel} className="accordion-header">
                    <span>{isExpanded ? 'Importance of Brainstorming: ' : 'Importance of Brainstorming: '}</span>
                </div>
                {isExpanded && (
                    <div className="accordion-content">
                        <p>
                            Brainstorming is crucial for crafting standout college application essays.
                            It sparks creativity and helps students uncover their most compelling stories
                            and personal strengths. By exploring various ideas and reflecting on their
                            experiences, students can create a unique narrative that not only aligns with
                            the essay prompt but also captivates admissions officers with authenticity and depth.
                        </p>
                    </div>
                )}
            </div>

            <div className="textarea-container">
                <div style={{ flex: 1 }}>
                    <h3 style={{ marginTop: "0px", marginBottom: "5px"}}>Writing Prompt</h3>
                    <textarea
                        value={collegePrompt}
                        title="Copy and paste the writing prompt from your college application here."
                        onChange={(e) => updateSessionData("college_prompt", e.target.value, setCollegePrompt)}
                        style={{
                            width: "100%",
                            height: "80px",
                            padding: "10px",
                            boxSizing: "border-box",
                            overflowWrap: "break-word", // Ensures text wraps within the box
                            resize: "none",             // Prevents resizing if undesired
                            verticalAlign: "top",        // Ensures text starts at the top
                            marginTop: '0px',
                            marginBottom: '25px',
                            fontSize: '0.8em',
                        }}
                    />
                </div>
                <div style={{ width: '6.5em', }}>
                    <h3 style={{ marginTop: "0px", marginBottom: "5px"}}>Word Limit</h3>
                    <input
                        type="number"
                        min="20"
                        max="1000"
                        value={wordLimit}
                        onChange={handleWordLimitChange}
                        onBlur={validateWordLimit} // Triggers validation when input loses focus
                        title="Enter an integer between 20 and 1000"
                        style={{ width: "6.5em", fontSize: '0.8em', textAlign: "center", height: "20px", marginTop: "0px", marginBottom: '25px' }} // Adjust width as needed
                    />
                </div>
            </div>

            <div className="textarea-container">
                <div className="textarea-item A">
                    <h3 style={{ marginTop: "0px", marginBottom: "5px" }}>Initial Idea</h3>
                    <textarea
                        value={keyIdea}
                        onChange={(e) => updateSessionData("key_idea", e.target.value, setKeyIdea)}
                        style={{
                            width: "100%",
                            height: "50px",
                            padding: "10px",
                            boxSizing: "border-box",
                            overflowWrap: "break-word", // Ensures text wraps within the box
                            resize: "none",             // Prevents resizing if undesired
                            verticalAlign: "top",
                            marginLeft: "0px",
                            marginBottom: '25px',
                            marginTop: '0px',
                            fontSize: '0.8em',
                        }}
                    />
                </div>
            </div>

            <div className="textarea-container">
                <div className="textarea-item A" style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "100%" }}>
                    <div style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", gap: "20px" }}>
                        {brainstormingLoading ? ( // Show spinner when brainstormingLoading
                            <div className="spinner brainstorming">
                                <div className="idea idea1"></div>
                                <div className="idea idea2"></div>
                                <div className="idea idea3"></div>
                                <div className="idea idea4"></div>
                                <div className="idea idea5"></div>
                                <div className="idea idea6"></div>
                            </div>
                        ) : (
                            <button
                                className="btn-broad-action"
                                onClick={handleBrainstorm}
                                style={{ backgroundColor: "#282c34", color: "#ffffff", border: "none" }}
                            >
                                <span className="highlighted-text">Brainstorming</span>
                            </button>
                        )}
                        {brainstormingErrorMessage && (
                            <p style={{ textAlign: 'center', marginTop: '-25px', marginBottom: '0px', color: 'red' }}>
                                {brainstormingErrorMessage}
                            </p>
                        )}
                    </div>
                </div>
                <div className="textarea-item A" style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "100%" }}>
                    <div style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", marginLeft: "20px", gap: "20px" }}>
                        <button
                            onClick={() => loadVersion()} // Call without arguments for the latest version
                            className="btn-broad-action"
                            title="Click to load the latest brainstorming data from the database."
                        >
                            Load the Last Brainstorming
                        </button>
                        {noVersionMessage && ( // Conditionally render the message
                            <div style={{ textAlign: 'center', marginTop: '-15px', marginBottom: '0px', color: 'red' }}>
                                {noVersionMessage}
                            </div>
                        )}
                    </div>
                </div>
                <div className="textarea-item A" style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "100%" }}>
                    <div style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", gap: "20px" }}>
                        <button
                            onClick={handleRestartOrStop}
                            className="btn-broad-action"
                            style={{ marginBottom: "10px", marginTop: "10px", }}
                        >
                            {brainstormingLoading ? "Stop" : "Restart"}
                        </button>
                    </div>
                </div>
            </div>

            <div className="textarea-container">
                <div className="textarea-item A">
                    <h3 style={{ marginTop: "25px", marginBottom: "5px"}}>Generated Writing Idea:</h3>
                    <textarea
                        ref={ideaRef}
                        style={{
                            width: "100%",
                            height: initialHeight,
                            padding: "10px",
                            boxSizing: "border-box",
                            overflowY: "auto",
                            overflowWrap: "break-word",
                            resize: "none",
                            marginTop: '0px',
                            fontSize: '0.8em',
                            backgroundColor: isEdited(originalWritingIdea, writingIdea) ? "#fff5e6" : "white"
                        }}
                        value={writingIdea || ""}
                        onChange={(e) => {
                            const text = e.target.value;
                            setWritingIdea(text);
                            updateSessionData("writing_idea", text, setWritingIdea);

                            // Adjust height based on screen size
                            const newHeightValue = isSmallScreen ? newHeight(text, text) : newHeight(text, writingFramework);
                            ideaRef.current.style.height = newHeightValue;
                            if (!isSmallScreen) frameworkRef.current.style.height = newHeightValue;
                        }}
                        disabled={brainstormingLoading}
                    />
                </div>
                <div className="textarea-item B">
                    <h3 style={{ marginTop: "25px", marginBottom: "5px"}}>Generated Writing Framework:</h3>
                    <textarea
                        ref={frameworkRef}
                        style={{
                            width: "100%",
                            height: initialHeight,
                            padding: "10px",
                            boxSizing: "border-box",
                            overflowY: "auto",
                            overflowWrap: "break-word",
                            resize: "none",
                            marginTop: '0px',
                            fontSize: '0.8em',
                            backgroundColor: isEdited(originalWritingFramework, writingFramework) ? "#fff5e6" : "white"
                        }}
                        value={writingFramework || ""}
                        onChange={(e) => {
                            const text = e.target.value;
                            setWritingFramework(text);
                            updateSessionData("writing_framework", text, setWritingFramework);

                            // Adjust height based on screen size
                            const newHeightValue = isSmallScreen ? newHeight(text, text) : newHeight(writingIdea, text);
                            frameworkRef.current.style.height = newHeightValue;
                            if (!isSmallScreen) ideaRef.current.style.height = newHeightValue;
                        }}
                        disabled={brainstormingLoading}
                    />
                </div>
            </div>

            {writingFramework && brainstormingWritingWords && brainstormingWords && (
                <div className="textarea-container">
                    <p style={{marginTop: '5px', marginBottom: '0px'}}>{brainstormingWritingWords} words in the draft essay. {brainstormingWords} words counted for usage. </p>
                </div>
            )}

            <div className="textarea-container">
                <div className="textarea-item A">
                    <div style={{ display: 'flex', justifyContent: 'center', marginTop: '20px', marginBottom: "20px" }}>
                        <button
                            onClick={handlePreviousVersion}
                            className="btn-record-action"
                            disabled={brainstormingVersionIndex === 1 || brainstormingVersionIndex <= brainstormingVersionMax - 4}
                            style={{ cursor: (brainstormingVersionIndex === 1 || brainstormingVersionIndex <= brainstormingVersionMax - 4) ? "not-allowed" : "pointer" }}
                        >Previous Version</button>
                    </div>
                </div>
                <div className="textarea-item B">
                    <div style={{ display: 'flex', justifyContent: 'center', marginTop: '20px', marginBottom: "20px" }}>
                        <button
                            onClick={handleNextVersion}
                            className="btn-record-action"
                            disabled={brainstormingVersionIndex >= brainstormingVersionMax}
                            style={{ cursor: brainstormingVersionIndex >= brainstormingVersionMax ? "not-allowed" : "pointer" }}
                        >Next Version</button>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Brainstorming;
