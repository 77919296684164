//frontend/src/components/AppContext.js
import React, { createContext, useReducer, useContext, useEffect } from 'react';
import {jwtDecode} from 'jwt-decode';
import {
    getSessionStorageItem,
    updateSessionData,
    setSessionStorageItem,
    removeSessionStorageItem,
} from "./Mask";

// Initial state with a safe decode check
const initialState = {
  token: getSessionStorageItem("session_token"),
  userInfo: (() => {
    try {
      return getSessionStorageItem("session_token")
        ? jwtDecode(getSessionStorageItem("session_token"))
        : null;
    } catch (error) {
      if (process.env.NODE_ENV === 'development') {
        console.error("Failed to decode token:", error);
      }
      return null;
    }
  })(),
  isAuthenticated: !!getSessionStorageItem("session_token"),
};

// Reducer function to manage state updates
const reducer = (state, action) => {
  switch (action.type) {
    case 'SET_AUTH':
      return {
        ...state,
        token: action.payload.token,
        userInfo: action.payload.userInfo,
        isAuthenticated: true,
      };
    case 'LOGOUT':
      return { ...initialState, isAuthenticated: false };
    default:
      return state;
  }
};

// Create Context
const AppContext = createContext();

  // Provide Context to the App
  export const AppProvider = ({ children }) => {
    const [state, dispatch] = useReducer(reducer, initialState);

    const updateToken = (token) => {
      if (token) {
        const decodedToken = jwtDecode(token);
        dispatch({ type: 'SET_AUTH', payload: { token, userInfo: decodedToken } });
      } else {
        dispatch({ type: 'LOGOUT' });
      }
    };

  return <AppContext.Provider value={{ state, dispatch, updateToken }}>{children}</AppContext.Provider>;
};

// Custom hook to use the context
export const useAppState = () => useContext(AppContext);
