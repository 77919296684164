import React from "react";

const EssayList = ({ essays, onEdit }) => {
  const groupedEssays = essays.reduce((acc, essay) => {
    acc[essay.folder] = acc[essay.folder] || [];
    acc[essay.folder].push(essay);
    return acc;
  }, {});

  return (
    <div className="essay-list">
      {Object.entries(groupedEssays).map(([folder, folderEssays]) => (
        <div key={folder} className="folder">
          <h3>{folder}</h3>
          <ul>
            {folderEssays.map((essay) => (
              <li key={essay._id} className="essay-item">
                {/* Display folder, tag (title), and comments */}
                <div>
                  <strong>{essay.tag}</strong>: {essay.comment}
                </div>
                <div className="actions">
                  <button
                    className="btn-record-managing"
                    style={{ marginTop: "10px" }}
                    onClick={() => {
                      if (process.env.NODE_ENV === 'development') {
                        console.log("Essay passed to onEdit:", essay);
                      }
                      onEdit(essay);
                    }}
                    title="Click to edit the essay or delete it from your account."
                  >
                    Edit
                  </button>
                </div>
              </li>
            ))}
          </ul>
        </div>
      ))}
    </div>
  );
};

export default EssayList;
