import React, { useState, useEffect } from 'react';
import SignUpForm from './SignUpForm';
import LoginForm from './LoginForm';
import { jwtDecode } from 'jwt-decode';
import styled from 'styled-components';
import {
    getSessionStorageItem,
    setSessionStorageItem,
    removeSessionStorageItem,
} from "./Mask";

const Phrase = styled.div`
  text-align: center;
  margin: 10px 0;
  font-size: 14px;
`;

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

const LogoutButton = styled.button.attrs({
  title: `If clicked, you will log out and clean up all data of this session. It is recommended for protecting your personal data.
  However, the next time you log in, you will not have access to data from this session.
  If you own this device and simply close the window without logging out,
  the next time you log in, you can access the data of this session by loading the last brainstorming or the last writing.`
})`
  margin-top: 10px; /* Add some space above the button */
  background-color: #e74c3c; /* Red color for logout */
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  padding: 6px 12px;
`;

const AuthenticationContainer = styled.div`
  margin-top: -10px; /* Adds space above the login form */
`;

async function checkAuth() {
    try {
        const response = await fetch(`${API_BASE_URL}/check-auth`, {
            method: "GET",
            credentials: "include"  // Include cookies in the request
        });
        if (process.env.NODE_ENV === 'development') {
            console.log('api_prefix: ', API_BASE_URL)
        }
        const data = await response.json();
        if (data.authenticated) {
            if (process.env.NODE_ENV === 'development') {
                console.log("User is authenticated:", data.session_token);
            }
            const tokenString = JSON.stringify(data.session_token);
            setSessionStorageItem("session_token", tokenString);
            return tokenString;  // Return the access token if authenticated
        } else {
            if (process.env.NODE_ENV === 'development') {
                console.log("User is not authenticated, please log in.");
            }
            return null;
        }
    } catch (error) {
        if (process.env.NODE_ENV === 'development') {
            console.error("Error checking auth status:", error);
        }
        return null;
    }
}

function AuthContainer({ onTokenChange }) {
    const [token, setToken] = useState(null);
    const [userInfo, setUserInfo] = useState(null);
    const [isLogin, setIsLogin] = useState(true);

    // Function to check authentication and update token
    const initializeAuth = async () => {
        let savedToken = getSessionStorageItem("session_token");
        let tokenToUse = savedToken;

        if (savedToken) {
            const decodedToken = jwtDecode(savedToken);
            const currentTime = Date.now() / 1000;

            if (decodedToken.exp < currentTime + 120) {
                tokenToUse = await checkAuth();
            }
        } else {
            tokenToUse = await checkAuth();
        }

        if (tokenToUse && typeof tokenToUse === "string") {
            const decodedToken = jwtDecode(tokenToUse);
            setToken(tokenToUse);
            setUserInfo(decodedToken);
            setSessionStorageItem("session_token", tokenToUse);
            onTokenChange(tokenToUse);  // Only call this once to avoid excessive renders
        }
    }

    useEffect(() => {
        initializeAuth();

        // Set an interval to refresh token if close to expiration
        const interval = setInterval(async () => {
            const savedToken = getSessionStorageItem("session_token");
            if (savedToken) {
                const decodedToken = jwtDecode(savedToken);
                const currentTime = Date.now() / 1000;

                // Refresh token if it's close to expiring
                if (decodedToken.exp < currentTime + 120) {
                    const refreshedToken = await checkAuth();
                    if (refreshedToken) {
                        setToken(refreshedToken);
                        setUserInfo(jwtDecode(refreshedToken));
                        setSessionStorageItem("session_token", refreshedToken);
                        onTokenChange(refreshedToken);  // Only update when necessary
                    }
                }
            }
        }, 5 * 60 * 1000); // Check every 5 minutes

        // Clear the interval on component unmount
        return () => clearInterval(interval);
    }, []); // Empty dependency array to run only on mount

    const handleTokenChange = (newToken) => {
        setToken(newToken);
        if (newToken && typeof newToken === "string") {
            const decodedToken = jwtDecode(newToken);
            setUserInfo(decodedToken);
            setSessionStorageItem("session_token", newToken);
        } else {
            setUserInfo(null);
            removeSessionStorageItem("session_token");
        }
        onTokenChange(newToken);
    };

    const handleLogout = async () => {
        sessionStorage.clear();
        setToken(null);
        setUserInfo(null);

        try {
            const response = await fetch(`${API_BASE_URL}/logout`, {
                method: "POST",
                credentials: "include"
            });

            if (response.ok) {
                console.log("Logged out successfully");
                window.location.reload();
            } else {
                console.error("Failed to log out:", response.statusText);
            }
        } catch (error) {
            console.error("Error during logout: ", error);
        }
    };

    return (
        <AuthenticationContainer>
            {userInfo ? (
                <div>
                    <center><h3>Welcome, {userInfo.username}!</h3></center>
                    <center><LogoutButton onClick={handleLogout}>Logout</LogoutButton></center>
                </div>
            ) : (
                <div>
                    <Phrase>Log in to your account</Phrase>
                    <Phrase>or sign up for a free membership!</Phrase>
                    <center>
                        <button onClick={() => setIsLogin(!isLogin)}>
                            {isLogin ? "Switch to Signup" : "Switch to Login"}
                        </button>
                    </center>
                    <div style={{ marginTop: '40px' }}>
                        {isLogin ? <LoginForm onTokenChange={handleTokenChange} /> : <SignUpForm onTokenChange={handleTokenChange} />}
                    </div>
                </div>
            )}
        </AuthenticationContainer>
    );
}

export default AuthContainer;
