import React, { useState, useEffect } from "react";
import axios from 'axios';
import GreetingMessage from '../../components/greeting';

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

const Purchase = () => {
    const [selectedProducts, setSelectedProducts] = useState({});
    const [selectedMembership, setSelectedMembership] = useState(null);
    const [coupons, setCoupons] = useState([]);
    const [totalPayment, setTotalPayment] = useState(0);
    const [originalAmount, setOriginalAmount] = useState(0);
    const [discountAmount, setDiscountAmount] = useState(0);
    const [totalDiscountRate, setTotalDiscountRate] = useState(0);
    const [couponInput, setCouponInput] = useState('');
    const [advisorName, setAdvisorName] = useState('');
    const [showCouponInput, setShowCouponInput] = useState(false);
    const [showOrderDetails, setShowOrderDetails] = useState(false);
    const [message, setMessage] = useState('');
    const [messageColor, setMessageColor] = useState('');
    const [isSpinnerRunning, setIsSpinnerRunning] = useState(false);

    const productPrices = {
        "Writing Plan A": 169.99,
        "Writing Plan B": 669.99,
        "Writing Plan C": 1119.99,
        "Expiration Date Extension: +45 days": 24.99,
        "Writing Limit Expansion: +10,000 words": 79.99,
        "Silver Membership": 34.99,
        "Gold Membership": 129.99,
    };

    const productPriceIDs = {
        'Writing Plan A': 'price_1OQ00YI3iPMtPJsfeOYyw3cW',
        'Writing Plan B': 'price_1OQ01nI3iPMtPJsfgDc9ih1I',
        'Writing Plan C': 'price_1OQ02vI3iPMtPJsfcgZfQUzd',
        'Expiration Date Extension: +45 days': 'price_1OQ04uI3iPMtPJsf60a6KzNP',
        'Writing Limit Expansion: +10,000 words': 'price_1OQ06cI3iPMtPJsflmjQNezi',
        'Silver Membership': 'price_1PFVcZI3iPMtPJsfcmS7Vjud',
        'Gold Membership': 'price_1PFVjaI3iPMtPJsf2a5oKkQv',
    }

    const handleProductSelection = (product, checked) => {
        setSelectedProducts(prev => ({
            ...prev,
            [product]: checked ? 1 : 0
        }));
    };

    const handleMembershipSelection = (membership) => {
        if (selectedMembership === membership) {
            setSelectedMembership(null); // Deselect if already selected
        } else {
            setSelectedMembership(membership); // Select if not already selected
        }
    };

    const handleQuantityChange = (product, quantity) => {
        setSelectedProducts(prev => ({
            ...prev,
            [product]: quantity
        }));
    };

    const calculateTotal = () => {
        let total = Object.entries(selectedProducts).reduce((sum, [product, quantity]) => {
            return sum + (quantity * productPrices[product]);
        }, 0);

        if (selectedMembership) {
            total += productPrices[selectedMembership];
        }

        const discountRate = coupons.reduce((sum, coupon) => sum + coupon.rate, 0);
        setTotalDiscountRate(discountRate);

        const discount = total * (totalDiscountRate / 100);
        setDiscountAmount(discount.toFixed(2));
        setOriginalAmount(total.toFixed(2));

        total -= discount;

        setTotalPayment(total.toFixed(2));
    };

    useEffect(() => {
        calculateTotal();
    }, [selectedProducts, selectedMembership, coupons, totalDiscountRate]);

    const handleCouponSubmission = async () => {
        if (coupons.length >= 3) {
            alert('You can only apply up to three coupons.');
            return;
        }

        try {
            const response = await fetch(`${API_BASE_URL}/providing_coupon`, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ coupon: couponInput, coupons: coupons }),
                credentials: 'include'
            });

            if (!response.ok) {
                throw new Error('Network response was not ok');
            }

            const { updated_coupons, coupon_success_message, coupon_error_message, advisor_name } = await response.json();

            if (coupon_success_message) {
                setCoupons(updated_coupons);
                setCouponInput('');
                setMessage(coupon_success_message);
                setMessageColor('green');

                calculateTotal();

                if (advisor_name) {
                    setAdvisorName(advisor_name)
                }

            } else {
                setMessage(coupon_error_message);
                setMessageColor('red');
            }
        } catch (error) {
            if (process.env.NODE_ENV === 'development') {
                console.error('Error:', error);
            }
            setMessage('An error occurred while processing the coupon.');
            setMessageColor('red');
        }
    };

    const handleCheckout = async () => {
        setIsSpinnerRunning(true);
        try {
            // Create the checkout_list in the required format
            const checkout_list = Object.entries(selectedProducts).reduce((list, [product, quantity]) => {
                if (quantity > 0 && productPriceIDs[product]) {
                    list.push({ price: productPriceIDs[product], quantity });
                }
                return list;
            }, []);

            // Add membership to the checkout_list if selected
            if (selectedMembership && productPriceIDs[selectedMembership]) {
                checkout_list.push({ price: productPriceIDs[selectedMembership], quantity: 1 });
            }

            // Create purchase_selection for additional details
            const purchase_selection = Object.entries(selectedProducts).reduce((list, [product, quantity]) => {
                if (quantity > 0) {
                    list.push({
                        product,
                        price_id: productPriceIDs[product],
                        price: productPrices[product],
                        quantity,
                    });
                }
                return list;
            }, []);

            if (selectedMembership) {
                purchase_selection.push({
                    product: selectedMembership,
                    price_id: productPriceIDs[selectedMembership],
                    price: productPrices[selectedMembership],
                    quantity: 1,
                });
            }

            const coupon_id = totalDiscountRate > 0 ? `COUPON_ID${totalDiscountRate}` : "";

            // Prepare the data for the backend call
            const checkoutData = {
                checkout_list,
                purchase_selection,
                advisor_name: advisorName,
                coupons,
                coupon_id, // Pass totalDiscountRate; the backend maps it to coupon_id
            };

            if (process.env.NODE_ENV === 'development') {
                console.log("Data passed to backend:", checkoutData);
            }

            // Make the backend call
            const response = await axios.post(`${API_BASE_URL}/checkout`, checkoutData, {
                withCredentials: true,
                headers: { 'Content-Type': 'application/json' },
            });

            if (response.status === 200) {
                setIsSpinnerRunning(false);
                setMessage('Checkout successful! Redirecting to payment...');
                setMessageColor('green');
                // Redirect to Stripe checkout page
                window.location.href = response.data.checkout_url;
            } else {
                setIsSpinnerRunning(false);
                throw new Error('Checkout failed.');
            }
        } catch (error) {
            setIsSpinnerRunning(false);
            if (process.env.NODE_ENV === 'development') {
                console.error('Error during checkout:', error);
            }
            setMessage('An error occurred with the checkout. Please try again.');
            setMessageColor('red');
        }
    };

    return (
        <>
            <h1 style={{marginBottom: "0px"}}>Make A Purchase</h1>
            <GreetingMessage />
            <p style={{marginTop: "0px", marginBottom: "0px"}}>Select any writing plan, extension option, or membership, and specify the desired quantities.</p>
            <p style={{marginTop: "10px", marginBottom: "0px"}}>All products can be combined.</p>
            <div className="product-catalog">
                <div className="catalog-box">
                    <h3 style={{ marginTop: "0px", textAlign: "center" }}>Product Catalog</h3>
                    <h4 style={{ marginTop: "20px", marginBottom: "10px" }}>Writing Plans</h4>
                    {["Writing Plan A", "Writing Plan B", "Writing Plan C"].map(plan => (
                        <div key={plan} className="catalog-item">
                            <div style={{ flex: 1, display: 'flex', alignItems: 'center' }}>
                                <input
                                    type="checkbox"
                                    style={{ marginLeft: "20px", marginRight: "10px" }}
                                    onChange={e => handleProductSelection(plan, e.target.checked)}
                                />
                                {plan} (${productPrices[plan]})
                            </div>
                            {selectedProducts[plan] > 0 && (
                                <div style={{ display: 'flex', alignItems: 'center', gap: '5px', justifyContent: 'flex-end', flex: 1 }}>
                                    <p style={{ margin: 0 }}>Quantity:</p>
                                    <input
                                        type="number"
                                        className="quantity-input"
                                        style={{ width: '50px', textAlign: 'right' }}
                                        value={selectedProducts[plan]}
                                        onChange={e => handleQuantityChange(plan, parseInt(e.target.value) || 0)}
                                        min="0"
                                    />
                                </div>
                            )}
                        </div>
                    ))}

                    <h4 style={{ marginTop: "20px", marginBottom: "10px" }}>Extension Options</h4>
                    {["Expiration Date Extension: +45 days", "Writing Limit Expansion: +10,000 words"].map(option => (
                        <div key={option} className="catalog-item">
                            <div style={{ flex: 1, display: 'flex', alignItems: 'center' }}>
                                <input
                                    type="checkbox"
                                    style={{ marginLeft: "20px", marginRight: "10px" }}
                                    onChange={e => handleProductSelection(option, e.target.checked)}
                                />
                                {option} (${productPrices[option]})
                            </div>
                            {selectedProducts[option] > 0 && (
                                <div style={{ display: 'flex', alignItems: 'center', gap: '5px', justifyContent: 'flex-end', flex: 1 }}>
                                    <p style={{ margin: 0 }}>Quantity:</p>
                                    <input
                                        type="number"
                                        className="quantity-input"
                                        style={{ width: '50px', textAlign: 'right' }}
                                        value={selectedProducts[option]}
                                        onChange={e => handleQuantityChange(option, parseInt(e.target.value) || 0)}
                                        min="0"
                                    />
                                </div>
                            )}
                        </div>
                    ))}

                    <h4 style={{marginTop: "20px", marginBottom: "10px"}}>Premium Memberships</h4>
                    {["Silver Membership", "Gold Membership"].map(membership => (
                        <div key={membership}>
                            <input
                                type="checkbox"
                                style={{marginLeft: "20px", marginRight: "10px"}}
                                onChange={() => handleMembershipSelection(membership)}
                                checked={selectedMembership === membership}
                            />
                            {membership} (${productPrices[membership]})
                        </div>
                    ))}
                </div>
            </div>
            <div style={{border: "0px", marginTop: "20px"}} className="product-catalog">
                <div>
                    <input
                        type="checkbox"
                        style={{marginLeft: "20px", marginRight: "10px", marginTop:"0px"}}
                        onChange={() => setShowCouponInput(!showCouponInput)}
                    />
                    Do you have a coupon?
                    {showCouponInput && (
                        <div>
                            <input
                                type="text"
                                style={{fontSize: '0.8em', marginLeft: "40px", marginRight: "20px", marginTop:"10px"}}
                                value={couponInput}
                                onChange={e => setCouponInput(e.target.value)}
                                placeholder="Enter coupon code"
                            />
                            <button
                                className='btn-record-managing'
                                onClick={handleCouponSubmission}>Submit Coupon</button>
                            {message && (
                                <div style={{ color: messageColor, marginTop: '10px' }}>
                                    {message}
                                </div>
                            )}
                        </div>
                    )}
                </div>

                <h3 style={{marginLeft: "20px"}}>Estimated Total Payment: ${totalPayment}</h3>
                {!showOrderDetails ? (
                    <button
                        className='btn-record-action'
                        style={{ marginLeft: "20px" }}
                        onClick={() => setShowOrderDetails(true)}
                        disabled={totalPayment <= 0}
                    >
                        Check Your Order
                    </button>
                ) : (
                    <div className="order-details" style={{ marginTop: "20px", padding: "10px", border: "1px solid #ddd", borderRadius: "5px" }}>
                        <h3 style={{marginTop: "20px", textAlign: "center"}}>Check Your Order</h3>
                        <h4 style={{marginTop: "20px", marginBottom: "10px"}}>Selected Products/Services:</h4>
                        <ul style={{ listStyleType: "none", padding: "0" }}>
                            {Object.entries(selectedProducts).map(([product, quantity]) =>
                                quantity > 0 ? (
                                    <li
                                        style={{
                                            display: "flex",
                                            justifyContent: "space-between",
                                            marginTop: "0px",
                                            marginBottom: "10px",
                                            marginLeft: "30px",
                                            marginRight: "40px",
                                        }}
                                        key={product}
                                    >
                                        <span>{product}</span>
                                        <span>${productPrices[product]} x {quantity}</span>
                                    </li>
                                ) : null
                            )}
                            {selectedMembership && (
                                <li
                                    style={{
                                        display: "flex",
                                        justifyContent: "space-between",
                                        marginTop: "0px",
                                        marginBottom: "10px",
                                        marginLeft: "30px",
                                        marginRight: "40px",
                                    }}
                                >
                                    <span>{selectedMembership}</span>
                                    <span>${productPrices[selectedMembership]}</span>
                                </li>
                            )}
                        </ul>
                        {coupons.length > 0 && (
                            <>
                                <h4 style={{marginTop: ";20px", marginBottom: "10px"}}>Coupons:</h4>
                                <ul style={{ listStyleType: "none", padding: "0" }}>
                                    {coupons.map((coupon, index) => (
                                        <li
                                            key={index}
                                            style={{
                                                display: "flex",
                                                justifyContent: "space-between",
                                                marginTop: "0px",
                                                marginBottom: "10px",
                                                marginLeft: "30px",
                                                marginRight: "40px",
                                            }}
                                        >
                                            <span>{coupon['coupon']}</span>
                                            <span>{coupon.rate}% off</span>
                                        </li>
                                    ))}
                                </ul>
                            </>
                        )}
                        <hr style={{ border: "1px solid #ddd", marginTop: "20px", marginBottom: "20px" }} />
                        {discountAmount > 0 && (
                            <>
                                <div style={{ display: "flex", justifyContent: "space-between", marginTop: "0px", marginRight: "40px" }}>
                                    <p>Original Amount:</p>
                                    <p>${originalAmount}</p>
                                </div>
                                <div style={{ display: "flex", justifyContent: "space-between", marginTop: "0px", marginRight: "40px" }}>
                                    <p style={{marginTop: "0px"}}>Discount ({totalDiscountRate}% off):</p>
                                    <p style={{marginTop: "0px"}}>- ${discountAmount}</p>
                                </div>
                            </>
                        )}
                        <div style={{ display: "flex", justifyContent: "space-between", marginTop: "0px", marginRight: "40px" }}>
                            <p style={{marginTop: "0px"}}><strong>Actual Payment:</strong></p>
                            <p style={{marginTop: "0px"}}><strong>${totalPayment}</strong></p>
                        </div>
                        <div style={{ display: "flex", justifyContent: "center", marginBottom: "20px" }}>
                            {
                                isSpinnerRunning ? (
                                    <div className="simpleSpinner"></div> // Spinner component or div
                                ) : (
                                    <button
                                        style={{ padding: "2px 12px", marginTop: "20px", fontWeight: "bold" }}
                                        onClick={handleCheckout}
                                    >
                                        Proceed to Checkout
                                    </button>
                                )
                            }
                        </div>
                    </div>
                )}
            </div>
        </>
    );
}

export default Purchase;