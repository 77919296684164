import React, { createContext, useState, useEffect } from 'react';
import { getSessionStorageItem, setSessionStorageItem } from "./Mask";

export const SettingsContext = createContext();

export const SettingsProvider = ({ children }) => {
  const [settings, setSettings] = useState(() => {
    // Load settings from sessionStorage using getSessionStorageItem or fall back to defaults
    const storedSettings = {
      pageWidth: getSessionStorageItem('page_width'),
      fontSize: getSessionStorageItem('font_size'),
      colorTheme: getSessionStorageItem('color_theme'),
    };

    return {
      pageWidth: storedSettings.pageWidth || '80%',
      fontSize: storedSettings.fontSize || 'medium',
      colorTheme: storedSettings.colorTheme || 'light',
    };
  });

  const updateSettings = (newSettings) => {
    setSettings((prev) => {
      const updatedSettings = { ...prev, ...newSettings };

      // Apply CSS variables globally
      const root = document.documentElement;

      // Apply page width
      root.style.setProperty('--page-width', updatedSettings.pageWidth);

      // Apply font size
      const fontSizeMap = {
        'very small': '12px',
        small: '14px',
        medium: '16px',
        large: '18px',
        'very large': '20px',
      };
      root.style.setProperty('--font-size', fontSizeMap[updatedSettings.fontSize]);

      // Apply color theme
      const themeMap = {
        light: {
          '--sidebar-bg-color': '#2c3e50',
          '--header-bg-color': '#2c3e50',
          '--content-bg-color': '#f9f9f9',
          '--text-color': '#2c3e50',
          '--btn-bg-color': '#f9f9f9',
          '--btn-txt-color': '#2c3e50',
          '--btn-hover-bg-color': '#f9f9f9',
          '--btn-hover-txt-color': '#2c3e50',
        },
        dark: {
          '--sidebar-bg-color': '#000',
          '--header-bg-color': '#000',
          '--content-bg-color': '#333',
          '--text-color': '#fff',
          '--btn-bg-color': '#444',
          '--btn-txt-color': '#ffffff',
          '--btn-hover-bg-color': '#555',
          '--btn-hover-txt-color': '#ffffff',
        },
        blue: {
          '--sidebar-bg-color': '#1e3a8a',
          '--header-bg-color': '#1e3a8a',
          '--content-bg-color': '#e3f2fd',
          '--text-color': '#0d47a1',
          '--btn-bg-color': '#0d47a1',
          '--btn-txt-color': '#ffffff',
          '--btn-hover-bg-color': '#1565c0',
          '--btn-hover-txt-color': '#ffffff',
        },
        green: {
          '--sidebar-bg-color': '#004d40',
          '--header-bg-color': '#004d40',
          '--content-bg-color': '#e8f5e9',
          '--text-color': '#1b5e20',
          '--btn-bg-color': '#1b5e20',
          '--btn-txt-color': '#ffffff',
          '--btn-hover-bg-color': '#2e7d32',
          '--btn-hover-txt-color': '#ffffff',
        },
        softGray: {
          '--sidebar-bg-color': '#34495e',
          '--header-bg-color': '#2c3e50',
          '--content-bg-color': '#ecf0f1',
          '--text-color': '#34495e',
          '--btn-bg-color': '#2c3e50',
          '--btn-txt-color': '#ffffff',
          '--btn-hover-bg-color': '#3b5998',
          '--btn-hover-txt-color': '#ffffff',
        },
        emerald: {
          '--sidebar-bg-color': '#2c3e50',
          '--header-bg-color': '#1abc9c',
          '--content-bg-color': '#ecf9f3',
          '--text-color': '#16a085',
          '--btn-bg-color': '#1abc9c',
          '--btn-txt-color': '#ffffff',
          '--btn-hover-bg-color': '#148f77',
          '--btn-hover-txt-color': '#ffffff',
        },
        sunset: {
          '--sidebar-bg-color': '#8e44ad',
          '--header-bg-color': '#c0392b',
          '--content-bg-color': '#f5e0dc',
          '--text-color': '#e74c3c',
          '--btn-bg-color': '#c0392b',
          '--btn-txt-color': '#ffffff',
          '--btn-hover-bg-color': '#d35400',
          '--btn-hover-txt-color': '#ffffff',
        },
        ocean: {
          '--sidebar-bg-color': '#34495e',
          '--header-bg-color': '#2980b9',
          '--content-bg-color': '#d6eaf8',
          '--text-color': '#2c3e50',
          '--btn-bg-color': '#2980b9',
          '--btn-txt-color': '#ffffff',
          '--btn-hover-bg-color': '#3498db',
          '--btn-hover-txt-color': '#ffffff',
        },
        forest: {
          '--sidebar-bg-color': '#145a32',
          '--header-bg-color': '#186a3b',
          '--content-bg-color': '#d4efdf',
          '--text-color': '#1d8348',
          '--btn-bg-color': '#229954',
          '--btn-txt-color': '#ffffff',
          '--btn-hover-bg-color': '#27ae60',
          '--btn-hover-txt-color': '#ffffff',
        },
      };

      const theme = themeMap[updatedSettings.colorTheme];
      for (const [key, value] of Object.entries(theme)) {
        root.style.setProperty(key, value);
      }

      // Save settings to sessionStorage using setSessionStorageItem
      setSessionStorageItem('page_width', updatedSettings.pageWidth);
      setSessionStorageItem('font_size', updatedSettings.fontSize);
      setSessionStorageItem('color_theme', updatedSettings.colorTheme);

      return updatedSettings; // Return the updated state
    });
  };

  useEffect(() => {
    // Apply settings on initial render
    updateSettings(settings);
  }, []); // Run only once

  return (
    <SettingsContext.Provider value={{ settings, updateSettings }}>
      {children}
    </SettingsContext.Provider>
  );
};
