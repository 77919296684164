import React, { useState } from "react";
import axios from "axios";
import "../../App.css"; // Ensure styling matches the updated layout

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

const ContactUs = () => {
  const [contactMethod, setContactMethod] = useState(null);
  const [comments, setComments] = useState("");
  const [emailData, setEmailData] = useState({
    fullname: "",
    senderEmail: "",
    subject: "",
    message: "",
    attachment: null,
  });
  const [loading, setLoading] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  const handleContactMethodChange = (method) => {
    setContactMethod(method);
    setComments("");
    setEmailData({
      fullname: "",
      senderEmail: "",
      subject: "",
      message: "",
      attachment: null,
    });
    setSuccessMessage("");
    setErrorMessage("");
  };

  const handleEmailChange = (e) => {
    const { name, value } = e.target;
    setEmailData((prevData) => ({ ...prevData, [name]: value }));
  };

  const handleFileChange = (e) => {
    setEmailData((prevData) => ({ ...prevData, attachment: e.target.files[0] }));
  };

  const handleSendMessage = async () => {
    if (comments.length < 10) {
      setErrorMessage("Please leave a complete comment.");
      return;
    }
    setLoading(true);
    try {
      const response = await axios.post(
        `${API_BASE_URL}/send_message`,
        { comments },
        { withCredentials: true }
      );
      if (response.data.status === "success") {
        setSuccessMessage(response.data.message || "Thank you for your comments.");
        setComments(""); // Clear the textarea after success
        setErrorMessage(""); // Clear any previous error message
      } else {
        setErrorMessage(response.data.message || "An unexpected error occurred.");
      }
    } catch (error) {
      // Handle network or server errors
      setErrorMessage(
        error.response?.data?.detail || "Failed to send your message. Please try again."
      );
    } finally {
      setLoading(false);
    }
  };

  const handleSendEmail = async () => {
    const { fullname, senderEmail, subject, message, attachment } = emailData;

    if (!fullname || !senderEmail || !subject || !message || message.length < 20) {
      setErrorMessage("Please fill out all fields with valid information.");
      return;
    }

    const formData = new FormData();
    formData.append("fullname", fullname);
    formData.append("senderEmail", senderEmail);
    formData.append("subject", subject);
    formData.append("message", message);
    if (attachment) {
      formData.append("attachment", attachment);
    }

    setLoading(true);
    try {
      const response = await axios.post(`${API_BASE_URL}/send_email`, formData, {
        withCredentials: true,
        headers: { "Content-Type": "multipart/form-data" },
      });

      const { status, message } = response.data;

      if (status === "success") {
        setSuccessMessage(message || "Message has been sent successfully.");
        setEmailData({
          fullname: "",
          senderEmail: "",
          subject: "",
          message: "",
          attachment: null,
        });
        setErrorMessage("");
      } else {
        setErrorMessage(message || "An unexpected error occurred.");
      }
    } catch (error) {
      const backendMessage = error.response?.data?.detail;
      setErrorMessage(backendMessage || "Failed to send your email. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="contact-us">
      <p style={{ color: "blue", marginTop: "0px"}}>Should you experience any issues, feel free to contact us. We will respond promptly.</p>
      <h1 style={{textAlign: "center", marginTop: "0px", marginBottom: "40px"}}>Select the Method to Contact Us</h1>
      <div className="contact-methods">
        <button onClick={() => handleContactMethodChange("message")}>
          📨 Message Us
        </button>
        <button onClick={() => handleContactMethodChange("email")}>
          ✉️ Email Us
        </button>
        <button onClick={() => handleContactMethodChange("call")}>
          📞 Call Us
        </button>
      </div>

      {contactMethod === "message" && (
        <div className="contact-form">
          <textarea
            placeholder="Provide your comments here"
            style={{minHeight: "100px", maxHeight: "500px", overflow: "auto"}}
            value={comments}
            onChange={(e) => setComments(e.target.value)}
          ></textarea>
          <button style={{ padding: "2px 12px" }} onClick={handleSendMessage} disabled={loading}>
            {loading ? "Sending..." : "Send Comments"}
          </button>
        </div>
      )}

      {contactMethod === "email" && (
        <div className="contact-form">
          <input
            type="text"
            name="fullname"
            placeholder="Your Name"
            value={emailData.fullname}
            onChange={handleEmailChange}
          />
          <input
            type="email"
            name="senderEmail"
            placeholder="Your Email"
            value={emailData.senderEmail}
            onChange={handleEmailChange}
          />
          <input
            type="text"
            name="subject"
            placeholder="Message Subject"
            value={emailData.subject}
            onChange={handleEmailChange}
          />
          <textarea
            name="message"
            placeholder="Enter your message here"
            style={{minHeight: "100px", maxHeight: "500px", overflow: "auto"}}
            value={emailData.message}
            onChange={handleEmailChange}
          ></textarea>
          <input type="file" onChange={handleFileChange} />
          <button style={{ padding: "2px 12px" }} onClick={handleSendEmail} disabled={loading}>
            {loading ? "Sending..." : "Send Email"}
          </button>
        </div>
      )}

      {contactMethod === "call" && (
        <div>
          <p style={{marginTop: "40px"}}>
            Phone: +1 (844)-677 0143
          </p>
          <p>
            Leave us a message with your name, username, phone number, and your
            question. We’ll get back to you as soon as possible.
          </p>
        </div>
      )}

      {successMessage && <p className="success-message">{successMessage}</p>}
      {errorMessage && <p className="error-message">{errorMessage}</p>}
    </div>
  );
};

export default ContactUs;
