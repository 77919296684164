// WordRange.js
function wordRange(wordLimit) {
    let expansion, contraction, l01, u01, l02, l05, token1, limitLower, l03, l04;

    if (wordLimit < 100) {
        expansion = wordLimit - 10;
        contraction = wordLimit + 5;
        l01 = wordLimit - 25;
        u01 = wordLimit + 20;
        l02 = expansion;
        l05 = wordLimit - 5;
        token1 = Math.floor(wordLimit * 10);
        limitLower = Math.floor(0.9 * wordLimit);
    } else if (wordLimit < 200) {
        expansion = wordLimit - 20;
        contraction = wordLimit + 10;
        l01 = wordLimit - 35;
        u01 = wordLimit + 30;
        l02 = expansion;
        l05 = wordLimit - 5;
        token1 = Math.floor(wordLimit * 7.5);
        limitLower = Math.floor(0.9 * wordLimit);
    } else if (wordLimit < 300) {
        expansion = wordLimit - 20;
        contraction = wordLimit + 10;
        l01 = wordLimit - 50;
        u01 = wordLimit + 40;
        l02 = expansion;
        l05 = wordLimit - 10;
        token1 = Math.floor(wordLimit * 5);
        limitLower = Math.max(wordLimit - 25, Math.floor(0.9 * wordLimit));
    } else if (wordLimit < 400) {
        expansion = wordLimit - 30;
        contraction = wordLimit + 10;
        l01 = wordLimit - 60;
        u01 = wordLimit + 40;
        l02 = expansion;
        l05 = wordLimit - 10;
        token1 = Math.floor(wordLimit * 4);
        limitLower = Math.floor(0.95 * wordLimit - 10);
    } else if (wordLimit < 500) {
        expansion = wordLimit - 30;
        contraction = wordLimit + 10;
        l01 = wordLimit - 75;
        u01 = wordLimit + 40;
        l02 = expansion;
        l05 = wordLimit - 10;
        token1 = Math.floor(wordLimit * 3.5);
        limitLower = Math.floor(0.95 * wordLimit - 10);
    } else if (wordLimit < 600) {
        expansion = wordLimit - 40;
        contraction = wordLimit + 10;
        l01 = wordLimit - 85;
        u01 = wordLimit + 50;
        l02 = expansion;
        l05 = wordLimit - 10;
        token1 = Math.floor(wordLimit * 3);
        limitLower = Math.floor(0.95 * wordLimit - 10);
    } else if (wordLimit < 700) {
        expansion = wordLimit - 40;
        contraction = wordLimit + 15;
        l01 = wordLimit - 100;
        u01 = wordLimit + 60;
        l02 = expansion;
        l05 = wordLimit - 25;
        token1 = Math.floor(wordLimit * 2.5);
        limitLower = Math.floor(0.95 * wordLimit - 10);
    } else {
        expansion = wordLimit - 60;
        contraction = wordLimit + 25;
        l01 = wordLimit - 150;
        u01 = wordLimit + 100;
        l02 = expansion;
        l05 = wordLimit - 35;
        token1 = Math.floor(wordLimit * 2.5);
        limitLower = wordLimit - 50;
    }

    token1 = Math.max(token1, 500);
    l03 = Math.round((l02 + (l05 - l02) / 3) / 5) * 5;
    l04 = Math.round((l05 - (l05 - l02) / 3) / 5) * 5;

    return { expansion, contraction, l01, u01, token1, l02, l03, l04, l05, limitLower };
}

export default wordRange;
