//frontend/src/pages/Account.js
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import '../App.css';
import UserInstructions from './subHelpSupport/UserInstructions';
import PrivacyPolicy from './subHelpSupport/PrivacyPolicy';
import ContactUs from './subHelpSupport/ContactUs';
import WritingUsage from './subHelpSupport/WritingUsage';
import UsageSuggestions from './subHelpSupport/UsageSuggestions';

const HelpSupport = () => {
    const navigate = useNavigate();

    // State to manage the active tab
    const [activeTab, setActiveTab] = useState("User Instructions");

    // Check the URL hash on load and set the active tab accordingly
    useEffect(() => {
        const hash = window.location.hash;
        if (hash === "#UserInstructions") {
            setActiveTab("User Instructions");
        } else if (hash === "#WritingUsage") {
            setActiveTab("Writing Usage");
        } else if (hash === "#UsageSuggestions") {
            setActiveTab("Usage Suggestions");
        } else if (hash === "#PrivacyPolicy") {
            setActiveTab("Privacy Policy");
        } else if (hash === "#ContactUs") {
            setActiveTab("Contact Us");
        } else {
            setActiveTab("User Instructions");
        }
    }, []);

    // Update the URL hash when the active tab changes
    const handleTabClick = (tabName) => {
        setActiveTab(tabName);
        if (tabName === "User Instructions") {
            window.location.hash = "#UserInstructions";
        } else if (tabName === "Writing Usage") {
            window.location.hash = "#WritingUsage";
        } else if (tabName === "Usage Suggestions") {
            window.location.hash = "#UsageSuggestions";
        } else if (tabName === "Privacy Policy") {
            window.location.hash = "#PrivacyPolicy";
        } else if (tabName === "Contact Us") {
            window.location.hash = "#ContactUs";
        } else {
            window.location.hash = "#UserInstructions";
        }
    };

    const tabs = [
        { name: "User Instructions", component: <UserInstructions /> },
        { name: "Writing Usage", component: <WritingUsage /> },
        { name: "Usage Suggestions", component: <UsageSuggestions /> },
        { name: "Privacy Policy", component: <PrivacyPolicy /> },
        { name: "Contact Us", component: <ContactUs /> },
    ];

    return (
        <>
            <div className="Page-header">
                <div className="Page-tabs">
                    {tabs.map((tab) => (
                        <span
                            key={tab.name}
                            className={`Page-tab ${activeTab === tab.name ? "active" : ""}`}
                            onClick={() => handleTabClick(tab.name)}
                        >
                            {tab.name}
                        </span>
                    ))}
                </div>
            </div>
            <div className="Page-maincontent">
                {tabs.find((tab) => tab.name === activeTab).component}
            </div>
        </>
    );
};

export default HelpSupport;
