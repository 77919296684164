//frontend/src/pages/Account.js
import React, { useState, useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Sidebar from '../Sidebar';
import AuthContainer from '../components/Authentication';
import { jwtDecode } from "jwt-decode";
import { useAppState } from '../components/AppContext';
import '../App.css';
import UserProfile from './subAccount/UserProfile';
import EditAccount from './subAccount/EditAccount';
import PageSetting from './subAccount/PageSetting';


const AccountPage = () => {
    const { updateToken } = useAppState();
    const [token, setToken] = useState(null);
    const [userInfo, setUserInfo] = useState(null);

    const handleTokenChange = (newToken) => {
        setToken(newToken);
        updateToken(newToken)
        if (newToken) {
            const decodedToken = jwtDecode(newToken);
            setUserInfo(decodedToken);
        } else {
            setUserInfo(null);
        }
    };
    const { state } = useAppState();

    // Use useEffect to update userInfo if state.userInfo exists
    useEffect(() => {
        if (state?.userInfo) {
            setUserInfo(state.userInfo);
        }
    }, [state?.userInfo]);

    const [activeTab, setActiveTab] = useState("User Profile");

    // Check the URL hash on load and set the active tab accordingly
    useEffect(() => {
        const hash = window.location.hash;
        if (hash === "#EditAccount") {
            setActiveTab("Edit Account");
        } else if (hash === "#PageSetting") {
            setActiveTab("Page Setting");
        } else {
            setActiveTab("User Profile");
        }
    }, []);

    // Update the URL hash when the active tab changes
    const handleTabClick = (tabName) => {
        setActiveTab(tabName);
        if (tabName === "Edit Account") {
            window.location.hash = "#EditAccount";
        } else if (tabName === "Page Setting") {
            window.location.hash = "#PageSetting";
        } else {
            window.location.hash = "#UserProfile";
        }
    };

    const tabs = [
        { name: "User Profile", component: <UserProfile /> },
        { name: "Edit Account", component: <EditAccount /> },
        { name: "Page Setting", component: <PageSetting /> },
    ];

    return (
        <>
            <div className="Page-header">
                <div className="Page-tabs">
                    {tabs.map((tab) => (
                        <span
                            key={tab.name}
                            className={`Page-tab ${activeTab === tab.name ? "active" : ""}`}
                            onClick={() => handleTabClick(tab.name)}
                        >
                            {tab.name}
                        </span>
                    ))}
                </div>
            </div>
            <div className="Page-maincontent">
                 {tabs.find((tab) => tab.name === activeTab).component}
            </div>
        </>
    );
};

export default AccountPage;


