import React, { useState, useEffect, useRef } from "react";
import axios from 'axios';
import getAIAccount from '../../components/GetAIAccount';
import wordRange from '../../components/WordRange';
import EssayOptionsBox from '../../components/EssayOptionsBox';
import FileUpload from "../../components/FileUpload";
import DownloadOptions from "../../components/DownloadOptions";
import GreetingMessage from '../../components/greeting';
import {
    getSessionStorageItem,
    updateSessionData,
    setSessionStorageItem,
    removeSessionStorageItem,
} from "../../components/Mask";

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

const aiAccount = getAIAccount();

function EssayWriting() {
const [username, setUsername] = useState(getSessionStorageItem("username"));
    const [expirationDate, setExpirationDate] = useState(getSessionStorageItem("expiration_date"));
    const [writingLimit, setWritingLimit] = useState(getSessionStorageItem("writing_limit", 0));
    const [totalWords, setTotalWords] = useState(getSessionStorageItem("total_words", 0));
    const currentDate = new Date();
    const shortDate = expirationDate.substring(0, 10);
    const expiration = new Date(shortDate);
    const numericWritingLimit = Number(writingLimit);
    const numericTotalWords = Number(totalWords);

    const [collegePrompt, setCollegePrompt] = useState(getSessionStorageItem("college_prompt"));
    const [wordLimit, setWordLimit] = useState(getSessionStorageItem("word_limit", "300"));
    const [limitLower, setLimitLower] = useState(getSessionStorageItem("limit_lower", ""));
    const [keyIdea, setKeyIdea] = useState(getSessionStorageItem("key_idea"));
    const [writingIdea, setWritingIdea] = useState(getSessionStorageItem("writing_idea"));
    const [collegeIdea, setCollegeIdea] = useState(getSessionStorageItem("college_idea"));
    const [writingFramework, setWritingFramework] = useState(getSessionStorageItem("writing_framework"));
    const [draftEssay, setDraftEssay] = useState(getSessionStorageItem("draft_essay"));
    const [evaluationSuggestion, setEvaluationSuggestion] = useState(getSessionStorageItem('evaluation_suggestion'));
    const [essaySuggestion, setEssaySuggestion] = useState("");
    const [evaluationReport, setEvaluationReport] = useState(getSessionStorageItem('evaluation_report'));
    const [originalCollegeIdea, setOriginalCollegeIdea] = useState("");
    const [originalWritingFramework, setOriginalWritingFramework] = useState("");
    const [originalDraftEssay, setOriginalDraftEssay] = useState("");
    const [essayVersionIndex, setEssayVersionIndex] = useState(null);
    const [essayVersionMax, setEssayVersionMax] = useState(null);
    const [essayLoading, setEssayLoading] = useState(false);
    const [isApplyButtonDisabled, setIsApplyButtonDisabled] = useState(false);
    const [isResetButtonDisabled, setIsResetButtonDisabled] = useState(true);
    const [writingWords, setWritingWords] = useState(getSessionStorageItem("writing_words", ""));
    const [words, setWords] = useState(getSessionStorageItem("words", ""));
    const [essayErrorMessage, setEssayErrorMessage] = useState("");

    const [noEssayVersionMessage, setNoEssayVersionMessage] = useState("");

    const [options, setOptions] = useState({
        applyBrainstorming: false,
        applyEvaluation: false,
        finalizeEssay: false
    });

    useEffect(() => {
        const handleStorageChange = () => {
            // Update state when sessionStorage values change
            setWritingIdea(getSessionStorageItem('writing_idea'));
            setWritingFramework(getSessionStorageItem('writing_framework'));
            setEvaluationSuggestion(getSessionStorageItem('evaluation_suggestion'));
            setWordLimit(getSessionStorageItem('word_limit'));
            setLimitLower(getSessionStorageItem('limit_lower'));
        };

        // Add an event listener to detect changes in sessionStorage
        window.addEventListener('storage', handleStorageChange);

        // Cleanup the event listener on component unmount
        return () => {
            window.removeEventListener('storage', handleStorageChange);
        };
    }, []);

    const handleOptionChange = (newOptions) => {
        setOptions(newOptions);
        if (process.env.NODE_ENV === 'development') {
            console.log("Updated options:", newOptions); // Debugging line to check the new state
        }
    };

    // Reference to store the current AbortController
    const essayAbortController = useRef(null);

    const [initialHeight, setInitialHeight] = useState("80px");
    const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth < 768);
    const ideaRef = useRef(null);
    const frameworkRef = useRef(null);
    const draftRef = useRef(null);

    // Function to calculate height based on content
    const newHeight = (text, pairedText, minHeight, maxHeight) => {
        const lineHeight = 25; // Adjust line height as needed
        const lines = Math.max(
            text.split("\n").length,
            pairedText ? pairedText.split("\n").length : 0
        );
        const calculatedHeight = Math.min(Math.max(25 + lines * lineHeight, minHeight), maxHeight);
        return `${calculatedHeight}px`;
    };

    useEffect(() => {
        const handleResize = () => {
            setIsSmallScreen(window.innerWidth < 768);

            if (ideaRef.current && draftRef.current) {
                if (isSmallScreen) {
                    // Adjust heights independently for small screens
                    ideaRef.current.style.height = newHeight(collegeIdea || "", "", 100, 500);
                    draftRef.current.style.height = newHeight(draftEssay || "", "", 200, 1000);
                } else {
                    // Adjust heights based on Essay logic
                    if (options.applyBrainstorming && writingFramework) {
                        const sharedHeight = newHeight(collegeIdea || "", writingFramework, 100, 800);
                        ideaRef.current.style.height = sharedHeight;
                        frameworkRef.current.style.height = sharedHeight;
                        setInitialHeight(sharedHeight);
                    } else {
                        // Adjust heights independently
                        ideaRef.current.style.height = newHeight(collegeIdea || "", "", 100, 800);
                        draftRef.current.style.height = newHeight(draftEssay || "", "", 200, 1000);
                    }
                }
            }
        };

        // Initial height adjustment
        handleResize();

        // Listen for window resize
        window.addEventListener("resize", handleResize);
        return () => window.removeEventListener("resize", handleResize);
    }, [collegeIdea, writingFramework, options.applyBrainstorming, isSmallScreen, draftEssay]);

    const handleFileLoad = (fileContent) => {
            updateSessionData("draft_essay", fileContent, setDraftEssay);
        };

    useEffect(() => {
        if (options.applyEvaluation && draftEssay) {
            updateSessionData("essay_suggestion", evaluationSuggestion, setEssaySuggestion);
        } else {
            updateSessionData("essay_suggestion", null, setEssaySuggestion);
        }
    }, [options.applyEvaluation, evaluationSuggestion]);


    useEffect(() => {
        if (options.applyBrainstorming && writingFramework && ideaRef.current && frameworkRef.current) {
            // Calculate shared height based on the content of both textareas
            const sharedHeight = newHeight(
                writingIdea || "",
                writingFramework || "",
                200, 800
            );

            // Apply the calculated shared height to both textarea
            ideaRef.current.style.height = sharedHeight;
            frameworkRef.current.style.height = sharedHeight;
            setInitialHeight(sharedHeight); // Update the initial height for dynamic resizing
        }
    }, [collegeIdea, writingIdea, writingFramework, options.applyBrainstorming]);

    useEffect(() => {
        if (wordLimit) {
            // Run the calculation and save limitLower to sessionStorage as limit_lower
            const rangeResults = wordRange(Number(wordLimit));
            setSessionStorageItem('limit_lower', rangeResults.limitLower.toString()); // Save as string
        }
    }, [wordLimit]);

    // Sync EssayVersionIndex and EssayVersionMax with sessionStorage whenever they change
    useEffect(() => {
        if (essayVersionIndex !== null) {
            setSessionStorageItem("essayVersionIndex", essayVersionIndex);
        }
        if (essayVersionMax !== null) {
            setSessionStorageItem("essayVersionMax", essayVersionMax);
        }
    }, [essayVersionIndex, essayVersionMax]);

    useEffect(() => {
        if (options.source !== 'lastVersion') {
            setNoEssayVersionMessage("")
        }
    }, [options.source]);

    // Load a specific essay version from the backend, or latest if essayVersionIndex is null
    const loadEssayVersion = async (version = null) => {
        try {
            setNoEssayVersionMessage(""); // clear any previous message
            setEssayErrorMessage("");
            const params = version !== null && version !== undefined ? { version_number: version } : {};
            const response = await axios.get(`${API_BASE_URL}/get_essay_session_data`, {
                withCredentials: true, // Include cookies with the request
                params
            });

            const versionData = response.data;

            if (!versionData || versionData.essay_version === null || versionData.essay_version === "") {
                // Set message if no version data is available
                setNoEssayVersionMessage("No essay found. Start a new essay writing process.");
            } else {
                updateSessionData("college_prompt", versionData.college_prompt, setCollegePrompt);
                updateSessionData("word_limit", versionData.word_limit, setWordLimit);
                updateSessionData("college_idea", versionData.writing_idea, setCollegeIdea);
                updateSessionData("draft_essay", versionData.draft_essay, setDraftEssay);
                updateSessionData("evaluation_suggestion", versionData.evaluation_suggestion, setEvaluationSuggestion);
                updateSessionData("evaluation_report", versionData.evaluation_report, setEvaluationReport);
                updateSessionData("writing_words", versionData.writing_words, setWritingWords)
                updateSessionData("words", versionData.words, setWords)

                // Set version indices based on condition
                if (essayVersionIndex === null) {
                    updateSessionData("essayVersionIndex", versionData.essay_version, setEssayVersionIndex);
                    updateSessionData("essayVersionMax", versionData.essay_version, setEssayVersionMax);
                } else {
                    updateSessionData("essayVersionIndex", versionData.essay_version, setEssayVersionIndex);
                }
            }
            return versionData;
        } catch (error) {
            if (error.name === 'AbortError') {
                if (process.env.NODE_ENV === 'development') {
                    console.log("Essay request was aborted.");
                }
                setNoEssayVersionMessage("Essay loading interrupted. Please try again.")
            } else if (error.response && error.response.status === 401) {
                // Handle authentication error
                setNoEssayVersionMessage("You need a valid account for this service.");
            } else {
                if (process.env.NODE_ENV === 'development') {
                    console.error("Essay failed:", error);
                }
                setNoEssayVersionMessage("Error loading essay data. Please try again.")
            }
        }
    };

    // Version navigation handlers
    const handlePreviousVersion = () => {
    if (essayVersionIndex > 1 && (essayVersionIndex > essayVersionMax - 5)) {
        const newEssayIndex = essayVersionIndex - 1;
        setEssayVersionIndex(newEssayIndex);
        loadEssayVersion(newEssayIndex);
        setOptions({
            applyBrainstorming: false,
            applyEvaluation: false,
            finalizeEssay: false,
            source: 'newEssay'
        });
    }
    };

    const handleNextVersion = () => {
    if (essayVersionIndex < essayVersionMax) {
        const newEssayIndex = essayVersionIndex + 1;
        setEssayVersionIndex(newEssayIndex);
        loadEssayVersion(newEssayIndex);
        setOptions({
            applyBrainstorming: false,
            applyEvaluation: false,
            finalizeEssay: false,
            source: 'newEssay'
        });
    }
    };

    useEffect(() => {
        const storedPrompt = getSessionStorageItem("college_prompt");
        if (storedPrompt) {
            setCollegePrompt(storedPrompt);
        }
    }, []); // Run only once when the component mounts

    // Essay button handler
    const handleEssayWriting = async () => {
        setEssayErrorMessage("");
        setNoEssayVersionMessage("");

        // Validate input fields
        if (!collegePrompt) {
            setEssayErrorMessage(
                "Please provide the writing prompt."
            );
            return;
        }
        if (!wordLimit) {
            setEssayErrorMessage(
                "Please provide the word limit."
            );
            return;
        }
        // Use writingIdea directly if applyBrainstorming is selected
        const effectiveCollegeIdea = options.applyBrainstorming ? writingIdea : collegeIdea;
        if (!effectiveCollegeIdea) {
            setEssayErrorMessage(
                "Please provide your writing idea for the college application essay."
            );
            return;
        }
        setCollegeIdea(effectiveCollegeIdea);
        setEssayLoading(true); // Start essayLoading spinner

        // Initialize a new AbortController for the current request
        essayAbortController.current = new AbortController();
        const { signal } = essayAbortController.current;

        const rangeResults = wordRange(Number(wordLimit));

        try {
            const response = await axios.post(`${API_BASE_URL}/essay_writing`, {
                ai_account: aiAccount || "",
                college_prompt: collegePrompt || "",
                word_limit: Number(wordLimit) || 300,
                writing_idea: effectiveCollegeIdea || "",
                apply_brainstorming: options.applyBrainstorming || false,
                apply_evaluation: options.applyEvaluation || false,
                finalize_essay: options.finalizeEssay || false,
                writing_framework: writingFramework || "",
                draft_essay: draftEssay || "",
                evaluation_suggestion: essaySuggestion || "",
                expansion: rangeResults.expansion || "",
                contraction: rangeResults.contraction || "",
                l01: rangeResults.l01 || "",
                u01: rangeResults.u01 || "",
                token1: rangeResults.token1 || "",
                limit_lower: Number(rangeResults.limitLower) || 0,

            }, {withCredentials: true});

            options.applyBrainstorming && updateSessionData("college_idea", response.data.college_idea, setCollegeIdea)
            options.applyBrainstorming && updateSessionData("writing_framework", "", setWritingFramework);
            options.applyBrainstorming && updateSessionData("writing_idea", "", setWritingIdea);
            options.applyEvaluation && updateSessionData("evaluation_suggestion", "", setEvaluationSuggestion);
            response.data.draft_essay && updateSessionData("draft_essay", response.data.draft_essay, setDraftEssay);
            updateSessionData('writing_words', response.data.writing_words, setWritingWords)
            updateSessionData('words', response.data.words, setWords)
            updateSessionData("essayVersionIndex", Number(essayVersionMax)+1, setEssayVersionIndex)
            updateSessionData("essayVersionMax", Number(essayVersionMax)+1, setEssayVersionMax)

            setOriginalDraftEssay(response.data.draft_essay);

            setOptions({
                applyBrainstorming: false,
                applyEvaluation: false,
                finalizeEssay: false,
                source: 'newEssay'
            });
        } catch (error) {
            if (error.name === 'AbortError') {
                if (process.env.NODE_ENV === 'development') {
                    console.log("Essay request was aborted.");
                }
                setEssayErrorMessage("An error occurred while generating your essay. Please try again.")
            } else if (error.response && error.response.status === 401) {
                // Handle authentication error
                setEssayErrorMessage("You need a valid account for this service.");
            } else {
                if (process.env.NODE_ENV === 'development') {
                    console.error("Essay failed:", error);
                }
                setEssayErrorMessage("Error writing the essay. Please try again.")
            }
        } finally {
            setEssayLoading(false);
        }
    };

    // Helper function to detect if the user has edited the input
    const isEdited = (original, current) => original !== current;

    const handleWordLimitChange = (e) => {
        let value = e.target.value;
        setWordLimit(value);
    };

    // Add validation on blur (when the input loses focus)
    const validateWordLimit = () => {
        let value = parseInt(wordLimit);

        // Validate and adjust the value only if it's a number
        if (!isNaN(value)) {
            if (value < 20) {
                value = 20; // Set to minimum if below 20
            } else if (value > 1000) {
                value = 1000; // Set to maximum if above 1000
            }
            updateSessionData("word_limit", value, setWordLimit);
            const rangeResults = wordRange(Number(wordLimit));
            updateSessionData('limit_lower', rangeResults.limitLower, setLimitLower);
        }
    }

    // Restart/Stop button handler
    const handleRestartOrStop = () => {
        if (essayLoading) {
            // Abort the current essay request if it exists
            if (essayAbortController.current) {
                essayAbortController.current.abort();
                essayAbortController.current = null; // Reset after aborting
            }

            // Clear any loading states
            setEssayLoading(false);
        } else {
            // Reset writing fields if not essay
            setCollegeIdea("");
            setWritingFramework("");
            setDraftEssay("");
            setNoEssayVersionMessage("");
            setEssayErrorMessage("");
            removeSessionStorageItem("college_idea");
            removeSessionStorageItem("writing_framework");
            removeSessionStorageItem('draft_essay');
            setOptions({
                applyBrainstorming: false,
                applyEvaluation: false,
                finalizeEssay: false,
                source: "newEssay"
            });


        }
    };

    // for page presentation
    const [isExpanded, setIsExpanded] = useState(false);

    const togglePanel = () => {
    setIsExpanded(!isExpanded);
    };

    const [showDownloadOptions, setShowDownloadOptions] = useState(false);

    const handleEssayDownload = () => {
        setShowDownloadOptions(true);
    };

    // Effect to reset showDownloadOptions when draftEssay changes
    useEffect(() => {
        setShowDownloadOptions(false);
    }, [draftEssay]);

    const todayDate = new Date().toISOString().split('T')[0].replace(/-/g, '.');
    const defaultFileName = `Essay-${todayDate}`;

    return (
        <>
            <h1>Writing Application Essay</h1>
            {(!username || numericWritingLimit<numericTotalWords || expiration<currentDate) && <GreetingMessage />}
            <div style={{marginTop: "20px"}} className="accordion">
                <div onClick={togglePanel} className="accordion-header">
                    <span>{isExpanded ? 'College Application Essay: ' : 'College Application Essay: '}</span>
                </div>
                {isExpanded && (
                    <div className="accordion-content">
                        <p>
                            Many colleges require one or more essays in their applications. While they may seem
                            burdensome, these essays offer a valuable opportunity to showcase your unique
                            qualities, experiences, and aspirations, allowing admissions committees to better
                            understand your potential contributions to their academic community.
                        </p>
                    </div>
                )}
            </div>

            <div className="textarea-container">
                <div style={{ flex: 1 }}>
                    <h3 style={{ marginTop: "0px", marginBottom: "5px" }}>Writing Prompt</h3>
                    <textarea
                        value={collegePrompt}
                        title="Copy and paste the writing prompt from your college application here."
                        onChange={(e) => {
                            if (collegePrompt !== null && collegePrompt !== undefined) {
                                updateSessionData("college_prompt", e.target.value, setCollegePrompt);
                            }
                        }}
                        style={{
                            width: "100%",
                            height: "80px",
                            padding: "10px",
                            boxSizing: "border-box",
                            overflowWrap: "break-word", // Ensures text wraps within the box
                            resize: "none",             // Prevents resizing if undesired
                            verticalAlign: "top",        // Ensures text starts at the top
                            marginTop: '0px',
                            marginBottom: '25px',
                            fontSize: '0.8em',
                        }}
                    />
                </div>
                <div style={{ width: '6.5em', }}>
                    <h3 style={{ marginTop: "0px", marginBottom: "5px" }}>Word Limit</h3>
                    <input
                        type="number"
                        min="20"
                        max="1000"
                        value={wordLimit}
                        onChange={handleWordLimitChange}
                        onBlur={validateWordLimit} // Triggers validation when input loses focus
                        title="Enter an integer between 20 and 1000"
                        style={{ fontSize: '0.8em', width: "6.5em", textAlign: "center", height: "20px", marginTop: "0px", marginBottom: "25px" }} // Adjust width as needed
                    />
                </div>
            </div>

            <div className="textarea-container">
                {/* Conditionally render College Idea or replace it with Writing Idea */}
                <div className="textarea-item A">
                    <h3 style={{ marginTop: "0px", marginBottom: "5px" }}>Writing Idea</h3>
                    <textarea
                        ref={ideaRef}
                        title="Provide your writing idea here to kick off the writing process. Highlight your unique story, identity, experiences, or passions. Incorporate dialogue and personal reflections to engage and connect with readers."
                        style={{
                            width: "100%",
                            height: initialHeight,
                            padding: "10px",
                            boxSizing: "border-box",
                            overflowY: "auto",
                            overflowWrap: "break-word",
                            resize: "none",
                            marginBottom: '25px',
                            fontSize: '0.8em',
                            backgroundColor: isEdited(originalCollegeIdea, collegeIdea) ? "#fff5e6" : "white"
                        }}
                        value={(options.applyBrainstorming && writingIdea) ? writingIdea : collegeIdea || ""}
                        onChange={(e) => {
                            const text = e.target.value;
                            setCollegeIdea(text);
                            updateSessionData("college_idea", text, setCollegeIdea);

                            const height = isSmallScreen
                                ? newHeight(text, "", 100, 500)
                                : newHeight(text, writingFramework, 100, 800);
                            e.target.style.height = height;
                            if (!isSmallScreen && frameworkRef.current) {
                                frameworkRef.current.style.height = height;
                            }
                        }}
                        disabled={essayLoading}
                    />
                </div>

                {/* Conditionally render Writing Framework only if it has a value and being selected*/}
                {writingFramework && options.applyBrainstorming ? (
                    <div className="textarea-item B">
                        <h3 style={{ marginTop: "0px", marginBottom: "5px" }}>Generated Writing Framework:</h3>
                        <textarea
                            ref={frameworkRef}
                            style={{
                                width: "100%",
                                height: initialHeight,
                                padding: "10px",
                                boxSizing: "border-box",
                                overflowY: "auto",
                                overflowWrap: "break-word",
                                resize: "none",
                                marginBottom: '25px',
                                fontSize: '0.8em',
                                backgroundColor: isEdited(originalWritingFramework, writingFramework) ? "#fff5e6" : "white"
                            }}
                            value={writingFramework || ""}
                            onChange={(e) => {
                                const text = e.target.value;
                                setWritingFramework(text);
                                updateSessionData("writing_framework", text, setWritingFramework);

                                const height = isSmallScreen
                                    ? newHeight(text, "", 100, 500)
                                    : newHeight(writingIdea, text, 100, 800);
                                e.target.style.height = height;
                                if (!isSmallScreen && ideaRef.current) {
                                    ideaRef.current.style.height = height;
                                }
                            }}
                            disabled={essayLoading}
                        />
                    </div>
                ) : (
                    // Hidden placeholder to maintain `frameworkRef`
                    <textarea
                        ref={frameworkRef}
                        style={{ display: 'none' }}
                    />
                )}
            </div>

            <div className="textarea-container">
                <div className="textarea-item A">
                    <h3 style={{ marginTop: "0px", marginBottom: "5px" }}>Essay Writing Options</h3>
                    <EssayOptionsBox
                        collegePrompt={collegePrompt}
                        collegeIdea={collegeIdea}
                        writingIdea={writingIdea}
                        writingFramework={writingFramework}
                        evaluationSuggestion={evaluationSuggestion}
                        limitLower={limitLower}
                        wordLimit={wordLimit}
                        draftEssay={draftEssay}
                        options={options} // Pass the options state
                        setOptions={setOptions} // Pass the setOptions function
                        onOptionChange={handleOptionChange} // Pass the onOptionChange callback
                        loadEssayVersion={loadEssayVersion}
                    />
                    {options.source === 'fromMyComputer' && (
                        <FileUpload onFileLoad={handleFileLoad} />
                    )}
                </div>
                <div className="textarea-item A">
                    <h3 style={{ marginTop: "0px", marginBottom: "5px" }}>Evaluation Suggestions</h3>
                    <textarea
                        style={{
                            width: "100%",
                            height: newHeight(evaluationSuggestion || "", "", 155, 400),
                            padding: "10px",
                            boxSizing: "border-box",
                            border: "1px solid #ddd",
                            overflowY: "auto",
                            overflowWrap: "break-word",
                            resize: "none",
                            fontSize: '0.8em',
                            backgroundColor: "white"
                        }}
                        value={essaySuggestion || ""}
                        readOnly
                    />
                </div>
            </div>
            <div className="textarea-container">
                <div className="textarea-item A" style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "100%" }}>
                    <div style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }}>
                        {essayLoading ? ( // Show spinner when essayLoading
                            <div className="spinner essayWriting">
                                <div className="writing-spinner">
                                    <div className="pen">🖋️</div>
                                    <div className="trace"></div>
                                </div>
                            </div>
                        ) : (
                            <button
                                onClick={handleEssayWriting}
                                className="btn-broad-action"
                                style={{backgroundColor: "#2c3e50", border: "none", color: "#ffffff", marginTop: "35px"}}
                            >
                                {options.applyBrainstorming || !draftEssay ? "Write Essay" : "Revise Essay"}
                            </button>
                        )}
                    </div>
                </div>
                <div className="textarea-item A"
                    style={{
                        display: "flex",
                        flexDirection: "column", // Stack items vertically
                        justifyContent: "center", // Center vertically
                        alignItems: "center", // Center horizontally
                        height: "100%"
                    }}
                >
                    {noEssayVersionMessage && ( // Conditionally render the message
                        <p style={{ textAlign: "center", color: "red" }}>
                            {noEssayVersionMessage}
                        </p>
                    )}
                    {essayErrorMessage && (
                        <p style={{ textAlign: "center", color: "red" }}>
                            {essayErrorMessage}
                        </p>
                    )}
                </div>
                <div className="textarea-item A" style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "100%" }}>
                    <div style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", gap: "20px" }}>
                        <button
                            onClick={handleRestartOrStop}
                            onMouseEnter={(e) => {
                                const instruction = essayLoading
                                    ? "Stop the writing process."
                                    : "Start a new writing process, resetting all options to none.";
                                e.target.setAttribute("title", instruction);
                            }}
                            className="btn-broad-action" /* Apply the class here */
                            style={{marginTop: "35px"}}
                        >
                            {essayLoading ? "Stop" : "Restart"} {/* Conditional label rendering */}
                        </button>
                    </div>
                </div>
            </div>

            <div className="textarea-container">
                <div className="textarea-item A">
                    <h3 style={{marginTop: '25px', marginBottom: '5px'}}>Sample Essay</h3>
                    <textarea
                        ref={ideaRef}
                        style={{
                            width: "100%",
                            height: newHeight(draftEssay || "", "", 200, 1000),
                            padding: "10px",
                            boxSizing: "border-box",
                            overflowY: "auto",
                            overflowWrap: "break-word",
                            resize: "none",
                            fontSize: '0.8em',
                            backgroundColor: isEdited(originalDraftEssay, draftEssay) ? "#fff5e6" : "white"
                        }}
                        value={draftEssay || ""}
                        onChange={(e) => {
                            const text = e.target.value;
                            setDraftEssay(text);
                            updateSessionData("draft_essay", text, setDraftEssay);
                            e.target.style.height = newHeight(text, "", 200, 1000);
                        }}
                        disabled={essayLoading}
                    />
                </div>
            </div>

            {draftEssay && writingWords && words && (
                <div className="textarea-container">
                    <p style={{marginTop: '5px', marginBottom: '0px'}}>{writingWords} words in the draft essay. {words} words counted for usage. </p>
                </div>
            )}

            <div className="textarea-container">
                <div className="textarea-item A">
                    <div style={{ display: 'flex', justifyContent: 'center', marginTop: '20px', marginBottom: "20px" }}>
                        <button
                            onClick={handlePreviousVersion}
                            className="btn-record-action"
                            disabled={essayVersionIndex === 1 || essayVersionIndex <= essayVersionMax - 4}
                            style={{
                                cursor: (essayVersionIndex === 1 || essayVersionIndex <= essayVersionMax - 4) ? "not-allowed" : "pointer"
                            }}
                        >Previous Version</button>
                    </div>
                </div>
                <div className="textarea-item A">
                    <div style={{ display: 'flex', justifyContent: 'center', marginTop: '20px', marginBottom: "20px" }}>
                        {!showDownloadOptions ? (
                            <button
                                onClick={handleEssayDownload}
                                className="btn-record-action"
                                disabled={!draftEssay || draftEssay === ""}
                                style={{ cursor: (!draftEssay || draftEssay === "") ? "not-allowed" : "pointer" }}
                            >
                                Download the Essay
                            </button>
                        ) : (
                            <DownloadOptions writingPiece={draftEssay} defaultFileName={defaultFileName} />
                        )}
                    </div>
                </div>
                <div className="textarea-item A">
                    <div style={{ display: 'flex', justifyContent: 'center', marginTop: '20px', marginBottom: "20px" }}>
                        <button
                            onClick={handleNextVersion}
                            className="btn-record-action"
                            disabled={essayVersionIndex >= essayVersionMax}
                            style={{ cursor: essayVersionIndex >= essayVersionMax ? "not-allowed" : "pointer" }}
                        >Next Version</button>
                    </div>
                </div>
            </div>
        </>
    );
}

export default EssayWriting;
