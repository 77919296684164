import React from 'react';

const MembershipsExplanation = () => {
    return (
        <>
            <div className="textarea-container">
                <div className="textarea">
                    <h4 style={{marginTop: "20px", color: "#00239C"}}>We offer three distinct membership tiers to complement your writing journey: Basic, Silver, and Gold.</h4>
                    <p style={{marginLeft: "0pt", marginTop: '-15pt'}}>Each membership tier can be combined with any of our writing plans to suit your specific needs.</p>

                    <h3>Basic Membership (Free)</h3>
                    <p>Our Basic Membership is an excellent starting point, offering:</p>
                    <ul style={{marginLeft: "40pt"}}>
                        <li>Initial Writing Limit: Up to 3,000 words over a 15-day trial period.</li>
                        <li>Access Duration: The trial ends when either the word limit is reached or the 15-day period expires, whichever comes first.</li>
                        <li>Support Access: Unlimited chat support on the Help and Support page, active during the trial.</li>
                    </ul>

                    <h3>Silver Membership ($34.99)</h3>
                    <p>Our Silver Membership enhances your experience by adding significant features:</p>
                    <ul style={{marginLeft: "40pt"}}>
                        <li>Special Writing Account: Save drafts of up to 15 different essays, along with any evaluation reports, in your special writing account.</li>
                        <li>
                        Account Access: Retrieve and revise your saved essays anytime within the active duration of your writing plan. This feature is particularly useful
                        for managing multiple essays simultaneously—ideal if you are applying to several colleges or exploring various ideas.
                        </li>
                    </ul>

                    <h3>Gold Membership ($129.99)</h3>
                    <p>Opt for our exclusive Gold Membership for premium services and benefits:</p>
                    <ul style={{marginLeft: "40pt"}}>
                        <li>Expanded Storage: Save up to 50 essays and their evaluation reports.</li>
                        <li>Additional Discounts: Receive an extra 5% off on all purchases, stacking with other discounts.</li>
                        <li>Extended Access: Enjoy the benefits of your chosen writing plan’s limits and duration to the fullest extent.</li>
                    </ul>

                    <hr style={{ marginLeft: '40px' }}></hr>

                    <h4 style={{color: "#00239C"}}>All membership tiers are designed to provide flexibility and support throughout your writing journey.</h4>
                </div>
            </div>
        </>

    );
};

export default MembershipsExplanation;