// frontend/src/homepage.js
import React from 'react';
import '../../App.css';
import GreetingMessage from '../../components/greeting';

const AboutUs = () => {
    return (
        <>
            <header className="App-header">
                <h2>Welcome to SophoScriptor</h2>
                <h4>Your Personal Writing Assistant</h4>
                <p>
                    Spark <span className="highlighted-text">Creativity</span> and Discover Your <span className="highlighted-text">Authentic Self</span>
                </p>
            </header>
            <div className='Page-maincontent'>
                <GreetingMessage />
                <p><strong>Navigating the labyrinth of college applications can feel like a Herculean task. Between wrestling with transcripts, corralling recommendation letters, and rounding up evidence of your extracurricular triumphs—it's a marathon, not a sprint. But amidst this academic chaos, one challenge stands out as both daunting and pivotal: crafting those all-important college application essays.</strong></p>
                <p><strong>Each institution has its unique culture and penchant for essay topics, making a one-size-fits-all approach a literary faux pas. Your essay mustn't read like a generic script but rather a tailored narrative, proving your dedication to the specific college. And if drafting a single impeccable essay is an uphill climb, imagine scaling the peaks for ten, fifteen, or more institutions, each with distinct cultures and expectations.</strong></p>
                <p><strong>Understanding the complexities you face in these writing tasks, SophoScriptor is here to assist as your personal writing assistant. The app’s distinct features allow you to brainstorm and spark creativity, develop detailed writing frameworks, revise essays by emphasizing your personal experience and reflections, and evaluate your drafts.</strong></p>
                <p><strong>While we provide reference drafts to guide you, it's essential to use them with caution and ensure your final essay authentically represents your best qualities. This can only be achieved through multiple rounds of brainstorming and revisions—a process that SophoScriptor helps transform into a fun journey of discovering your self-stories.</strong></p>
                <p><strong>Consider SophoScriptor your reliable writing companion. Happy writing!</strong></p>
            </div>
        </>
    );
};

export default AboutUs;
