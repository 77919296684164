import React, { useState, useEffect, useRef } from "react";
import axios from 'axios';
import getAIAccount from '../../components/GetAIAccount';
import ProductsExplanation from '../../constants/ProductsExplanation';
import GreetingMessage from '../../components/greeting';


const aiAccount = getAIAccount();

function Products() {

    return (
        <>
            <h1 style={{marginBottom: "0px"}}>Writing Plans</h1>
            <GreetingMessage />
            <ProductsExplanation />
        </>
    );
}

export default Products;