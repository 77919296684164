//frontend/src/components/SignUpForm.js
import React, { useState, useEffect } from 'react';
import { setSessionStorageItem } from "./Mask";

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

function SignUpForm({ onTokenChange }) {
  const [formData, setFormData] = useState({
    email: '',
    username: '',
    password: '',
  });
  const [error, setError] = useState([]);  // You can now use this
  const [formErrors, setFormErrors] = useState({});  // To track client-side validation errors
  const [token, setToken] = useState(null);
  const [isSpinnerRunning, setIsSpinnerRunning] = useState(false);

  useEffect(() => {
    const userTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone || 'UTC';
    setFormData((prevData) => ({
      ...prevData,
      myTimezone: userTimezone
    }));
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
    setFormErrors((prevErrors) => ({ ...prevErrors, [name]: '' }));  // Clear field error on change
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError([]);  // Clear previous errors

    // Basic client-side validation
    const validationErrors = {};
    if (!formData.email) validationErrors.email = 'Email is required';
    if (!formData.username) validationErrors.username = 'Username is required';
    if (!formData.password) validationErrors.password = 'Password is required';
    if (!formData.signupTimezone && !formData.myTimezone) validationErrors.signupTimezone = 'Timezone is required';

    if (Object.keys(validationErrors).length > 0) {
      setFormErrors(validationErrors);
      return;  // Stop the form submission if there are validation errors
    }

    setIsSpinnerRunning(true);
    // Transform camelCase to snake_case and include timezone
    const transformedData = {
      email: formData.email,
      username: formData.username,
      password: formData.password,
      signup_timezone: formData.signupTimezone || formData.myTimezone,
    };

    console.log('Transformed Data:', transformedData);

    try {
      const response = await fetch(`${API_BASE_URL}/sign-up`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(transformedData), // Send transformed data
        credentials: "include"  // Required to include cookies in the request
      });

      if (!response.ok) {
        const data = await response.json();
        if (Array.isArray(data.detail)) {
          setError(data.detail);
          setIsSpinnerRunning(false);
        } else {
          setError([{ msg: data.detail }]);
          setIsSpinnerRunning(false);
        }
      } else {
        const data = await response.json()
        const newToken = data.session_token;
        setToken(newToken);  // Store the token in component state
        setSessionStorageItem("session_token", newToken);  // Save the token to sessionStorage
        setFormData({ email: '', username: '', password: '', signupTimezone: formData.myTimezone }); // Clear form data
        setIsSpinnerRunning(false);
        window.location.reload();
      }
    } catch (error) {
      setIsSpinnerRunning(false);
      if (process.env.NODE_ENV === 'development') {
        console.error('Error:', error);
      }
      setError([{ msg: 'Failed to register. Please try again later.' }]);
    }
  };

  return (
    <div>
      <h3>Sign Up</h3>
      <form onSubmit={handleSubmit}>
          <input
            type="email"
            name="email"
            placeholder="Email"
            required
            onChange={handleChange}
            title="Your email is the primary method for account communication."
            style={{
              padding: '8px 3px',
              width: '96%',
              borderRadius: '4px',
              border: '1px solid #ccc',
              fontSize: '12px',
              marginBottom: '10px'
            }}
          />
          {formErrors.email && <p style={{ color: 'red' }}>{formErrors.email}</p>}
          <input
            type="text"
            name="username"
            placeholder="Username"
            required
            onChange={handleChange}
            title="Choose a unique username to log in with."
            style={{
              padding: '8px 3px',
              width: '96%',
              borderRadius: '4px',
              border: '1px solid #ccc',
              fontSize: '12px',
              marginBottom: '10px'
            }}
          />
          {formErrors.username && <p style={{ color: 'red' }}>{formErrors.username}</p>}
          <input
            type="password"
            name="password"
            placeholder="Password"
            required
            onChange={handleChange}
            title="The password should have ten or more characters. Letters, digits, and special characters are allowed."
            style={{
              padding: '8px 3px',
              width: '96%',
              borderRadius: '4px',
              border: '1px solid #ccc',
              fontSize: '12px',
              marginBottom: '10px'
            }}
          />
          {formErrors.password && <p style={{ color: 'red' }}>{formErrors.password}</p>}


        {
            isSpinnerRunning ? (
                <div className="simpleSpinner"></div> // Spinner component or div
            ) : (
                <button
                  type="submit"
                  style={{
                    fontSize: '14px',
                    padding: '3px',
                    backgroundColor: '#2c3e50',
                    color: 'white',
                    border: '1px solid #ccc',
                    borderRadius: '4px',
                    cursor: 'pointer',
                  }}
                >
                  &nbsp;&nbsp;Submit&nbsp;&nbsp;
                </button>
            )
        }
      </form>

      {/* Display server-side errors */}
      {error.length > 0 && (
        <div style={{ color: 'red', marginTop: '10px' }}>
          {error.map((err, index) => (
            <p key={index}>{err.msg}</p>
          ))}
        </div>
      )}
    </div>
  );
}

export default SignUpForm;
