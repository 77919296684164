import React, { useState, useEffect, useRef } from "react";
import axios from 'axios';
import getAIAccount from '../../components/GetAIAccount';
import EvaluationOptionsBox from '../../components/EvaluationOptionsBox';
import { evaluationCriteria } from '../../components/Suggestions';
import FileUpload from "../../components/FileUpload";
import DownloadOptions from "../../components/DownloadOptions";
import GreetingMessage from '../../components/greeting';
import {
    getSessionStorageItem,
    updateSessionData,
    setSessionStorageItem,
    removeSessionStorageItem,
} from "../../components/Mask";

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

const aiAccount = getAIAccount();

function EssayEvaluation() {
    const [username, setUsername] = useState(getSessionStorageItem("username"));
    const [expirationDate, setExpirationDate] = useState(getSessionStorageItem("expiration_date"));
    const [writingLimit, setWritingLimit] = useState(getSessionStorageItem("writing_limit", 0));
    const [totalWords, setTotalWords] = useState(getSessionStorageItem("total_words", 0));
    const currentDate = new Date();
    const shortDate = expirationDate.substring(0, 10);
    const expiration = new Date(shortDate);
    const numericWritingLimit = Number(writingLimit);
    const numericTotalWords = Number(totalWords);

    const [collegePrompt, setCollegePrompt] = useState(getSessionStorageItem("college_prompt"));
    const [wordLimit, setWordLimit] = useState(getSessionStorageItem("word_limit", "300"));
    const [draftEssay, setDraftEssay] = useState(getSessionStorageItem("draft_essay"));
    const [essayToEvaluate, setEssayToEvaluate] = useState("");
    const [evaluationSuggestion, setEvaluationSuggestion] = useState(getSessionStorageItem('evaluation_suggestion'));
    const [evaluationDimension, setEvaluationDimension] = useState(getSessionStorageItem('evaluation_dimension'));
    const [newEvaluationDimension, setNewEvaluationDimension] = useState(false);
    const [evaluationReport, setEvaluationReport] = useState(getSessionStorageItem('evaluation_report'));
    const [originalEvaluationDimension, setOriginalEvaluationDimension] = useState("");
    const [evaluationDimensionVersionIndex, setEvaluationDimensionVersionIndex] = useState(null);
    const [evaluationDimensionVersionMax, setEvaluationDimensionVersionMax] = useState(null);
    const [evaluationReportVersionIndex, setEvaluationReportVersionIndex] = useState(null);
    const [evaluationReportVersionMax, setEvaluationReportVersionMax] = useState(null);
    const [evaluationLoading, setEvaluationLoading] = useState(false);
    const [isApplyButtonDisabled, setIsApplyButtonDisabled] = useState(false);
    const [isResetButtonDisabled, setIsResetButtonDisabled] = useState(true);
    const [evaluationWritingWords, setEvaluationWritingWords] = useState(getSessionStorageItem("evaluation_writing_words", ""));
    const [evaluationWords, setEvaluationWords] = useState(getSessionStorageItem("evaluation_words", ""));

    const [noReportVersionMessage, setNoReportVersionMessage] = useState("");
    const [noCriteriaVersionMessage, setNoCriteriaVersionMessage] = useState("");
    const [evaluationErrorMessage, setEvaluationErrorMessage] = useState("");

    const [options, setOptions] = useState({
        fromSampleWriting: false,
        fromMyComputer: false,
        useNewCriteria: true,
        useOldCriteria: false
    });

    useEffect(() => {
        const handleStorageChange = () => {
            // Update state when sessionStorage values change
            setEvaluationReport(getSessionStorageItem('evaluation_report'));
            setEvaluationDimension(getSessionStorageItem('evaluation_dimension'));
            setEvaluationSuggestion(getSessionStorageItem('evaluation_suggestion'));
            setEssayToEvaluate(getSessionStorageItem('essay_to_evaluate'));
        };

        // Add an event listener to detect changes in sessionStorage
        window.addEventListener('storage', handleStorageChange);

        // Cleanup the event listener on component unmount
        return () => {
            window.removeEventListener('storage', handleStorageChange);
        };
    }, []);

    const handleOptionChange = (newOptions) => {
        setOptions(newOptions);
        if (process.env.NODE_ENV === 'development') {
            console.log("Updated options:", newOptions); // Debugging line to check the new state
        }
    };

    // Reference to store the current AbortController
    const evaluationAbortController = useRef(null);

    const [initialHeight, setInitialHeight] = useState("80px");
    const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth < 768);
    const textRef = useRef(null);

    // Function to calculate height based on content
    const newHeight = (text, minHeight, maxHeight) => {
        const lineHeight = 25; // Adjust line height as needed
        const lines = text.split("\n").length;
        const calculatedHeight = Math.min(Math.max(25 + lines * lineHeight, minHeight), maxHeight);
        return `${calculatedHeight}px`;
    };

    useEffect(() => {
        if (options.fromSampleWriting) {updateSessionData("essay_to_evaluate", draftEssay, setEssayToEvaluate);}
    }, [options.fromSampleWriting, draftEssay]);

    useEffect(() => {
        if (!options.fromSampleWriting && !options.fromMyComputer) {updateSessionData("essay_to_evaluate", "", setEssayToEvaluate);}
    }, [options.fromSampleWriting, options.fromMyComputer]);

    useEffect(() => {
        if (options.useNewCriteria) {
            updateSessionData("evaluation_dimension", "", setEvaluationDimension);
            updateSessionData("evaluationDimensionVersionIndex", "", setEvaluationDimensionVersionIndex);
            updateSessionData("evaluationDimensionVersionMax", "", setEvaluationDimensionVersionMax);
        }
    }, [options.useNewCriteria]);

    useEffect(() => {
        if (options.useOldCriteria) {loadEvaluationDimensionVersion();}
    }, [options.useOldCriteria]);

    const handleFileLoad = (fileContent) => {
        updateSessionData("essay_to_evaluate", fileContent, setEssayToEvaluate);
    };

    // Sync evaluationDimension and evaluationReport VersionIndex and VersionMax with sessionStorage whenever they change
    useEffect(() => {
        if (evaluationDimensionVersionIndex !== null) {
            setSessionStorageItem("evaluationDimensionVersionIndex", evaluationDimensionVersionIndex);
        }
        if (evaluationDimensionVersionMax !== null) {
            setSessionStorageItem("evaluationDimensionVersionMax", evaluationDimensionVersionMax);
        }
    }, [evaluationDimensionVersionIndex, evaluationDimensionVersionMax]);

    useEffect(() => {
        if (evaluationReportVersionIndex !== null) {
            setSessionStorageItem("evaluationReportVersionIndex", evaluationReportVersionIndex);
        }
        if (evaluationReportVersionMax !== null) {
            setSessionStorageItem("evaluationReportVersionMax", evaluationReportVersionMax);
        }
    }, [evaluationReportVersionIndex, evaluationReportVersionMax]);

    // Load a specific evaluation report version from the backend, or latest if evaluationReportVersionIndex is null
    const loadEvaluationReportVersion = async (version = null) => {
        try {
            setNoReportVersionMessage(""); // clear any previous message
            setEvaluationErrorMessage("");
            const params = version !== null && version !== undefined ? { version_number: version } : {};
            const response = await axios.get(`${API_BASE_URL}/get_evaluation_report_session_data`, {
                withCredentials: true, // Include cookies with the request
                params
            });

            const versionData = response.data;

            if (!versionData || versionData.evaluation_report_version === null || versionData.evaluation_report_version === "") {
                // Set message if no version data is available
                setNoReportVersionMessage("No evaluation report found. Start a new evaluation process.");
            } else {
                updateSessionData("college_prompt", versionData.college_prompt, setCollegePrompt);
                updateSessionData("word_limit", versionData.word_limit, setWordLimit);
                updateSessionData("essay_to_evaluate", versionData.essay_to_evaluate, setEssayToEvaluate);
                updateSessionData("evaluation_suggestion", versionData.evaluation_suggestion, setEvaluationSuggestion);
                updateSessionData("evaluation_report", versionData.evaluation_report, setEvaluationReport);
                updateSessionData("evaluation_writing_words", versionData.writing_words, setEvaluationWritingWords);
                updateSessionData("evaluation_words", versionData.words, setEvaluationWords);

                // Set version indices based on condition
                if (evaluationReportVersionIndex === null ) {
                    updateSessionData("evaluationReportVersionIndex", versionData.evaluation_report_version, setEvaluationReportVersionIndex);
                    updateSessionData("evaluationReportVersionMax", versionData.evaluation_report_version, setEvaluationReportVersionMax);
                } else {
                    updateSessionData("evaluationReportVersionIndex", versionData.evaluation_report_version, setEvaluationReportVersionIndex);
                }
            }
        } catch (error) {
            if (error.name === 'AbortError') {
                if (process.env.NODE_ENV === 'development') {
                    console.log("Essay request was aborted.");
                }
                setNoReportVersionMessage("Loading interrupted. Please try again.")
            } else if (error.response && error.response.status === 401) {
                // Handle authentication error
                setNoReportVersionMessage("You need a valid account for this service.");
            } else {
                if (process.env.NODE_ENV === 'development') {
                    console.error("Essay failed:", error);
                }
                setNoReportVersionMessage("Error loading evaluation data. Please try again.")
            }
        }
    };

    // Report version navigation handlers
    const handleReportPreviousVersion = () => {
        if (evaluationReportVersionIndex > 1 && (evaluationReportVersionIndex > evaluationReportVersionMax - 5)) {
            const newEvaluationReportIndex = evaluationReportVersionIndex - 1;
            setEvaluationReportVersionIndex(newEvaluationReportIndex);
            loadEvaluationReportVersion(newEvaluationReportIndex);
        }
    };

    const handleReportNextVersion = () => {
        if (evaluationReportVersionIndex < evaluationReportVersionMax) {
            const newEvaluationReportIndex = evaluationReportVersionIndex + 1;
            setEvaluationReportVersionIndex(newEvaluationReportIndex);
            loadEvaluationReportVersion(newEvaluationReportIndex);
        }
    };

    // Load a specific evaluation dimension version from the backend, or latest if evaluationDimensionVersionIndex is null
    const loadEvaluationDimensionVersion = async (version = null) => {
        try {
            setNoCriteriaVersionMessage(""); // clear any previous message

            const params = (version !== null && version !== undefined) ? { version_number: version } : undefined;
            const response = await axios.get(`${API_BASE_URL}/get_evaluation_dimension_session_data`, {
                withCredentials: true, // Include cookies with the request
                params
            });

            const versionData = response.data;

            if (!versionData || versionData.evaluation_dimension_version === null || versionData.evaluation_dimension_version === "") {
                // Set message if no version data is available
                setNoCriteriaVersionMessage("No evaluation criteria found. Please start a new evaluation process.");
            } else {
                updateSessionData("evaluation_dimension", versionData.evaluation_dimension, setEvaluationDimension);

                // Set version indices based on condition
                if (evaluationDimensionVersionIndex === null || evaluationDimensionVersionIndex === "") {
                    updateSessionData("evaluationDimensionVersionIndex", versionData.evaluation_dimension_version, setEvaluationDimensionVersionIndex);
                    updateSessionData("evaluationDimensionVersionMax", versionData.evaluation_dimension_version, setEvaluationDimensionVersionMax);
                } else {
                    updateSessionData("evaluationDimensionVersionIndex", versionData.evaluation_dimension_version, setEvaluationDimensionVersionIndex);
                }
            }
        } catch (error) {
            if (process.env.NODE_ENV === 'development') {
                console.error(`Error loading version ${version !== undefined ? version : 'undefined'}:`, error);
            }
            setNoCriteriaVersionMessage("Error loading evaluation criteria data. Please try again.");
        }
    };

    // Version navigation handlers
    const handleDimensionPreviousVersion = () => {
    if (evaluationDimensionVersionIndex > 1 && (evaluationDimensionVersionIndex > evaluationDimensionVersionMax - 5)) {
        const newEvaluationDimensionIndex = evaluationDimensionVersionIndex - 1;
        setEvaluationDimensionVersionIndex(newEvaluationDimensionIndex);
        loadEvaluationDimensionVersion(newEvaluationDimensionIndex);
    }
    };

    const handleDimensionNextVersion = () => {
    if (evaluationDimensionVersionIndex < evaluationDimensionVersionMax) {
        const newEvaluationDimensionIndex = evaluationDimensionVersionIndex + 1;
        setEvaluationDimensionVersionIndex(newEvaluationDimensionIndex);
        loadEvaluationDimensionVersion(newEvaluationDimensionIndex);
    }
    };

    useEffect(() => {
        const storedPrompt = getSessionStorageItem("college_prompt");
        if (storedPrompt) {
            setCollegePrompt(storedPrompt);
        }
    }, []); // Run only once when the component mounts

    // Evaluation button handler
    const handleEvaluation = async () => {
        setEvaluationErrorMessage("");
        setNoReportVersionMessage("");

        if (!collegePrompt) {
            setEvaluationErrorMessage(
                "Please provide the writing prompt."
            );
            return;
        }
        if (!wordLimit) {
            setEvaluationErrorMessage(
                "Please provide the word limit."
            );
            return;
        }
        if (!essayToEvaluate) {
            setEvaluationErrorMessage(
                "Please provide the essay to evaluate."
            );
            return;
        }

        setEvaluationLoading(true); // Start evaluationLoading spinner

        // Initialize a new AbortController for the current request
        evaluationAbortController.current = new AbortController();
        const { signal } = evaluationAbortController.current;

        try {
            const response = await axios.post(`${API_BASE_URL}/essay_evaluation`, {
                ai_account: aiAccount || "",
                college_prompt: collegePrompt || "",
                word_limit: Number(wordLimit) || 300,
                essay_to_evaluate: essayToEvaluate || "",
                evaluation_dimension: evaluationDimension || "",
                new_evaluation_dimension: newEvaluationDimension || false,
            }, {withCredentials: true});

            updateSessionData('evaluation_dimension', response.data.evaluation_dimension, setEvaluationDimension);
            setOriginalEvaluationDimension(response.data.evaluation_dimension);
            updateSessionData('evaluation_report', response.data.evaluation_report, setEvaluationReport);
            updateSessionData('evaluation_suggestion', response.data.evaluation_suggestion, setEvaluationSuggestion);
            updateSessionData('evaluation_writing_words', response.data.writing_words, setEvaluationWritingWords);
            updateSessionData('evaluation_words', response.data.words, setEvaluationWords);
            updateSessionData("evaluationReportVersionIndex", Number(evaluationReportVersionMax)+1, setEvaluationReportVersionIndex);
            updateSessionData("evaluationReportVersionMax", Number(evaluationReportVersionMax)+1, setEvaluationReportVersionMax);
            setOptions(prevOptions => ({
                ...prevOptions, // Spread the previous state to keep other properties unchanged
                useNewCriteria: true,
                useOldCriteria: false
            }));
            if (process.env.NODE_ENV === 'development') {
                console.log(options)
            }

        } catch (error) {
            if (error.name === 'AbortError') {
                if (process.env.NODE_ENV === 'development') {
                    console.log("Evaluation request was aborted.");
                }
                setEvaluationErrorMessage("An error occurred while evaluating your essay. Please try again.")
            } else if (error.response && error.response.status === 401) {
                // Handle authentication error
                setEvaluationErrorMessage("You need a valid account for this service.");
            } else {
                if (process.env.NODE_ENV === 'development') {
                    console.error("Evaluation failed:", error);
                }
                setEvaluationErrorMessage("Error evaluating the essay. Please try again.")
            }
        } finally {
            setEvaluationLoading(false);
        }
    };

    // Helper function to detect if the user has edited the input
    const isEdited = (original, current) => original !== current;

    const handleWordLimitChange = (e) => {
        let value = e.target.value;
        setWordLimit(value);
    };

    // Add validation on blur (when the input loses focus)
    const validateWordLimit = () => {
        let value = parseInt(wordLimit);

        // Validate and adjust the value only if it's a number
        if (!isNaN(value)) {
            if (value < 20) {
                value = 20; // Set to minimum if below 20
            } else if (value > 1000) {
                value = 1000; // Set to maximum if above 1000
            }
            updateSessionData("word_limit", value, setWordLimit);
        }
    }

    // Restart/Stop button handler
    const handleRestartOrStop = () => {
        if (evaluationLoading) {
            // Abort the current essay request if it exists
            if (evaluationAbortController.current) {
                evaluationAbortController.current.abort();
                evaluationAbortController.current = null; // Reset after aborting
            }

            // Clear any loading states
            setEvaluationLoading(false);
        } else {
            // Reset evaluation
            setEssayToEvaluate("");
            setEvaluationDimension("");
            setEvaluationReport("");
            setEvaluationErrorMessage("");
            setNoReportVersionMessage("");
            setOptions({
                fromSampleWriting: false,
                fromMyComputer: false,
                useNewCriteria: true,
                useOldCriteria: false
            });
            removeSessionStorageItem("essay_to_evaluate");
            removeSessionStorageItem("evaluation_dimension");
            removeSessionStorageItem('evaluation_report');
        }
    };

    // for page presentation
    const [isExpanded, setIsExpanded] = useState(false);

    const togglePanel = () => {
    setIsExpanded(!isExpanded);
    };

    const [showDownloadOptions, setShowDownloadOptions] = useState(false);

    const handleEvaluationDownload = () => {
        setShowDownloadOptions(true);
    };

    // Effect to reset showDownloadOptions when draftEssay changes
    useEffect(() => {
        setShowDownloadOptions(false);
    }, [evaluationReport]);

    const todayDate = new Date().toISOString().split('T')[0].replace(/-/g, '.');
    const defaultFileName = `Evaluation Report-${todayDate}`;

    return (
        <>
            <h1>Evaluating Application Essay</h1>
            {(!username || numericWritingLimit<numericTotalWords || expiration<currentDate) && <GreetingMessage />}
            <div style={{marginTop: "20px"}} className="accordion">
                <div onClick={togglePanel} className="accordion-header">
                    <span>{isExpanded ? 'Essay Evaluation: ' : 'Essay Evaluation: '}</span>
                </div>
                {isExpanded && (
                    <div className="accordion-content">
                        <p style={{marginTop: "0px", marginBottom:"10px"}}>
                            This evaluation is provided for your reference to help enhance your essay. It is your
                            decision whether to accept and implement its suggestions.
                        </p>
                    </div>
                )}
            </div>

            <div className="textarea-container">
                <div style={{ flex: 1 }}>
                    <h3 style={{ marginTop: "0px", marginBottom: "5px" }}>Writing Prompt</h3>
                    <textarea
                        value={collegePrompt}
                        title="Copy and paste the writing prompt from your college application here."
                        onChange={(e) => {
                            if (collegePrompt !== null && collegePrompt !== undefined) {
                                updateSessionData("college_prompt", e.target.value, setCollegePrompt);
                            }
                        }}
                        style={{
                            width: "100%",
                            height: "80px",
                            padding: "10px",
                            boxSizing: "border-box",
                            overflowWrap: "break-word", // Ensures text wraps within the box
                            resize: "none",             // Prevents resizing if undesired
                            verticalAlign: "top",        // Ensures text starts at the top
                            marginTop: '0px',
                            marginBottom: '25px',
                            fontSize: '0.8em',
                        }}
                    />
                </div>
                <div style={{ width: '6.5em', }}>
                    <h3 style={{ marginTop: "0px", marginBottom: "5px" }}>Word Limit</h3>
                    <input
                        type="number"
                        min="20"
                        max="1000"
                        value={wordLimit}
                        onChange={handleWordLimitChange}
                        onBlur={validateWordLimit} // Triggers validation when input loses focus
                        title="Enter an integer between 20 and 1000"
                        style={{ fontSize: '0.8em', width: "6.5em", textAlign: "center", height: "20px", marginTop: "0px", marginBottom: '25px' }} // Adjust width as needed
                    />
                </div>
            </div>

            <div className="textarea-container">
                <div className="textarea-item A">
                    <h3 style={{ marginTop: "0px", marginBottom: "5px" }}>Essay to Evaluate</h3>
                    <textarea
                        style={{
                            width: "100%",
                            height: newHeight(essayToEvaluate || "", 200, 1000),
                            padding: "10px",
                            boxSizing: "border-box",
                            overflowY: "auto",
                            overflowWrap: "break-word",
                            resize: "none",
                            backgroundColor: "white",
                            marginBottom: '25px',
                            fontSize: '0.8em',
                        }}
                        value={essayToEvaluate || ""}
                        readOnly // This makes the textarea read-only
                        disabled={evaluationLoading}
                    />
                </div>
            </div>

            <div className="accordion">
                <div onClick={togglePanel} className="accordion-header">
                    <span>{isExpanded ? 'A college application essay is often examined on the following criteria:' : 'Evaluation Criteria:'}</span>
                </div>
                {isExpanded && (
                    <div
                        className="accordion-content"
                        dangerouslySetInnerHTML={{ __html: evaluationCriteria }}
                    ></div>
                )}
            </div>

            <div className="textarea-container">
                <div className="textarea-item A">
                    <h3 style={{ marginTop: "0px", marginBottom: "5px" }}>Essay Evaluation Options</h3>
                    <EvaluationOptionsBox options={options} setOptions={setOptions} onOptionChange={handleOptionChange} />
                    {options.fromMyComputer && (
                        <FileUpload onFileLoad={handleFileLoad} />
                    )}
                </div>
                <div className="textarea-item A">
                    <h3 style={{ marginTop: "0px", marginBottom: "5px" }}>Evaluation Criteria</h3>
                    <textarea
                        style={{
                            width: "100%",
                            height: "273px",
                            padding: "10px",
                            boxSizing: "border-box",
                            overflowY: "auto",
                            overflowWrap: "break-word",
                            resize: "none",
                            backgroundColor: "white",
                            marginTop: "0px",
                            fontSize: '0.8em',
                            border: "1px solid #ddd"
                        }}
                        value={evaluationDimension}
                        title={
                            evaluationDimension
                                ? 'The evaluation will proceed using the specified criteria. You can edit them here if needed.'
                                : 'If left empty, the evaluation will generate a new set of customized criteria tailored to your essay.'
                        }
                        onChange={(e) => {
                            if (evaluationDimension !== null && evaluationDimension !== undefined) {
                                updateSessionData("evaluation_dimension", e.target.value, setEvaluationDimension);
                                setNewEvaluationDimension(true);
                            }
                        }}
                        // readOnly // This makes the textarea read-only
                        disabled={evaluationLoading}
                    />

                    {evaluationDimension && (
                        <div style={{ display: "flex", justifyContent: "space-between", marginTop: "10px" }}>
                            <button
                                onClick={handleDimensionPreviousVersion}
                                disabled={evaluationDimensionVersionIndex === 1 || evaluationDimensionVersionIndex === evaluationDimensionVersionMax - 5}
                                style={{ padding: "2px 12px", cursor: evaluationDimensionVersionIndex === 1 ? "not-allowed" : "pointer" }}
                            >
                                Previous Version
                            </button>
                            <button
                                onClick={handleDimensionNextVersion}
                                disabled={evaluationDimensionVersionIndex === evaluationDimensionVersionMax}
                                style={{ padding: "2px 12px", cursor: evaluationDimensionVersionIndex === evaluationDimensionVersionMax ? "not-allowed" : "pointer" }}
                            >
                                Next Version
                            </button>
                        </div>
                    )}
                    {options.useOldCriteria && noCriteriaVersionMessage && (
                        <div style={{ textAlign: 'center', marginTop: '10px', color: 'red' }}>
                            {noCriteriaVersionMessage}
                        </div>
                    )}
                </div>
            </div>

            <div className="textarea-container">
                <div className="textarea-item A" style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "100%" }}>
                    <div style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", gap: "20px" }}>
                        {evaluationLoading ? ( // Show spinner when evaluation in process
                            <div class="spinner-container">
                                <div class="laptop-emoji">💻</div>
                                <div class="pen-emoji">🖊️</div>
                            </div>
                        ) : (
                            <button
                                onClick={handleEvaluation}
                                className="btn-broad-action"
                                style={{backgroundColor: "#2c3e50", border: "none", color: "#ffffff", marginTop: "35px"}}
                                title="Evaluation requires writing prompt, word limit, and essay to evaluate."
                            >
                                Evaluating Essay
                            </button>
                        )}
                    </div>
                </div>

                <div className="textarea-item A" style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "100%" }}>
                    <div style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", marginLeft: "20px", gap: "20px" }}>
                        <button
                            onClick={() => loadEvaluationReportVersion()} // Call without arguments for the latest version
                            className="btn-broad-action"
                            style={{marginTop: "35px"}}
                            disabled = {noReportVersionMessage}
                            title="Click to load the latest evaluation report from the database."
                        >
                            Load the Last Evaluation
                        </button>
                        {noReportVersionMessage && ( // Conditionally render the message
                            <div style={{ textAlign: 'center', marginTop: '0px', marginBottom: '-20px', color: 'red' }}>
                                {noReportVersionMessage}
                            </div>
                        )}
                        {evaluationErrorMessage && ( // Conditionally render the message
                            <div style={{ textAlign: 'center', marginTop: '-20px', marginBottom: '-20px', color: 'red' }}>
                                {evaluationErrorMessage}
                            </div>
                        )}
                    </div>
                </div>


                <div className="textarea-item A" style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "100%" }}>
                    <div style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", gap: "20px" }}>
                        <button
                            onClick={handleRestartOrStop}
                            className="btn-broad-action"
                            style={{marginTop: "35px"}}
                        >
                            {evaluationLoading ? "Stop" : "Restart"}
                        </button>
                    </div>
                </div>
            </div>

            <div className="textarea-container">
                <div className="textarea-item A">
                    <h3 style={{ marginTop: "15px", marginBottom: "5px" }}>Evaluation Report</h3>
                    <textarea
                        style={{
                            width: "100%",
                            height: newHeight(draftEssay || "", 200, 1000),
                            padding: "10px",
                            boxSizing: "border-box",
                            overflowY: "auto",
                            overflowWrap: "break-word",
                            resize: "none",
                            fontSize: '0.8em',
                            backgroundColor: "white"
                        }}
                        value={evaluationReport || ""}
                        readOnly // This makes the textarea read-only
                        disabled={evaluationLoading}
                    />
                </div>
            </div>

            {evaluationReport && evaluationWritingWords && evaluationWords && (
                <div className="textarea-container">
                    <p style={{marginTop: '5px', marginBottom: '0px'}}>{evaluationWritingWords} words in the evaluation report. {evaluationWords} words counted for usage. </p>
                </div>
            )}

            <div className="textarea-container">
                <div className="textarea-item A">
                    <div style={{ display: 'flex', justifyContent: 'center', marginTop: '20px', marginBottom: "20px" }}>
                        <button
                            onClick={handleReportPreviousVersion}
                            className="btn-record-action"
                            disabled={evaluationReportVersionIndex === 1 || evaluationReportVersionIndex <= evaluationReportVersionMax - 4}
                            style={{ cursor: (evaluationReportVersionIndex === 1 || evaluationReportVersionIndex <= evaluationReportVersionMax - 4) ? "not-allowed" : "pointer" }}
                        >Previous Version</button>
                    </div>
                </div>
                <div className="textarea-item A">
                    <div style={{ display: 'flex', justifyContent: 'center', marginTop: '20px', marginBottom: "20px" }}>
                        {!showDownloadOptions ? (
                            <button
                                onClick={handleEvaluationDownload}
                                className="btn-record-action"
                                disabled={!evaluationReport || evaluationReport === ""}
                                style={{
                                    cursor: (!evaluationReport || evaluationReport === "") ? "not-allowed" : "pointer"
                                }}
                            >
                                Download the Evaluation Report
                            </button>
                        ) : (
                            <DownloadOptions writingPiece={evaluationReport} defaultFileName={defaultFileName} />
                        )}
                    </div>
                </div>
                <div className="textarea-item A">
                    <div style={{ display: 'flex', justifyContent: 'center', marginTop: '20px', marginBottom: "20px" }}>
                        <button
                            onClick={handleReportNextVersion}
                            className="btn-record-action"
                            disabled={evaluationReportVersionIndex >= evaluationReportVersionMax}
                            style={{ cursor: evaluationReportVersionIndex >= evaluationReportVersionMax ? "not-allowed" : "pointer" }}
                        >Next Version</button>
                    </div>
                </div>
            </div>
        </>
    );
}

export default EssayEvaluation;

