import React, { useState, useEffect, useRef } from "react";
import Select from 'react-select';
import axios from 'axios';
import getAIAccount from '../../components/GetAIAccount';
import FileUpload from "../../components/FileUpload";
import DownloadOptions from "../../components/DownloadOptions";
import EmailSuggestions from '../../constants/EmailSuggestionConstant';
import GreetingMessage from '../../components/greeting';
import {
    getSessionStorageItem,
    updateSessionData,
    setSessionStorageItem,
    removeSessionStorageItem,
} from "../../components/Mask";

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

const aiAccount = getAIAccount();

function EmailWriting() {
    const [username, setUsername] = useState(getSessionStorageItem("username"));
    const [expirationDate, setExpirationDate] = useState(getSessionStorageItem("expiration_date"));
    const [writingLimit, setWritingLimit] = useState(getSessionStorageItem("writing_limit", 0));
    const [totalWords, setTotalWords] = useState(getSessionStorageItem("total_words", 0));
    const currentDate = new Date();
    const shortDate = expirationDate.substring(0, 10);
    const expiration = new Date(shortDate);
    const numericWritingLimit = Number(writingLimit);
    const numericTotalWords = Number(totalWords);

    const [emailRequirement, setEmailRequirement] = useState(getSessionStorageItem("email_requirement") || "");
    const [emailTone, setEmailTone] = useState(getSessionStorageItem("email_tone") || []);
    const [draftEmail, setDraftEmail] = useState(getSessionStorageItem("draft_email") || "");
    const [originalDraftEmail, setOriginalDraftEmail] = useState("");
    const [emailVersionIndex, setEmailVersionIndex] = useState(null);
    const [emailVersionMax, setEmailVersionMax] = useState(null);
    const [emailLoading, setEmailLoading] = useState(false);
    const [isApplyButtonDisabled, setIsApplyButtonDisabled] = useState(false);
    const [isResetButtonDisabled, setIsResetButtonDisabled] = useState(true);
    const [emailWritingWords, setEmailWritingWords] = useState(getSessionStorageItem("email_writing_words", ""));
    const [emailWords, setEmailWords] = useState(getSessionStorageItem("email_words", ""));
    const [emailErrorMessage, setEmailErrorMessage] = useState("");

    const [noEmailVersionMessage, setNoEmailVersionMessage] = useState("");

    useEffect(() => {
        const handleStorageChange = () => {
            // Update state when sessionStorage values change
            setEmailRequirement(getSessionStorageItem('email_requirement'));
            setEmailTone(getSessionStorageItem('email_tone'));
        };

        // Add an event listener to detect changes in sessionStorage
        window.addEventListener('storage', handleStorageChange);

        // Cleanup the event listener on component unmount
        return () => {
            window.removeEventListener('storage', handleStorageChange);
        };
    }, []);

    // Reference to store the current AbortController
    const emailAbortController = useRef(null);

    const [initialHeight, setInitialHeight] = useState("80px");
    const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth < 768);
    const ideaRef = useRef(null);
    const frameworkRef = useRef(null);
    const draftRef = useRef(null);

    // Function to calculate height based on content
    const newHeight = (text, pairedText, minHeight, maxHeight) => {
        const lineHeight = 25; // Adjust line height as needed
        const lines = Math.max(
            text.split("\n").length,
            pairedText ? pairedText.split("\n").length : 0
        );
        const calculatedHeight = Math.min(Math.max(25 + lines * lineHeight, minHeight), maxHeight);
        return `${calculatedHeight}px`;
    };

    useEffect(() => {
        const handleResize = () => {
            const isSmallScreen = window.innerWidth < 768; // Move this calculation inside the function

            if (ideaRef.current && draftRef.current) {
                if (isSmallScreen) {
                    // Adjust heights independently for small screens
                    ideaRef.current.style.height = newHeight(emailRequirement || "", "", 100, 500);
                    draftRef.current.style.height = newHeight(draftEmail || "", "", 200, 1000);
                } else {
                    // Adjust heights independently
                    ideaRef.current.style.height = newHeight(emailRequirement || "", "", 100, 800);
                    draftRef.current.style.height = newHeight(draftEmail || "", "", 200, 1000);
                }
            }
        };

        // Initial height adjustment
        handleResize();

        // Listen for window resize
        window.addEventListener("resize", handleResize);
        return () => window.removeEventListener("resize", handleResize);
    }, [emailRequirement, draftEmail]); // Removed isSmallScreen from dependencies


    const handleFileLoad = (fileContent) => {
        updateSessionData("draft_email", fileContent, setDraftEmail);
    };

    // Sync emailVersionIndex and emailVersionMax with sessionStorage whenever they change
    useEffect(() => {
        if (emailVersionIndex !== null) {
            setSessionStorageItem("emailVersionIndex", emailVersionIndex);
        }
        if (emailVersionMax !== null) {
            setSessionStorageItem("emailVersionMax", emailVersionMax);
        }
    }, [emailVersionIndex, emailVersionMax]);

    // Load a specific email version from the backend, or latest if emailVersionIndex is null
    const loadEmailVersion = async (version = null) => {
        try {
            setNoEmailVersionMessage(""); // clear any previous message
            setEmailErrorMessage("");

            const params = version !== null && version !== undefined ? { version_number: version } : {};
            const response = await axios.get(`${API_BASE_URL}/get_email_session_data`, {
                withCredentials: true, // Include cookies with the request
                params
            });

            const versionData = response.data;

            if (!versionData || versionData.email_version === null || versionData.email_version === "") {
                // Set message if no version data is available
                setNoEmailVersionMessage("No email found. Start a new email writing process.");
            } else {
                updateSessionData("draft_email", versionData.draft_email, setDraftEmail);
                updateSessionData("email_requirement", versionData.email_requirement, setEmailRequirement);
                updateSessionData("email_tone", versionData.email_tone, setEmailTone);
                updateSessionData("email_writing_words", versionData.writing_words, setEmailWritingWords)
                updateSessionData("email_words", versionData.words, setEmailWords)

                // Set version indices based on condition
                if (emailVersionIndex === null) {
                    updateSessionData("emailVersionIndex", versionData.email_version, setEmailVersionIndex);
                    updateSessionData("emailVersionMax", versionData.email_version, setEmailVersionMax);
                } else {
                    updateSessionData("emailVersionIndex", versionData.email_version, setEmailVersionIndex);
                }
            }
            return versionData;
        } catch (error) {
            if (error.name === 'AbortError') {
                if (process.env.NODE_ENV === 'development') {
                    console.log("Essay request was aborted.");
                }
                setNoEmailVersionMessage("Loading interrupted. Please try again.")
            } else if (error.response && error.response.status === 401) {
                // Handle authentication error
                setNoEmailVersionMessage("You need a valid account for this service.");
            } else {
                if (process.env.NODE_ENV === 'development') {
                    console.error("Essay failed:", error);
                }
                setNoEmailVersionMessage("Error loading email data. Please try again.")
            }
        }
    };

    // Version navigation handlers
    const handlePreviousVersion = () => {
        if (emailVersionIndex > 1 && (emailVersionIndex > emailVersionMax - 5)) {
            const newEmailIndex = emailVersionIndex - 1;
            setEmailVersionIndex(newEmailIndex);
            loadEmailVersion(newEmailIndex);
        }
    };

    const handleNextVersion = () => {
        if (emailVersionIndex < emailVersionMax) {
            const newEmailIndex = emailVersionIndex + 1;
            setEmailVersionIndex(newEmailIndex);
            loadEmailVersion(newEmailIndex);
        }
    };

    useEffect(() => {
        const storedEmailRequirement = getSessionStorageItem("email_requirement");
        if (storedEmailRequirement) {
            setEmailRequirement(storedEmailRequirement);
        }
    }, []); // Run only once when the component mounts

    // Email button handler
    const handleEmailWriting = async () => {
        setEmailErrorMessage("");
        setNoEmailVersionMessage("");
        setEmailLoading(true); // Start emailLoading spinner

        // Initialize a new AbortController for the current request
        emailAbortController.current = new AbortController();
        const { signal } = emailAbortController.current;

        try {
            const response = await axios.post(`${API_BASE_URL}/email_writing`, {
                ai_account: aiAccount || "",
                email_requirement: emailRequirement || "",
                email_tone: emailTone || "",
                draft_email: draftEmail || "",
            }, {withCredentials: true});

            updateSessionData("draft_email", response.data.draft_email, setDraftEmail);
            updateSessionData("email_requirement", response.data.email_requirement, setEmailRequirement);
            updateSessionData("email_tone", response.data.email_tone, setEmailTone);
            updateSessionData('email_writing_words', response.data.email_writing_words, setEmailWritingWords)
            updateSessionData('email_words', response.data.email_words, setEmailWords)
            updateSessionData("emailVersionIndex", Number(emailVersionMax)+1, setEmailVersionIndex)
            updateSessionData("emailVersionMax", Number(emailVersionMax)+1, setEmailVersionMax)

            setOriginalDraftEmail(response.data.draft_email);

        } catch (error) {
            if (error.name === 'AbortError') {
                if (process.env.NODE_ENV === 'development') {
                    console.log("Essay request was aborted.");
                }
                setEmailErrorMessage("An error occurred while generating your email. Please try again.")
            } else if (error.response && error.response.status === 401) {
                // Handle authentication error
                setEmailErrorMessage("You need a valid account for this service.");
            } else {
                if (process.env.NODE_ENV === 'development') {
                    console.error("Essay failed:", error);
                }
                setEmailErrorMessage("Error writing the email. Please try again.")
            }
        } finally {
            setEmailLoading(false);
        }
    };

    // Helper function to detect if the user has edited the input
    const isEdited = (original, current) => original !== current;

    // Restart/Stop button handler
    const handleRestartOrStop = () => {
        if (emailLoading) {
            // Abort the current email request if it exists
            if (emailAbortController.current) {
                emailAbortController.current.abort();
                emailAbortController.current = null; // Reset after aborting
            }

            // Clear any loading states
            setEmailLoading(false);
        } else {
            // Reset writing fields if not email
            setEmailRequirement("");
            setEmailTone("");
            setDraftEmail("");
            setNoEmailVersionMessage("");
            setEmailErrorMessage("");
            removeSessionStorageItem("email_requirement");
            removeSessionStorageItem("email_tone");
            removeSessionStorageItem('draft_email');
        }
    };

    // for page presentation
    const [isExpanded, setIsExpanded] = useState(false);

    const togglePanel = () => {
        setIsExpanded(!isExpanded);
    };

    const [showDownloadOptions, setShowDownloadOptions] = useState(false);

    const handleEmailDownload = () => {
        setShowDownloadOptions(true);
    };

    // Effect to reset showDownloadOptions when draftEmail changes
    useEffect(() => {
        setShowDownloadOptions(false);
    }, [draftEmail]);

    const todayDate = new Date().toISOString().split('T')[0].replace(/-/g, '.');
    const defaultFileName = `Email-${todayDate}`;

    const options = [
        { value: 'Formal', label: 'Formal' },
        { value: 'Informal', label: 'Informal' },
        { value: 'Professional', label: 'Professional' },
        { value: 'Casual', label: 'Casual' },
        { value: 'Assertive', label: 'Assertive' },
        { value: 'Appreciative', label: 'Appreciative' },
        { value: 'Short and Brief', label: 'Short and Brief' },
        { value: 'Apologetic', label: 'Apologetic' },
        { value: 'Polite', label: 'Polite' },
        { value: 'Humble', label: 'Humble' },
        { value: 'Diplomatic', label: 'Diplomatic' },
        { value: 'Urgent', label: 'Urgent' },
        { value: 'Humorous', label: 'Humorous' },
        { value: 'Inquisitive/Curious', label: 'Inquisitive/Curious' },
        { value: 'Confidential', label: 'Confidential' },
        { value: 'Collaborative', label: 'Collaborative' },
        { value: 'Sympathetic/Compassionate', label: 'Sympathetic/Compassionate' },
        { value: 'Motivational', label: 'Motivational' },
        { value: 'Educational', label: 'Educational' },
        { value: 'Inspirational', label: 'Inspirational' },
    ];

    const handleChange = (selectedOptions) => {
        const selectedValues = selectedOptions.map(option => option.value);
        setEmailTone(selectedValues);
        setSessionStorageItem('email_tone', JSON.stringify(selectedValues));
    };


    return (
        <>
            <h1>Writing Emails</h1>
            {(!username || numericWritingLimit<numericTotalWords || expiration<currentDate) && <GreetingMessage />}
            <div className="accordion" style={{marginTop: "20px"}}>
                <div onClick={togglePanel} className="accordion-header">
                    <span>{isExpanded ? 'To Write Effective and Professional Emails: ' : 'To Write Effective and Professional Emails: '}</span>
                </div>
                {isExpanded && (
                    <div className="accordion-content">
                        <p  style={{marginTop: "0px", marginBottom:"10px", marginRight: "10px"}}>
                            Writing effective emails can be challenging due to misconceptions about their informality,
                            the expectation of quick replies, and the risk of unintentional offense. Although emails
                            may appear casual, they are an important tool in professional communication, requiring
                            careful word choice and clear messaging to leave a positive impression.
                        </p>
                        {EmailSuggestions}
                    </div>
                )}
            </div>

            <div className="textarea-container">
                <div style={{ flex: 1 }}>
                    <h3 style={{ marginTop: "0px", marginBottom: "5px" }}>Email Requirement</h3>
                    <textarea
                        value={emailRequirement}
                        title="Who are you writing to, and why? Briefly explain the purpose of your email."
                        onChange={(e) => {
                            if (emailRequirement !== null && emailRequirement !== undefined) {
                                updateSessionData("email_requirement", e.target.value, setEmailRequirement);
                            }
                        }}
                        style={{
                            width: "100%",
                            height: "80px",
                            padding: "10px",
                            boxSizing: "border-box",
                            overflowWrap: "break-word", // Ensures text wraps within the box
                            resize: "none",             // Prevents resizing if undesired
                            verticalAlign: "top",        // Ensures text starts at the top
                            marginTop: '0px',
                            marginBottom: '25px',
                            fontSize: '0.8em',
                        }}
                    />
                </div>
            </div>
            <div className="textarea-container">
                <div className="textarea-item A">
                    <h3 style={{ marginTop: "0px", marginBottom: "5px" }}>Select the tones your email should read like</h3>
                    <Select
                        id="email-tone-selector"
                        isMulti
                        options={options}
                        placeholder="Choose any relevant tones"
                        onChange={handleChange}
                        styles={{
                            container: (base) => ({
                                ...base,
                                marginBottom: "25px", // Add margin-bottom here
                            }),
                        }}
                        value={options.filter(option => emailTone.includes(option.value))}
                    />
                </div>
            </div>

            <div className="textarea-container">
                <div className="textarea-item A" style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "100%" }}>
                    <div style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }}>
                        {emailLoading ? ( // Show spinner when emailLoading
                            <div className="spinner emailWriting">
                                <div className="writing-spinner">
                                    <div className="pen">🖋️</div>
                                    <div className="trace"></div>
                                </div>
                            </div>
                        ) : (
                            <button
                                onClick={handleEmailWriting}
                                className="btn-broad-action"
                                style={{backgroundColor: "#2c3e50", border: "none", color: "#ffffff",}}
                            >
                                {draftEmail ? "Revise Email" : "Write Email"}
                            </button>
                        )}
                    </div>
                </div>
                <div className="textarea-item A" style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "100%" }}>
                    <div style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", marginLeft: "20px", gap: "20px" }}>
                        <button
                            onClick={() => loadEmailVersion()} // Call without arguments for the latest version
                            className="btn-broad-action"
                            disabled = {noEmailVersionMessage}
                            title="Click to load the latest evaluation report from the database."
                        >
                            Load the Last Email
                        </button>
                        {noEmailVersionMessage && ( // Conditionally render the message
                            <div style={{ textAlign: 'center', marginTop: '0px', marginBottom: '-20px', color: 'red' }}>
                                {noEmailVersionMessage}
                            </div>
                        )}
                        {emailErrorMessage && ( // Conditionally render the message
                            <div style={{ textAlign: 'center', marginTop: '-20px', marginBottom: '-20px', color: 'red' }}>
                                {emailErrorMessage}
                            </div>
                        )}
                    </div>
                </div>
                <div className="textarea-item A" style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "100%" }}>
                    <div style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", gap: "20px" }}>
                        <button
                            onClick={handleRestartOrStop}
                            className="btn-broad-action"
                        >
                            {emailLoading ? "Stop" : "Restart"}
                        </button>
                    </div>
                </div>
            </div>

            <div className="textarea-container">
                <div className="textarea-item A">
                    <h3 style={{marginTop: '25px', marginBottom: '5px'}}>Draft Email</h3>
                    <textarea
                        ref={ideaRef}
                        style={{
                            width: "100%",
                            height: newHeight(draftEmail || "", "", 200, 1000),
                            padding: "10px",
                            boxSizing: "border-box",
                            overflowY: "auto",
                            overflowWrap: "break-word",
                            resize: "none",
                            fontSize: '0.8em',
                            backgroundColor: isEdited(originalDraftEmail, draftEmail) ? "#fff5e6" : "white"
                        }}
                        value={draftEmail || ""}
                        onChange={(e) => {
                            const text = e.target.value;
                            setDraftEmail(text);
                            updateSessionData("draft_email", text, setDraftEmail);
                            e.target.style.height = newHeight(text, "", 200, 1000);
                        }}
                        disabled={emailLoading}
                    />
                </div>
            </div>

            {draftEmail && emailWritingWords && emailWords && (
                <div className="textarea-container">
                    <p style={{marginTop: '5px', marginBottom: '0px'}}>{emailWritingWords} words in the draft email. {emailWords} words counted for usage. </p>
                </div>
            )}

            <div className="textarea-container">
                <div className="textarea-item A">
                    <div style={{ display: 'flex', justifyContent: 'center', marginTop: '20px', marginBottom: "20px" }}>
                        <button
                            onClick={handlePreviousVersion}
                            className="btn-record-action"
                            disabled={emailVersionIndex === 1 || emailVersionIndex <= emailVersionMax - 4}
                            style={{
                                cursor: (emailVersionIndex === 1 || emailVersionIndex <= emailVersionMax - 4) ? "not-allowed" : "pointer"
                            }}
                        >Previous Version</button>
                    </div>
                </div>
                <div className="textarea-item A">
                    <div style={{ display: 'flex', justifyContent: 'center', marginTop: '20px', marginBottom: "20px" }}>
                        {!showDownloadOptions ? (
                            <button
                                onClick={handleEmailDownload}
                                className="btn-record-action"
                                disabled={!draftEmail || draftEmail === ""}
                                style={{
                                    cursor: (!draftEmail || draftEmail === "") ? "not-allowed" : "pointer"
                                }}
                            >
                                Download the Email
                            </button>
                        ) : (
                            <DownloadOptions writingPiece={draftEmail} defaultFileName={defaultFileName} />
                        )}
                    </div>
                </div>
                <div className="textarea-item A">
                    <div style={{ display: 'flex', justifyContent: 'center', marginTop: '20px', marginBottom: "20px" }}>
                        <button
                            onClick={handleNextVersion}
                            className="btn-record-action"
                            disabled={emailVersionIndex >= emailVersionMax}
                            style={{ cursor: emailVersionIndex >= emailVersionMax ? "not-allowed" : "pointer" }}
                        >Next Version</button>
                    </div>
                </div>
            </div>
        </>
    );
}

export default EmailWriting;