import React, { useState, useEffect, useRef } from "react";
import axios from 'axios';
import WritingUsageConstant from '../../constants/WritingUsageConstant';


function WritingUsage() {

    return (
        <>
            <h1>The Calculation of Your Writing Usage</h1>

            {WritingUsageConstant}
        </>
    );
}

export default WritingUsage;