import React, { useState, useEffect, useRef } from "react";
import axios from 'axios';
import InstructionText from '../../constants/InstructionConstants';


function UserInstructions() {

    return (
        <>
            <h1>User Instructions</h1>

            {InstructionText}
        </>
    );
}

export default UserInstructions;