// DownloadOptions.js
import React, { useState } from 'react';
import FileDownload from './FileDownload';

const DownloadOptions = ({ writingPiece, defaultFileName }) => {
  const [fileName, setFileName] = useState(defaultFileName);
  const [fileFormat, setFileFormat] = useState('txt');

  return (
    <div>
      <label>
        File Name:
        <input
          type="text"
          style={{ marginLeft: '15px', marginTop: '5px', marginBottom: '5px' }}
          value={fileName}
          onChange={(e) => setFileName(e.target.value)}
        />
      </label>
      <br />
      <label style={{ marginTop: '-15px', marginBottom: '0px' }}>
        Select Format:
        <select
          style={{ marginLeft: '15px', marginTop: '0px', marginBottom: '0px' }}
          value={fileFormat}
          onChange={(e) => setFileFormat(e.target.value)}
        >
          <option value="txt">.txt</option>
          <option value="docx">.docx</option>
          <option value="pdf">.pdf</option>
        </select>
      </label>
      <FileDownload writingPiece={writingPiece} fileName={fileName} fileFormat={fileFormat} />
    </div>
  );
};

export default DownloadOptions;
