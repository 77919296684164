import React, { useState, useEffect } from 'react';
import { useAppState } from '../components/AppContext';
import '../App.css';
import Brainstorming from './subWritingAssistance/Brainstorming';
import SampleWriting from './subWritingAssistance/SampleWriting';
import EssayEvaluation from './subWritingAssistance/EssayEvaluation';
import EmailWriting from './subWritingAssistance/EmailWriting';
import ManageWritingAccount from './subWritingAssistance/ManageWritingAccount';

const WritingAssistance = () => {
    const [pageSettings, setPageSettings] = useState({
        pageWidth: '80%',
        fontSize: 'medium',
        colorTheme: 'light',
    });

    const applySettings = (settings) => {
        setPageSettings(settings);
    };

    const { state } = useAppState();
    const [activeTab, setActiveTab] = useState("Brainstorming");

    // Check the URL hash on load and set the active tab accordingly
    useEffect(() => {
        const hash = window.location.hash;
        if (hash === "#Brainstorming") {
            setActiveTab("Brainstorming");
        } else if (hash === "#SampleWriting") {
            setActiveTab("Sample Writing");
        } else if (hash === "#EssayEvaluation") {
            setActiveTab("Essay Evaluation");
        } else if (hash === "#EmailWriting") {
            setActiveTab("Email Writing");
        } else if (hash === "#ManageWritingAccount") {
            setActiveTab("Manage Writing Account");
        } else {
            setActiveTab("Brainstorming");
        }
    }, []);

    // Update the URL hash when the active tab changes
    const handleTabClick = (tabName) => {
        setActiveTab(tabName);
        if (tabName === "Brainstorming") {
            window.location.hash = "#Brainstorming";
        } else if (tabName === "Sample Writing") {
            window.location.hash = "#SampleWriting";
        } else if (tabName === "Essay Evaluation") {
            window.location.hash = "#EssayEvaluation";
        } else if (tabName === "Email Writing") {
            window.location.hash = "#EmailWriting";
        } else if (tabName === "Manage Writing Account") {
            window.location.hash = "#ManageWritingAccount";
        } else {
            window.location.hash = "#Brainstorming";
        }
    };

    // List of tabs
    const tabs = [
        { name: "Brainstorming", component: <Brainstorming /> },
        { name: "Sample Writing", component: <SampleWriting /> },
        { name: "Essay Evaluation", component: <EssayEvaluation /> },
        { name: "Email Writing", component: <EmailWriting /> },
        { name: "Manage Writing Account", component: <ManageWritingAccount /> },
    ];

    return (
        <>
            <div className="Page-header">
                <div className="Page-tabs">
                    {tabs.map((tab) => (
                        <span
                            key={tab.name}
                            className={`Page-tab ${activeTab === tab.name ? "active" : ""}`}
                            onClick={() => handleTabClick(tab.name)}
                        >
                            {tab.name}
                        </span>
                    ))}
                </div>
            </div>
            <div className="Page-maincontent">
                {/* Render the active tab's component */}
                {tabs.find((tab) => tab.name === activeTab).component}
            </div>
        </>
    );
};

export default WritingAssistance;

