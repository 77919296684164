import React from 'react';

const ProductsExplanation = () => {
    return (
        <>
            <div className="textarea-container">
                <div className="textarea">
                    <h4 style={{marginTop: "20px", color: "#00239C"}}>We offer three distinct writing plans to enhance your writing journey.</h4>
                    <h3>Writing Plan A ($169.99)</h3>
                    <p>
                        Register to unlock up to 20,000 words generated by SophoScriptor throughout your 45-day usage period.
                        Your plan expires either when you reach the writing limit or at the end of the 45-day period, whichever comes first.
                    </p>
                    <h3>Writing Plan B ($669.99)</h3>
                    <p>
                        Upgrade to Writing Plan B, unlocking the ability to generate up to 100,000 words from SophoScriptor throughout
                        your 60-day usage period. Your writing plan expires either when you reach the writing limit or at the end of
                        the 60-day period, whichever comes first.
                    </p>
                    <h3 style={{ marginTop: "25px", marginBottom: "5px"}}>Writing Plan C ($1119.99)</h3>
                    <p>
                        Elevate your experience with Writing Plan C, granting you the power to generate up to 200,000 words from
                        SophoScriptor throughout your 90-day usage period. Your writing plan expires either when you reach the writing
                        limit or at the end of the 90-day period, whichever comes first.
                    </p>
                    <hr style={{marginLeft: '40px'}}></hr>

                    <h4 style={{color: "#00239C"}}>We also offer additional extension options to provide greater flexibility for managing your writing needs:</h4>

                    <h3>Expiration Date Extension: +45 days ($24.99)</h3>
                    <p>
                        Supercharge your writing journey with our Expiration Date Extension option! Add an extra 45 days to your
                        current usage period, ensuring uninterrupted access to SophoScriptor. Maintain your momentum and creativity
                        by extending your usage period today.
                    </p>

                    <h3>Writing Limit Expansion: +10,000 words ($79.99)</h3>
                    <p>
                        Tailor your writing experience to your needs with our Writing Limit Expansion option. This allows you to
                        increase your word limit by an additional 10,000 words without extending the usage period. Empower your
                        creativity with this customizable feature and make every word count.
                    </p>
                    <hr style={{marginLeft: '40px'}}></hr>

                    <h4 style={{color: "#00239C"}}>All writing plans and extension options can be combined to meet your specific writing needs.</h4>
                </div>
            </div>
        </>
    );
};

export default ProductsExplanation;
