// FileDownload.js
import React from 'react';
import { saveAs } from 'file-saver';
import { Document, Packer, Paragraph, TextRun, AlignmentType } from 'docx';
import { jsPDF } from 'jspdf';

const FileDownload = ({ writingPiece, fileName, fileFormat }) => {
    const title = fileName; // Assign title with fileName

    // Helper function to save file using Blob
    const saveFile = (blob, fullFileName) => {
        const element = document.createElement('a');
        element.href = URL.createObjectURL(blob);
        element.download = fullFileName;
        document.body.appendChild(element);
        element.click();
        document.body.removeChild(element);
    };

    const downloadFile = () => {
        switch (fileFormat) {
            case 'txt':
                createTxtFile(writingPiece, title, fileName);
                break;
            case 'docx':
                createDocxFile(writingPiece, title, fileName);
                break;
            case 'pdf':
                createPdfFile(writingPiece, title, fileName);
                break;
            default:
                return;
        }
    };

    const createTxtFile = (content, title, fileName) => {
        const formattedContent = `${title}\n\n${content}`; // Title followed by two line breaks
        const txtBlob = new Blob([formattedContent], { type: 'text/plain' });
        saveFile(txtBlob, `${fileName}.txt`);
    };

    const createDocxFile = (content, title, fileName) => {
        const doc = new Document({
            sections: [
                {
                    properties: {},
                    children: [
                        // Title paragraph: centered and bold
                        new Paragraph({
                            text: title,
                            alignment: AlignmentType.CENTER,
                            children: [new TextRun({ text: title, bold: true })],
                        }),
                        // Empty paragraph for one line space
                        new Paragraph(""),
                        // Content paragraphs
                        ...content.split('\n').map(line => new Paragraph(line)),
                    ],
                },
            ],
        });

        Packer.toBlob(doc).then(blob => {
            saveAs(blob, `${fileName}.docx`);
        });
    };

    const createPdfFile = (content, title, fileName) => {
        const pdf = new jsPDF();
        const pageWidth = pdf.internal.pageSize.getWidth();
        const pageHeight = pdf.internal.pageSize.getHeight();

        // Set margins
        const marginLeft = 20;
        const marginTop = 30;
        const marginBottom = 30;
        const contentWidth = pageWidth - marginLeft * 2;

        // Add the title
        pdf.setFont("Helvetica", "bold");
        const titleWidth = pdf.getTextWidth(title);
        const titleX = (pageWidth - titleWidth) / 2;
        pdf.text(title, titleX, marginTop); // Position title at the top and centered

        // Add one line space below the title
        pdf.setFont("Helvetica", "normal");
        let currentHeight = marginTop + 10; // Position below the title

        // Split the content into lines that fit within the content width
        const lines = pdf.splitTextToSize(content, contentWidth);

        // Loop through the lines and add them to the PDF, handling pagination
        lines.forEach((line) => {
            if (currentHeight + 10 > pageHeight - marginBottom) {
                // If the current position is past the bottom margin, add a new page
                pdf.addPage();
                currentHeight = marginTop; // Reset the height for the new page
            }
            pdf.text(line, marginLeft, currentHeight);
            currentHeight += 10; // Move down for the next line
        });

        pdf.save(`${fileName}.pdf`);
    };

    return (
        <div>
            <h4 style={{ marginTop: '10px', marginBottom: '0px'}}>Download Writing Piece</h4>
            <button
                style={{ padding: "2px 12px", marginTop: '5px', marginBottom: "20px" }}
                onClick={downloadFile}>Download
            </button>
        </div>
    );
};

export default FileDownload;
