const InstructionText = (
    <div className="textarea-container">
        <div className="textarea">
            <h2 style={{ fontSize: '1.6em', fontWeight: 'bold' }}>Welcome to SophoScriptor!</h2>
            <p style={{marginLeft: "0pt", marginTop: '0pt'}}>Using the Sample Writing tool to create your application essay is as simple as following these steps:</p>

            <h3>Step 1: Provide Your Writing Requirements</h3>
            <ul style={{marginLeft: "40pt"}}>
                <li style={{marginBottom: "10pt"}}>
                <strong>Writing Prompt:</strong> This is your essay's starting point. Usually, you can find it on your chosen college’s application page. Copy and paste the prompt into the designated Writing Prompt box to set the context for your essay. If you leave this blank, it defaults to a common prompt:
                <p style={{ marginTop: "5pt", marginBottom: "5pt" }}>
                    <em>“Some students have a background, identity, interest, or talent that is so meaningful they believe their application would be incomplete without it. If this sounds like you, then please share your story.”</em>
                </p>This prompt is popular across colleges and appears as the first option on the Common App.
                </li>
                <li style={{marginBottom: "10pt"}}>
                <strong>Word Limit:</strong> Specify the maximum word count for your essay. Although there is no strict minimum, aiming to approach the word limit can ensure you fully express your ideas.
                </li>
                <li style={{marginBottom: "10pt"}}>
                <strong>Writing Idea:</strong> Outline the main message you want to convey. Enter your thoughts in the Writing Ideas box. If you have used the Brainstorming tool, click "Apply Brainstorming" in the Essay Writing Options box to use the generated writing ideas and framework.
                </li>
            </ul>

            <h3>Step 2: Generate Your Essay</h3>
            <p>Click the <strong>Start a New Essay</strong> button to create your initial draft. Alternatively, you can provide an existing draft for the app to revise.</p>

            <h3>Step 3: Revise Your Essay</h3>
            <p style={{marginBottom: "10pt"}}>Review the generated essay and make revisions as needed. You can:</p>
            <ul style={{marginLeft: "40pt"}}>
                <li style={{marginBottom: "10pt"}}>Add new ideas directly into the writing box.</li>
                <li style={{marginBottom: "10pt"}}>Request an evaluation (optional) to get feedback based on common college essay criteria. Use the suggestions from this report for improvement.</li>
                <li style={{marginBottom: "10pt"}}>Edit the essay directly. Feel free to be bold: add content, change phrasing, and don’t worry about grammatical errors at this stage.</li>
            </ul>

            <h3>Step 4: Finalize Your Essay (Optional)</h3>
            <p>
                If needed, click <strong>Finalize Essay</strong> in the Essay Writing Options box to adjust your essay to fit within the word limit. This feature is intended for cases where meeting the word count is crucial but is <strong>not recommended</strong> for general use. This process is resource-intensive, and your word count usage will be multiplied by ten. For example, a 500-word essay will count as 5000 words toward your usage.
            </p>

            <h3>Additional Features:</h3>
            <ul style={{marginLeft: "40pt"}}>
                <li style={{marginBottom: "10pt"}}>Navigate between the 5 most recent versions of your essay using the <strong>Previous</strong> and <strong>Next</strong> buttons.</li>
                <li style={{marginBottom: "10pt"}}>Edit any version freely to create another draft. To save a version, use the <strong>Download</strong> button.</li>
                <li style={{marginBottom: "10pt"}}>If your essay doesn’t align with your vision, or if you want to try a new approach, click the <strong>Restart</strong> button to start fresh.</li>
            </ul>
        </div>
    </div>
);

export default InstructionText;
